import React from "react";

const Button = ({
  id,
  border,
  color,
  children,
  height,
  onClick,
  radius,
  width,
  disabled,
  
}) => {
  if(id == "menu"){
    return (
   
      <button
      aria-expanded="false" 
      className="menu-link menu-link--button" data-ariacontrols="i-want-to-submenu-1"
      id= {id}
        onClick={onClick}
        style={{
          
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )
  }
  else if(id == "submitCon"){
    return (
   
      <button
      className=" tw-button tw-bg-white tw-inline-flex tw-flex-row tw-button-md tw-button--primary"
        onClick={onClick}
        id= {id}
        style={{
          
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )
  }
  else if(id == "submit"){
    return (
   
      <button
      className=" tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--primary"
        onClick={onClick}
        id= {id}
        style={{
          
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )
  }
  else if(id == "next"  || id == "NoteSubmit" || id == "EditVot" || id == "EditInc" || id == "EditBOE"){
    return (
   
      <button
      className="tw-ml-auto tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--primary"
        onClick={onClick}
        id= {id}
        style={{
          
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )
  }else if (id == "back"){
    return (
   
      <button
       
      className="tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--secondary"
        onClick={onClick}
        id= {id}
        style={{
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )

  }else if (id == "backDisabled"){
    return (
   
      <button
       
      className="tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--secondary"
        onClick={onClick}
        id= {id}
        disabled
        style={{
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )

  }else if (id == "search"){
    return (
   
      <button
       
      className="tw-button tw-w-full tw-inline-flex tw-flex-row tw-button-md tw-button--primary"
        onClick={onClick}
        id= {id}
        style={{
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
      </button>
    )

  }else if (id == "Add"){
    return (
   
      <button
       
      className="tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--secondary"
        onClick={onClick}
        id= {id}
        style={{
          backgroundColor: "#00A676",
          color: "white",
          border,
          borderRadius: radius,
          height,
          width
        }}
      >
        {children}
       
      </button>
    )
  
}
else if (id == "Removed"){
  return (
 
    <button
     
    className="tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--secondary"
      onClick={onClick}
      id= {id}
      style={{
        backgroundColor: "#9B2915",
        color: "white",
        border,
        borderRadius: radius,
        height,
        width
      }}
    >
      {children}
    </button>
  )

}
else if (id == "issue"){
  return (
 <div align="center" class = "tw-pt-2">
    <button
     
    className="tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--primary"
      onClick={onClick}
      id= {id}
      disabled={disabled}
      
       style={disabled? {backgroundColor: "#F3F3F3", color: "white", border, borderRadius: radius,height, width} : {backgroundColor: "#00A676", color: "white", border, borderRadius: radius,height, width}
      
    }
    >
      {children}
    </button>
    </div>
  )

}
}

export default Button
