import React, { Component } from 'react'

import { FormControl, Alert, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/Button.js';
import { OAGTextField } from '../../helpers/StyledPaper.js';

import { loginRequest, protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";
import PositionedSnackbar from '../../helpers/alerts.js';

export class MOTD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageString: "",
            isopen: false,
            link: "",
            link_text: "",
        };
    }

    async componentDidMount() {
        // console.log("In componentdidmount");
        const accessToken = await this.getToken();
        //console.log(accessToken);

        const url = process.env.REACT_APP_API_URL + "MOTD";

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };
        //console.log(options);

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log("result: " + result)
                    this.setState({
                        isLoaded: true,
                        messageString: result.adminAlert,
                        link: result.link,
                        link_text: result.linkText
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }

            )
    }

    getToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            //console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            ...loginRequest
        });

        return response.accessToken;
    }


    // handle field change
    handleChange = input => (event) => {
        this.setState({ [input]: event.target.value });
    }

    submit = async (e) => {
        e.preventDefault();
        //console.log("About to Submit");
        const accessToken = await this.getToken();
        const url = process.env.REACT_APP_API_URL + "MOTD";
        const headers = new Headers();

        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        //console.log(headers);
        const options = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                adminAlert: this.state.messageString,
                id: 1,
                link: this.state.link,
                linkText: this.state.link_text
            })
        };

       // console.log("options: " + options.headers);
        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {

                    //console.log("status: " + result.status)
                    if (result.status == 200) {
                        

                       // console.log(this.state.isopen)

                    }
                    //console.log(result.body)

                },
                (error) => {
                    //console.log("error:" + error)
                }

            )
       // alert("successfully updated MOTD")
        // this.state.isopen = true;
        //console.log("Open" + this.state.isopen)
        alert("successfully updated MOTD")
        window.location.reload();
    }

    newDelete = async e => {
        e.preventDefault();
        const accessToken = await this.getToken();
        const url = process.env.REACT_APP_API_URL + "MOTD";
        const headers = new Headers();

        const bearer = `Bearer ${accessToken}`;
        //("messageString: " + this.state.messageString)

        headers.append("Authorization", bearer);
        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");

        const options = {
            method: "DELETE",
            headers: headers,
        };

        //console.log(options.body);
        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result.status)
                    //console.log(result.body)
                    
                },
                (error) => {
                    //console.log(error)

                }

            )
            
            alert("Successfully cleared MOTD")
            window.location.reload();
    }


    handleClick = () => {
        this.state.isopen = true;
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.state.isopen = true;
    };

    action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={this.handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    render() {
        const { error, isLoaded } = this.state;
        //console.log(this.state.messageString);
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <main class="tw-px-5 tw-py-5">
                    <div class="tw-px-5 tw-rounded-xl tw-bg-light-gray">

                        <h1 class="tw-pt-2">Message of the Day Manager</h1>
                        <h3>Below is the current message of the day. Please edit as Needed</h3>

                        <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="MOTD" class="tw-block tw-mb-2 tw-text-md tw-font-medium  ">Message of the Day</label>
                                <textarea
                                    type="text"
                                    id="MOTD"
                                    class=" tw-border   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                    placeholder="Enter the Message of the Day"
                                    value={this.state.messageString}
                                    onChange={this.handleChange('messageString')}
                                />
                            </div>

                        </div>
                        <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="MOTD" class="tw-block tw-mb-2 tw-text-md tw-font-medium  ">Hyperlink</label>
                                <input
                                    type="text"
                                    id="Hyperlink"
                                    class=" tw-border   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                    placeholder="Hyperlink"
                                    value={this.state.link}
                                    onChange={this.handleChange('link')}

                                />
                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="linkText" class="tw-block tw-mb-2 tw-text-md tw-font-medium  ">Hyperlink Text</label>
                                <input
                                    type="text"
                                    id="linkText"
                                    class=" tw-border   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                    placeholder="Enter the Hyperlink Text"
                                    value={this.state.link_text}
                                    onChange={this.handleChange('link_text')}

                                />
                            </div>
                        </div>




                        <div align="right" className="tw-grid tw-gap-6 md:tw-grid-cols-1">
                            <div class="tw-py-2">

                                <Button
                                    id="back"

                                    variant="contained"
                                    onClick={this.newDelete}

                                >
                                    Clear      </Button>
                                    &nbsp;
                                <Button
                                    id="submit"
                                    onClick={this.submit}

                                >
                                    Submit      </Button>

                            </div>
                        </div>
                        {/* <PositionedSnackbar  link_text = {this.state.link_text} link = {this.state.link} messageString= {this.state.messageString}/> */}

                    </div>
                </main>
            )
        }
    }
}
export default MOTD;

