import React, { Component, useEffect,useState } from 'react'
import PropTypes from 'prop-types'
import {IconButton} from '@mui/material';
import {ArrowDownward} from '@mui/icons-material';
import { loginRequest, protectedResources } from "../authConfig";
import { msalInstance } from "../index";
import edit from "../images/icons/eva/fill/arrow-ios-downward.svg";


function issuemovedown(props) {
    //const [rowpk, setRowPK] = React.useState(props.issueid);
    const rowpk = props.issueid;
    const getToken = async () => {
        const account = msalInstance.getActiveAccount();
    
        if (!account) {
            ////console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            account: account,
            loginRequest
        });
    
        return response.accessToken;
      }

      const move = async(e) => {
        const accessToken = await getToken();
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
    
        const PostData = {
          method: "POST",
          headers: headers,
        };
        const url = process.env.REACT_APP_API_URL + "IssueType/MoveDown/" + rowpk;    
        ////console.log("We're going to call");
        ////console.log(url);
        try{
            let response = await fetch(url, PostData);
            ////console.log(response);
        }
        catch (err){
            ////console.log(err);
        }
        window.location.reload(false);
      }

  return (
         <button id="lastPage" className=" tw-pt-1" onClick={move}>
         <img className="  hover:tw-bg-gray tw-w-[40px]  tw-h-[40px] " src={edit} />
       </button>
  )
}

issuemovedown.propTypes = {}

export default issuemovedown
