import * as React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import { Add } from '@mui/icons-material';
import { OAGTextField, OAGButton, OAGButtonGroup } from '../helpers/StyledPaper.js';

import { loginRequest, protectedResources } from "../authConfig";
import { msalInstance } from "../index";
import add from "../images/icons/eva/outline/plus-outline.svg";
import Button from '../components/Button.js';
export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [issue, setIssue] = React.useState('');
  const [subissue, setSubIssue] = React.useState('');
  const [disporder, setDispOrder] = React.useState('');
  const [token, setToken] = React.useState(null);

  const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...loginRequest
    });

    setToken(response.accessToken);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIssueChange = React.useCallback(e => {        // ***
    setIssue(e.target.value)                          // ***
  }, [])

  const handleSubIssueChange = React.useCallback(e => {        // ***
    setSubIssue(e.target.value)                          // ***
  }, [])

  const handleDispOrderChange = React.useCallback(e => {        // ***
    setDispOrder(e.target.value)                          // ***
  }, [])

  const submit = async () => {
    //e.preventDefault();
    getToken();
    //console.log(token);

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("content-type", "application/json");
    headers.append("accept", "*/*");
    headers.append("Authorization", bearer);

    const PostData = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        issueType: issue,
        issueSubtype: subissue,
      }),
    };

    //console.log(PostData);

    const url = process.env.REACT_APP_API_URL + "IssueType/";

    try {
      let response = await fetch(url, PostData);
      //console.log(response);
    }
    catch (err) {
      //console.log(err);
    }

    handleClose();
    window.location.reload(false);
  }

  return (
    <div align="center">


      <Button id="Add" onClick={handleClickOpen}>
        Add a New Issue
        <img className="  tw-px-1 tw-w-[40px]  tw-h-[40px] " src={add} />
      </Button>




      {/* <IconButton onClick={handleClickOpen}><MuIcons.Edit/></IconButton> */}
      <Dialog open={open} onClose={handleClose}>

        <DialogTitle >
          <h4>Add Issue</h4></DialogTitle>

        <DialogContent>

          <h5 >
            Please enter the Issue and SubIssue below
          </h5>

          <hr />
          <label for="typeofissue" class="tw-block  tw-text-sm  ">Type of Issue</label>
          <input
            type="text"
            id="typeofissue"
            onChange={handleIssueChange}
            placeholder="Enter type of issue"
            class=" tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "

          />

          <label for="issue" class="tw-block  tw-text-sm  ">Type of Sub Issue</label>
          <input
            type="text"
            id="issue"
            onChange={handleSubIssueChange}
            placeholder="Enter type of subissue"
            class=" tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "

          />

          <label for="displayorder" class="tw-block  tw-text-sm  ">Display Order</label>
          <input
            type="number"
            id="displayorder"
            onChange={handleDispOrderChange}
            placeholder="Enter type of subissue"
            class=" tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "

          />


        </DialogContent>

        <DialogActions>

          <Button id="back" onClick={handleClose}>Cancel</Button>
          <Button id="submit" onClick={submit}>Submit</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}