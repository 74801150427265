import { LogLevel } from "@azure/msal-browser";


export const msalConfig = {
    auth: {
        //Production AD Authority/Tenant
        //authority: "https://login.microsoftonline.com/f0e46024-97df-431a-80d6-c0e744fd7a39", // Defaults to "https://login.microsoftonline.com/common"
        //authority: `https://login.microsoftonline.com/${process.env["REACT_APP_AAD_APP_TENANT_ID"]}`,
        //Local & Dev
        //clientId: "8ba42226-648b-48ef-9688-8d5ddef3c720",
        ///PRODUCTION
        //clientId: "f85b745f-98da-4813-a1c4-21744fb63769",

        //TestAD Authority
        //authority: "https://login.microsoftonline.com/22e092d7-d0b8-4e04-8d63-c022b7ce1afe",
        //TEST with TEST Azure Domain (oagtest.ag.ny.gov)
        //clientId: "500fc2c1-fb18-4ea0-b862-4daa8d945187",
        //UAT with TEST Azure Domain (oagtest.ag.ny.gov)
        //clientId: "c4416e2a-48e1-470e-8d1c-0248ffb75085",

        authority: `${process.env["REACT_APP_MSAL_AUTHORITY"]}`, // Defaults to "https://login.microsoftonline.com/common"
        clientId: `${process.env["REACT_APP_MSAL_CLIENTID"]}`,
        redirectUri: "/",
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                }
            }
        }
    }
};

//TOGGLE THE RIGHT ENVIRONMENT HERE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const loginRequest = {
    //Local
    //scopes: ["api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user"],
    //Dev
    //scopes: ["api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user"],
    //scopes: process.env["REACT_APP_SCOPES2"] //Works for login but not for Token retrieval
    //Test
    //scopes: ["api://8850a487-c9ba-45fa-b979-aa593048097a/access_as_user"],
    //UAT (nysag.microsoft.com)
    //scopes: ["api://7b0fb0a3-97ee-436a-ae63-cdda307fed58/access_as_user"],
    //Production
    scopes: ["api://33c0021d-c228-472b-b7c9-4f6417e18cea/access_as_user"],
};

export const protectedResources = {
    //Agnostic
    apiElectionHotline: {
        complaintEndpoint: `${process.env["REACT_APP_COMPLAINT_API"]}`,
        electionEndpoint: `${process.env["REACT_APP_ELECTION_API"]}`,
        issueEndpoint: `${process.env["REACT_APP_ISSUE_API"]}`,
        issuetypeEndpoint: `${process.env["REACT_APP_ISSUETYPE_API"]}`,
        notesEndpoint: `${process.env["REACT_APP_NOTES_API"]}`,
        searchEndpoint: `${process.env["REACT_APP_SEARCH_API"]}`,
        voterEndpoint: `${process.env["REACT_APP_VOTER_API"]}`,
        documentEndpoint: `${process.env["REACT_APP_DOCUMENT_API"]}`,
        motdEndpoint: `${process.env["REACT_APP_MOTD_API"]}`,
        scopes: [`${process.env["REACT_APP_AAD_APP_FUNCTION_SCOPE_URI"]}/access_as_user`], // e.g. api://xxxxxx/access_as_user
    },
    graphGroup: {
        scopes: ["https://graph.microsoft.com/User.Read.All"],

        /*Local
        // apiElectionHotline: {
        //     complaintEndpoint: "http://localhost:7259/api/Complaint",
        //     electionEndpoint: "http://localhost:7259/api/ElectionYear",
        //     issueEndpoint: "http://localhost:7259/api/Issue",
        //     issuetypeEndpoint: "http://localhost:7259/api/IssueType",
        //     notesEndpoint: "http://localhost:7259/api/Notes",
        //     searchEndpoint: "http://localhost:7259/api/Search",
        //     voterEndpoint: "http://localhost:7259/api/Voter",
        //     scopes: ["api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user"],
        // },
        //Dev
        // apiElectionHotline: {
        //     complaintEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/Complaint",
        //     electionEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/ElectionYear",
        //     issueEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/Issue",
        //     issuetypeEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/IssueType",
        //     notesEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/Notes",
        //     searchEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/Search",
        //     voterEndpoint: "https://electionhotline-api-dev.ag.ny.gov/api/Voter",
        //     scopes: ["api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user"],
        // },
    
        //Test with Azure Test
        // apiElectionHotline: {
        //     complaintEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/Complaint",
        //     electionEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/ElectionYear",
        //     issueEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/Issue",
        //     issuetypeEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/IssueType",
        //     notesEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/Notes",
        //     searchEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/Search",
        //     voterEndpoint: "https://electionhotline-api-test.ag.ny.gov/api/Voter",
        //     scopes: ["api://8850a487-c9ba-45fa-b979-aa593048097a/access_as_user"],
        // },
        //UAT with Azure Test
        // apiElectionHotline: {
        //         complaintEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/Complaint",
        //         electionEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/ElectionYear",
        //         issueEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/Issue",
        //         issuetypeEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/IssueType",
        //         notesEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/Notes",
        //         searchEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/Search",
        //         voterEndpoint: "https://electionhotline-api-uat.ag.ny.gov/api/Voter",
        //         scopes: ["api://7b0fb0a3-97ee-436a-ae63-cdda307fed58/access_as_user"],
        // },
        // PRODUCTION!!!
        // apiElectionHotline: {
        //     complaintEndpoint: "https://electionhotline-api.ag.ny.gov/api/Complaint",
        //     electionEndpoint: "https://electionhotline-api.ag.ny.gov/api/ElectionYear",
        //     issueEndpoint: "https://electionhotline-api.ag.ny.gov/api/Issue",
        //     issuetypeEndpoint: "https://electionhotline-api.ag.ny.gov/api/IssueType",
        //     notesEndpoint: "https://electionhotline-api.ag.ny.gov/api/Notes",
        //     searchEndpoint: "https://electionhotline-api.ag.ny.gov/api/Search",
        //     voterEndpoint: "https://electionhotline-api.ag.ny.gov/api/Voter",
        //     scopes: ["api://33c0021d-c228-472b-b7c9-4f6417e18cea/access_as_user"],
        },*/
    }
}

// export const loginRequest = {
//   scopes: [...protectedResources.apiElectionHotline.scopes]
// };

export const appRoles = {
    IT: "IT",
    Admin: "Admin",
    Volunteer: "Volunteer"
}