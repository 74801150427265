import React, { Component } from 'react'
import { IconButton, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import * as MuIcons from '@mui/icons-material';
import { loginRequest, protectedResources } from "../../../authConfig";
import { msalInstance } from "../../../index";
import { OAGTextfield, OAGButtonGroup, OAGStyle, OAGTypography } from '../../../helpers/StyledPaper';
import Edit from "../../../images/icons/eva/outline/edit-outline.svg";
import Save from "../../../images/icons/eva/outline/save-outline.svg";
import Close from "../../../images/icons/eva/outline/close-circle-outline.svg";

export default class boeblock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            disabled: true,
        };

        this.unlock = this.unlock.bind(this)
        this.lock = this.lock.bind(this)
        this.savechanges = this.savechanges.bind(this)
    }

    getToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            ...loginRequest
        });

        return response.accessToken;
    }

    unlock() {
        this.setState({ disabled: false });
    };
    lock() {
        this.setState({ disabled: true });
    };

    savechanges = async e => {
        e.preventDefault();

        const token = await this.getToken();
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        headers.append("Authorization", bearer);

        const PostData = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                pollworker: this.props.boedtls.pollworker,
                followUpReqd: this.props.boedtls.followupreqd,
                indepescalation: this.props.boedtls.indepescalation,
                boeContacted: this.props.boedtls.boecontacted,
                boeContact: this.props.boedtls.boecontact,
                issueRslvd: this.props.boedtls.issuerslved,
                affidavitCast: this.props.boedtls.affidavitcast,
                votercontactlocalboe: this.props.boedtls.votercontactlocalboe,

                oagboecontacted: this.props.boedtls.oagboecontacted,
                oagboecontact: this.props.boedtls.oagboecontact,
                oagfollowup: this.props.boedtls.oagfollowup,
                oagfollowupdtl: this.props.boedtls.oagfollowupdtl,
                oagrslvdissue: this.props.boedtls.Oagrslvdissue,
                oagrslvdissuedtl: this.props.boedtls.oagrslvdissuedtl,
                voternotifiedstatus: this.props.boedtls.Voternotifiedstatus,
                voternotifiedstatusdtl: this.props.boedtls.voternotifiedstatusdtl,
            }),
        };

        //console.log(PostData);
        //console.log("Voter ID Being passed as " + this.props.voterdtls.voterid)

        const url = process.env.REACT_APP_API_URL + "Complaint/BOE/" + this.props.boedtls.cmpltID;
        //console.log(url);
        try {
            let response = await fetch(url, PostData);
            //console.log(response);
        }
        catch (err) {
            //console.log(err);
        }

        this.lock();
    }

    render() {
        const { boedtls, handleChange } = this.props;
        const { disabled } = this.state;

        const divStyle = {
            backgroundColor: 'lightgray',
            borderRadius: 5 + 'px',
            paddingTop: 2.5 + 'px',
            paddingBottom: 2.5 + 'px'
        };

        let menu, editable;

        if (disabled) {
            editable = disabled
            menu =
                <button id="BoeUnlock" className="text-white tw-rounded-full hover:tw-bg-gray" onClick={this.unlock}>
                    <img className="tw-block  tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Edit} />
                </button>

        } else {
            editable = "enabled"
            menu =
                <div className="tw-flex tw-space-x-4">
                    <button
                        id="BoeSaveChanges"
                        className=" tw-transition tw-rounded-full tw-duration-300 hover:tw-bg-gray"
                        onClick={this.savechanges}
                    >
                        <img className="tw-block tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Save} alt="Save Icon" />
                    </button>
                    <button
                        id="BoeLock"
                        className="tw-text-white  tw-rounded-full  hover:tw-bg-gray hover:tw-text-blue-500 tw-transition tw-duration-300"
                        onClick={this.lock}
                    >
                        <img className="tw-block tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Close} alt="Lock Icon" />
                    </button>
                </div>

        }


        // { pollworker, followupreqd, independentescalation, boecontact, issuerslved, affidavitcast,
        //     oagboecontacted, oagboecontact, oagfollowup, localboeressolve, voternotifiedstatus, cmpltID }
        return (
            <div class="tw-px-5  tw-shadow-xl tw-rounded-2xl tw-bg-lightest-gray">
                {/* NEW CODE */}
                <div class="tw-pt-4">
                    <div className="tw-flex tw-text-center   tw-rounded-xl tw-items-center tw-justify-between tw-px-4 ">
                        <div class="tw-callout tw-callout--secondary tw-text-blue tw-text-base tw-text-center">
                            <h3>
                                Board of Election Details
                            </h3>
                        </div>
                        {menu}
                    </div>
                </div>
                <div className="tw-grid tw-gap-6 md:tw-grid-cols-2">
                    <div >

                        {/* Base BOE Questions */}




                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="boecontacted">Did a poll worker solve your problem or contact the local Board of Elections about it?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.boecontacted}
                            label="Did a poll worker solve your problem or contact the local Board of Elections about it?"
                            onChange={handleChange('boecontacted')}
                            id="boecontacted"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>


                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="pollworker">Did a poll worker tell you what you should do next to solve your problem or follow up?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.pollworker}
                            label="Did a poll worker solve your problem or contact the local Board of Elections about it?"
                            onChange={handleChange('pollworker')}
                            id="pollworker"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>
                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}

                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="Contact">Did you know how to contact the Board of Elections about your problem?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.indepescalation}
                            onChange={handleChange('indepescalation')}
                            id="Contact"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>

                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="votercontactboe">Did you (the voter) contact the BOE?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.votercontactlocalboe}
                            onChange={handleChange('votercontactlocalboe')}
                            id="votercontactboe"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>

                        <br />
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} for="BoeContact">If yes, provide details.</label>
                        <textarea
                            type="text"
                            disabled={disabled}
                            value={boedtls?.boecontact}
                            onChange={handleChange('boecontact')}
                            id="BoeContact"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                                  maxLength={1000}
                        />


                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}

                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="VoterResolveBoe">Did the BOE resolve issue?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.issuerslved}
                            onChange={handleChange('issuerslved')}
                            id="VoterResolveBoe"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>

                        <br />

                    </div>

                    {/* Extra Questions */}
                    <div >

                        <div class="container-md bg-blue tw-rounded-lg">

                            Extra questions (Office of the Attorney General Section)

                        </div>
                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="pollworker">Did the OAG contact the local BOE after receiving the voter’s complaint?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.oagboecontacted}
                            onChange={handleChange('oagboecontacted')}
                            id="pollworker"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>
                        
                        <div class="tw-px-2 tw-pt-2">
                            <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} for="oagBoeContact">Who was contacted and what is the status?</label>
                            <textarea
                                type="text"
                                disabled={disabled}
                                value={boedtls?.oagboecontact}
                                onChange={handleChange('oagboecontact')}
                                id="oagBoeContact"
                                      class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                                      maxLength={1000}
                            />
                        </div>

                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="oagfollowup">Is additional OAG follow up still needed?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.oagfollowup}
                            onChange={handleChange('oagfollowup')}
                            id="oagfollowup"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>
                        
                        <div class="tw-px-2 tw-pt-2">
                            <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} for="oagFollowupDtl">Please provide status of Contact Efforts</label>
                            <textarea
                                type="text"
                                disabled={disabled}
                                value={boedtls?.oagfollowupdtl}
                                onChange={handleChange('oagfollowupdtl')}
                                id="oagFollowupDtl"
                                      class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                                      maxLength={1000}
                            />
                        </div>
                        
                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}

                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="Oagrslvdissue">Did the OAG resolve the voter's issue?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.Oagrslvdissue}
                            onChange={handleChange('Oagrslvdissue')}
                            id="Oagrslvdissue"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>



                        <div class="tw-px-2 tw-pt-2">
                            <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} for="oagrslvdissuedtl">Please provide status/progress with BOE</label>
                            <textarea
                                type="text"
                                disabled={disabled}
                                value={boedtls?.oagrslvdissuedtl}
                                onChange={handleChange('oagrslvdissuedtl')}
                                id="oagrslvdissuedtl"
                                      class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                                      maxLength={1000}
                            />
                        </div>
                        


                        <br />

                        {/* <OAGTypography>Did a poll worker solve your problem or contact the local Board of Elections about it?</OAGTypography> */}
                        <label class={disabled ? "tw-block  tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium "} id="Voternotifiedstatus">Was the voter updated on the status of their complaint?</label>
                        <select

                            disabled={disabled}
                            value={boedtls?.Voternotifiedstatus}
                            onChange={handleChange('Voternotifiedstatus')}
                            id="Voternotifiedstatus"
                                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}

                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="N/A">N/A</option>
                            <option value="Unknown">Unknown</option>
                        </select>


                        <br />


                    </div>
                </div>
            </div>
        )
    }
}
