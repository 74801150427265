import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import * as MuIcons from '@mui/icons-material';
import { OAGColor, OAGStyle, OAGTextField, OAGButton } from '../helpers/StyledPaper';
import Button from '../components/Button';
import { protectedResources, loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import add from "../images/icons/eva/outline/plus-outline.svg";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [electionYear, setElectionYear] = React.useState('');
  const [electionMonth, setElectionMonth] = React.useState('');
  const [startDt, setStartDt] = React.useState('');
  const [token, setToken] = React.useState(null);
  //const [bearer, setBearer] = React.useState(null);

  useEffect(() => {
    async function getToken() {
      const account = msalInstance.getActiveAccount();

      if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...loginRequest
      });

      setToken(response.accessToken);
    };
    getToken();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleelectionYearChange = React.useCallback(e => {        // ***
    setElectionYear(e.target.value)                          // ***
  }, [])

  const handleelectionMonthChange = React.useCallback(e => {        // ***
    setElectionMonth(e.target.value)                          // ***
  }, [])

  const handlestartDtChange = React.useCallback(e => {        // ***
    setStartDt(e.target.value)                          // ***
  }, [])

  const submit = async () => {
    //e.preventDefault();
    var tdy = new Date();
    tdy.setDate(tdy.getDate());
    var defaultdate = tdy.toISOString().substr(0, 10);
    //console.log("Off to go get me a token");
    //await getToken();

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    //console.log("Using Bearer state variable now:");
    //console.log(bearer);

    headers.append("content-type", "application/json");
    headers.append("accept", "*/*");
    headers.append("Authorization", bearer);

    const PostData = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        electionYear: electionYear,
        electionMonth: electionMonth,
        startDt: startDt
      }),
    };

    //console.log(PostData);

    const url = process.env.REACT_APP_API_URL + "ElectionYear/";

    try {
      let response = await fetch(url, PostData);
      //console.log(response);
    }
    catch (err) {
      //console.log(err);
    }

    handleClose();
    //window.location.reload(false);
  }

  return (

    <div align="center">


      <Button id="Add" onClick={handleClickOpen}>
        Add a New Election
        <img className="  tw-px-1 tw-w-[40px]  tw-h-[40px] " src={add} />
      </Button>




      {/* <IconButton onClick={handleClickOpen}><MuIcons.Edit/></IconButton> */}
      <Dialog open={open} onClose={handleClose}>

        <DialogTitle >
          <h4>
            Add Election
          </h4>
        </DialogTitle>

        <DialogContent>

          <h5 >
            Please enter the Election details below
          </h5>

          <hr />
          <label for="electionyear" class="tw-block  tw-text-sm  ">Election Year</label>
          <input
            type="text"
            id="electionyear"
            onChange={handleelectionYearChange}
            placeholder="Enter election year"
            class=" tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   "

          />

          <label for="electionmonth" class="tw-block  tw-text-sm  ">Election Month</label>
          <input
            type="text"
            id="electionmonth"
            onChange={handleelectionMonthChange}
            placeholder="Enter election month"
            class=" tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   "

          />

          <label for="startdate" class="tw-block  tw-text-sm  ">Start Date</label>
          <input
            type="date"
            id="startdate"
            onChange={handlestartDtChange}
            placeholder="Enter start date"
            class=" tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   "

          />
          <div>
          <h5>Note: Make sure you end-date previous election</h5>

          </div>

        </DialogContent>

        <DialogActions>

          <Button id="back" onClick={handleClose}>Cancel</Button>
          <Button id="submit" onClick={submit}>Submit</Button>

        </DialogActions>
      </Dialog>
    </div>


  );
}

<OAGButton></OAGButton>