import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../helpers/SignInButton";
import { Outlet, Link, Redirect } from "react-router-dom";
import agencyLogo from "./../images/logo.svg";
import "./layout.css";
import Adminnav from "../helpers/adminnav"
import { QuestionMark, ContactPhone } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import GoogleTranslate from "../helpers/GoogleTranslate";
import Header from "../components/Header";
import Footer from "../components/Footer";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Layout = () => {

    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    let userfullname, userroles, name;
    let nav, motd, navbackground, title;
    if (isAuthenticated) {
        userfullname = accounts[0].idTokenClaims.preferred_username;
        name = accounts[0].idTokenClaims.name;
        userroles = accounts[0].idTokenClaims.roles;
        title = "Election Complaint Form for Volunteers";
        nav = <div className="navtext">
            <Adminnav name={name} role={userroles} />
        </div>
        navbackground = '#520081';
    } else {
        title = "Election Complaint Form";
        navbackground = '#005481';
        nav = <div>
            <SignInButton />
        </div>
    }

    const homeUri = "/";

    const navStyle = {
        backgroundColor: navbackground,
        boxShadow: "4px -2px 6px #9E9E9E"
    };

    const divStyle = {
        backgroundColor: 'white',
        boxShadow: "4px -1px 8px #9E9E9E",
        padding: "20px",
    };
    const footerStyle = {
        backgroundColor: 'white',
        // boxShadow: "4px 4px 8px #9E9E9E",
        // padding: "5px",
    };

    return (
        <>




            {/* <nav className="navbar navbar-dark bg-blue navbar-expand-md"style={loggedinStyle}>
                {motd}
            </nav> */}
            <div class="layout-container tw-flex tw-flex-col tw-min-h-screen">
                <div><Header /></div>
                <br />
                <Outlet />
                <br />
                <div >
                    <Footer />
                </div>
                </div>
            

        </>

    );
};

export default Layout;