import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, MenuItem,  DialogActions, Box, Typography, TextField } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import * as MuIcons from '@mui/icons-material';
import { OAGTextField, OAGSelect, OAGInputLabel } from '../helpers/StyledPaper';
import { protectedResources, loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import copyOutline from "../images/icons/eva/outline/copy-outline.svg";
import Button from '../components/Button';

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [token, setToken] = React.useState(null);
    const [fulllist, setfulllist] = React.useState("");

    useEffect(() => {
        async function getToken() {
            const account = msalInstance.getActiveAccount();

            if (!account) {
                ////console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const response = await msalInstance.acquireTokenSilent({
                account: account,
                ...loginRequest
            });

            setToken(response.accessToken);
        };
        getToken();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
        var i = 0;
        var list = "";
        //console.log("Length: " + props.complaintarray.length)
        while (i < props.complaintarray.length) {
            if (props.complaintarray[i].value == null) {
                list += props.complaintarray[i].label + "\n"
                i++;
            } else {
                if (i + 1 == props.complaintarray.length) {
                    list += props.complaintarray[i].label + props.complaintarray[i].value;
                    i++;
                } else {
                    list += props.complaintarray[i].label + props.complaintarray[i].value + "\n";
                    i++;
                }
            }
        }
        setfulllist(list);
        //console.log("Complaintarray: " + props.complaintarray);

        //console.log("list: " + list);

       // console.log("fulllist: " + fulllist);
        navigator.clipboard.writeText(list).then(() => { "" }).catch(() => { ""});
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div class="tw-px-5" >

            <label class="tw-block tw-mb-4 tw-text-md tw-font-medium  ">Export</label>
            <button className="tw-pt- tw-px-3  tw-transition tw-duration-300 ">
                <img className=" tw-block tw-rounded-full hover:tw-bg-lighter-blue tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={copyOutline} onClick={handleClickOpen} />
            </button>


            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Complaint successfully copied to clipboard</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        A preview of the copied complaint is displayed below

                    </DialogContentText>
                    <br />
                    <textarea class = "tw-text-dark-gray"style={{ width: '500px', height:'600px'}} disabled="true" defaultValue={fulllist} />
                </DialogContent>
                <DialogActions>
                    <Button id = "back" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

