import React, { Component } from 'react'
import { IconButton, Toolbar, Typography, FormControl, TextField, Autocomplete } from '@mui/material';
import * as MuIcons from '@mui/icons-material';
import { loginRequest, protectedResources } from "../../../authConfig";
import { msalInstance } from "../../../index";
import { OAGStyle, OAGTextField, OAGButtonGroup } from '../../../helpers/StyledPaper.js';
import Edit from "../../../images/icons/eva/outline/edit-outline.svg";
import Save from "../../../images/icons/eva/outline/save-outline.svg";
import Close from "../../../images/icons/eva/outline/close-circle-outline.svg";



export default class voterblock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            disabled: true,
        };

        //console.log("We got our Date of Birth from the Detail View")
        //console.log(this.props.voterdtls.dateOfBirth)
        this.unlock = this.unlock.bind(this)
        this.lock = this.lock.bind(this)
        this.savechanges = this.savechanges.bind(this)
        //this.formatDate = this.formatDate.bind(this)
    }

    getToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            ...loginRequest
        });

        return response.accessToken;
    }

    unlock() {
        this.setState({ disabled: false });

    };
    lock() {
        this.setState({ disabled: true });
    };

    savechanges = async e => {
        e.preventDefault();

        const token = await this.getToken();
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        headers.append("Authorization", bearer);

        var date = new Date(this.props.voterdtls.dateOfBirth);
        const PostData = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                lastName: this.props.voterdtls.lastname,
                firstName: this.props.voterdtls.firstname,
                phoneNumber: this.props.voterdtls.phonenumber,
                emailAddr: this.props.voterdtls.email,
                address: this.props.voterdtls.address,
                address2: this.props.voterdtls.address2,
                city: this.props.voterdtls.city,
                state: this.props.voterdtls.state,
                zipCode: this.props.voterdtls.zip,
                county: this.props.voterdtls.county,
                //dateOfBirth: this.state.voterdtls.dateOfBirth,
                dateOfBirth: date,
                voterId: this.props.voterdtls.voterid,
                lmUser: this.props.volunteer
            }),
        };

        //console.log(PostData);
        //console.log("Voter ID Being passed as " + this.props.voterdtls.voterid)

        const url = process.env.REACT_APP_API_URL + "Voter/" + this.props.voterdtls.voterid;
        //console.log(url);
        try {
            let response = await fetch(url, PostData);
            //console.log(response);
        }
        catch (err) {
            //console.log(err);
        }

        this.lock();
    }

    formatDate(date) {

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            //day = '' + d.getDate(),
            day = '' + d.getUTCDate(),
            year = d.getFullYear();

        //console.log(d.getDate() + " < result of getDate");
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2) {
            //console.log("Day length is less than 2");
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }


    render() {
        const { voterdtls, handleChange, formatDate } = this.props;
        const { disabled, dob } = this.state;

        const divStyle = {
            backgroundColor: 'lightgray',
            borderRadius: 5 + 'px',
            paddingTop: 2.5 + 'px',
            paddingBottom: 2.5 + 'px'
        };
        const states = usStates.map(state => (
            <option key={state.label} value={state.label}>
                {state.label}
            </option>
        ));
        const counties = nyCounties.map(county => (
            <option key={county.label} value={county.label}>
                {county.label}
            </option>
        ));

        var timelessdateOfBirth = new Date(this.props.voterdtls.dateOfBirth).toUTCString();
        //const yearfirstDate = this.formatDate(this.props.voterdtls.dateOfBirth);
        // console.log("When we set it to UTC Date String we get..")
        // console.log(timelessdateOfBirth);
        // console.log("If we then change that to be the en-CA format we get..")
        // console.log(new Date(timelessdateOfBirth).toLocaleDateString('en-CA'));
        const yearfirstDate = formatDate(timelessdateOfBirth);
        // console.log("Instead let's parse, rearrange, and concatenate the string manually")
        // console.log(yearfirstDate);

        let menu, editable;
        if (disabled) {
            editable = disabled
            menu =
                <button id="Unlock" className="text-white hover:text-blue-500" onClick={this.unlock}>
                    <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Edit} />
                </button>
        } else {
            editable = "enabled"
            menu =
                <div className="tw-flex tw-space-x-4">
                    <button
                        id="SaveChanges"
                        className="tw-rounded-full hover:tw-bg-gray tw-transition tw-duration-300"
                        onClick={this.savechanges}
                    >
                        <img className="tw-block tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Save} alt="Save Icon" />
                    </button>
                    <button
                        id="Lock"
                        className="tw-text-white   tw-rounded tw-hover:bg-white tw-hover:text-blue-500 tw-transition tw-duration-300"
                        onClick={this.lock}
                    >
                        <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Close} alt="Lock Icon" />
                    </button>
                </div>


        }

        return (

            <div class="tw-px-5  tw-shadow-xl tw-rounded-2xl tw-bg-lightest-gray">
                <div class="tw-pt-4">
                    <div className="tw-flex tw-text-center   tw-rounded-xl tw-items-center tw-justify-between tw-px-4 ">
                        <div class="tw-callout tw-callout--secondary tw-text-blue tw-text-base tw-text-center">
                            <h3>
                                Voter Details
                            </h3>
                        </div>
                        {menu}
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div class="">
                        <label for="lastname" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Last Name</label>
                        <input
                            type="text"
                            id="firstName"
                            value={voterdtls?.lastname}
                            onChange={handleChange('lastname')}
                            class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="FirstName" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>First Name</label>
                        <input
                            type="text"
                            id="FirstName"
                            value={voterdtls?.firstname}
                            onChange={handleChange('firstname')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="phonenumber" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Phone Number</label>
                        <input
                            type="text"
                            id="phonenumber"
                            value={voterdtls?.phonenumber}
                            onChange={handleChange('phonenumber')}
                            class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={30}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="email" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Email</label>
                        <input
                            type="text"
                            id="email"
                            value={voterdtls?.email}
                            onChange={handleChange('email')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="address" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Mailing Address</label>
                        <input
                            type="text"
                            id="address"
                            value={voterdtls?.address}
                            onChange={handleChange('address')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="address2" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Mailing Address line 2</label>
                        <input
                            type="text"
                            id="address2"
                            value={voterdtls?.address2}
                            onChange={handleChange('address2')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="city" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>City</label>
                        <input
                            type="text"
                            id="city"
                            value={voterdtls?.city}
                            onChange={handleChange('city')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="state" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>State</label>
                        <select
                            type="text"
                            id="state"
                            value={voterdtls?.state}
                            onChange={handleChange('state')}
                        class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                        >
                            {states}
                        </select>
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="zip" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Zip</label>
                        <input
                            type="text"
                            id="zip"
                            value={voterdtls?.zip}
                            onChange={handleChange('zip')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                            maxLength={10}
                        />
                    </div>
                </div>
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                <div >
                        <label for="county" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>County</label>
                        <select
                            type="text"
                            id="county"
                            value={voterdtls?.county}
                            onChange={handleChange('county')}
                        class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":"tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                        >
                            {counties}
                        </select>
                    </div>
                </div>

                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div >
                        <label for="state" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Date of Birth</label>
                        <input
                            type="date"
                            id="dob"
                            value={yearfirstDate}
                            onChange={handleChange('dateOfBirth')}
                        class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}


const nyCounties = [
    { label: '' },
    { label: 'Albany' },
    { label: 'Allegany' },
    { label: 'Bronx' },
    { label: 'Broome' },
    { label: 'Cattaraugus' },
    { label: 'Cayuga' },
    { label: 'Chautauqua' },
    { label: 'Chemung' },
    { label: 'Chenango' },
    { label: 'Clinton' },
    { label: 'Columbia' },
    { label: 'Cortland' },
    { label: 'Delaware' },
    { label: 'Dutchess' },
    { label: 'Erie' },
    { label: 'Essex' },
    { label: 'Franklin' },
    { label: 'Fulton' },
    { label: 'Genesee' },
    { label: 'Greene' },
    { label: 'Hamilton' },
    { label: 'Herkimer' },
    { label: 'Jefferson' },
    { label: 'Kings' },
    { label: 'Lewis' },
    { label: 'Livingston' },
    { label: 'Madison' },
    { label: 'Monroe' },
    { label: 'Montgomery' },
    { label: 'Nassau' },
    { label: 'New York' },
    { label: 'Niagara' },
    { label: 'Oneida' },
    { label: 'Onondaga' },
    { label: 'Ontario' },
    { label: 'Orange' },
    { label: 'Orleans' },
    { label: 'Oswego' },
    { label: 'Otsego' },
    { label: 'Putnam' },
    { label: 'Queens' },
    { label: 'Rensselaer' },
    { label: 'Richmond' },
    { label: 'Rockland' },
    { label: 'St. Lawrence' },
    { label: 'Saratoga' },
    { label: 'Schenectady' },
    { label: 'Schoharie' },
    { label: 'Schuyler' },
    { label: 'Seneca' },
    { label: 'Steuben' },
    { label: 'Suffolk' },
    { label: 'Sullivan' },
    { label: 'Tioga' },
    { label: 'Tompkins' },
    { label: 'Ulster' },
    { label: 'Warren' },
    { label: 'Washington' },
    { label: 'Wayne' },
    { label: 'Westchester' },
    { label: 'Wyoming' },
    { label: 'Yates' }
]

const usStates = [
    { label: '' },
    { label: 'Alabama' },
    { label: 'Alaska' },
    { label: 'Arizona' },
    { label: 'Arkansas' },
    { label: 'California' },
    { label: 'Colorado' },
    { label: 'Connecticut' },
    { label: 'Delaware' },
    { label: 'Florida' },
    { label: 'Georgia' },
    { label: 'Hawaii' },
    { label: 'Idaho' },
    { label: 'Illinois' },
    { label: 'Indiana' },
    { label: 'Iowa' },
    { label: 'Kansas' },
    { label: 'Kentucky' },
    { label: 'Louisiana' },
    { label: 'Maine' },
    { label: 'Maryland' },
    { label: 'Massachusetts' },
    { label: 'Michigan' },
    { label: 'Minnesota' },
    { label: 'Mississippi' },
    { label: 'Missouri' },
    { label: 'Montana' },
    { label: 'Nebraska' },
    { label: 'Nevada' },
    { label: 'New Hampshire' },
    { label: 'New Jersey' },
    { label: 'New Mexico' },
    { label: 'New York' },
    { label: 'North Carolina' },
    { label: 'North Dakota' },
    { label: 'Ohio' },
    { label: 'Oklahoma' },
    { label: 'Oregon' },
    { label: 'Pennsylvania' },
    { label: 'Rhode Island' },
    { label: 'South Carolina' },
    { label: 'South Dakota' },
    { label: 'Tennessee' },
    { label: 'Texas' },
    { label: 'Utah' },
    { label: 'Vermont' },
    { label: 'Virginia' },
    { label: 'Washington' },
    { label: 'West Virginia' },
    { label: 'Wisconsin' },
    { label: 'Wyoming' }
]