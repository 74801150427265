import React, { Component, } from 'react';
import downArrow from "../images/icons/eva/fill/arrow-ios-downward.svg"
import forArrow from "../images/icons/eva/fill/arrow-ios-forward.svg"

// Make sure to adjust the path

class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  editInput = (e) => {
    //console.log(e.target.id);
    { this.props.editPage(e) }
  }


  toggleAccordion = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const { title, children } = this.props;
    const { expanded } = this.state;
    // if(this.props.title == "Voter Details"){

    //   this.setState({expanded: "true"})
    // }
    var test;
     if(title == "Voter Details"){
       test = expanded ? '0' : 'auto' 

     }else{
      test = expanded ? 'auto' : '0' 
     }
    const iconSrc = expanded
      ? downArrow
      : forArrow;

      const iconSrc2 = expanded
      ? forArrow
      : downArrow;
      
    return (
      <div className="tw-accordion--item md:tw-px-10 tw-border-2 ">
        <button
          className={` tw-accordion--item-header tw-transition tw-ease-linear tw-text-lg tw-align-middle tw-text-dark-blue hover:tw-text-light-blue hover:tw-fill-light-blue tw-fill-dark-blue  tw-text-left tw-accordion--item-button `}
          //aria-expanded={expanded}
          onClick={this.toggleAccordion}
        >
          <img
            width="18" height="18"
            className={`accordion-icon tw-transition tw-ease-linear tw-inline tw-relative tw-top-[-0.09em] `}
            src={title == "Voter Details" ? iconSrc2 : iconSrc}
            alt={title == "Voter Details" ? expanded ? 'Expand' : 'Collapse' : expanded ? 'Collapse' : 'Expand'}
          />

          <span>{title} </span>

        </button>



        <div
          className={`tw-accordion--item-content `}
          style={{ height: test}}
        >
          {children}
        </div>
      </div>
    );
  }
}

class Accordion extends Component {
  render() {
    const { items } = this.props;

    return (
      <div className="tw-accordion">
        {items.map((item, index) => (
          <AccordionItem key={index} title={item.title}>
            {item.content}
          </AccordionItem>
        ))}
      </div>
    );
  }
}

export default Accordion;
