import React, { useState, useEffect } from 'react';
import * as Mui from '@mui/material';
import * as MuIcons from '@mui/icons-material';
import { OAGTextField, OAGColor } from '../helpers/StyledPaper';
import axios from 'axios';
import dayjs from 'dayjs';
import { protectedResources, loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import edit from "../images/icons/eva/outline/edit-outline.svg";
import Button from '../components/Button';

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [electionYear, setElectionYear] = React.useState(props.year);
  const [electionMonth, setElectionMonth] = React.useState(props.month);
  const [startDt, setStartDt] = React.useState(props.start);
  const [endDt, setEndDt] = React.useState(props.end);
  const [status, setStatus] = React.useState("");
  const [token, setToken] = React.useState(null);

  useEffect(() => {
    async function getToken() {
      const account = msalInstance.getActiveAccount();

      if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...loginRequest
      });

      setToken(response.accessToken);
    };
    getToken();
  }, []);

  var start = new Date(startDt).toLocaleDateString();
  //console.log(endDt)
  var endDate = endDt === null ? "" : new Date(endDt).toLocaleDateString();

  //var defaultdate = tdy.toISOString().substr(0,10);

  // React.useEffect(()=>{

  //   if(status)
  //     window.location.reload(false);
  // }, [status])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleElectionYearChange = (e) => {        // ***
    setElectionYear(e.target.value)                          // ***
  }

  const handleElectionMonthChange = (e) => {        // ***
    setElectionMonth(e.target.value)                          // ***
  }

  const handleStartDtChange = (e) => {        // ***
    setStartDt((e.target.value) + "T00:00:00")                          // ***
  }

  const handleEndDtChange = (e) => {        // ***
    setEndDt((e.target.value) + "T00:00:00")                          // ***
  }

  const update = async () => {
    //e.preventDefault();
    //await getToken();

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("content-type", "application/json");
    headers.append("accept", "*/*");
    headers.append("Authorization", bearer);


    const url = process.env.REACT_APP_API_URL + "ElectionYear/" + props.issuetypeid;

    const PostData = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        electionYear: electionYear,
        electionMonth: electionMonth,
        startDt: startDt,
        endDt: endDt,
      }),
    };

    //console.log(PostData);

    try {
      let response = await fetch(url, PostData);
      setStatus(response.status)
      //console.log(response);
    }
    catch (err) {
      //console.log(err);
    }


    handleClose();
    window.location.reload();
  }

  return (

    <div>
      <button id="lastPage" className=" tw-pt-1" onClick={handleClickOpen}>
        <img className="  hover:tw-bg-gray tw-w-[40px]  tw-h-[40px] " src={edit} />
      </button>


      <Mui.Dialog open={open} onClose={handleClose}>
        <Mui.DialogTitle >
        <h4>
            Edit
          </h4>
          </Mui.DialogTitle>
        <Mui.DialogContent>
          <h5>
          Change the below information as needed and click Update. These changes will be reflected on any submissions that occurred during the original date window.

          </h5>
          <hr />
          <label for="year" class="tw-block tw-text-blue tw-text-sm ">Year</label>
          <input
            type="text"
            id="year"
            defaultValue={electionYear}

            onChange={handleElectionYearChange}
            placeholder="Enter election year"
            class= " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5"

          />
          <label for="month" class="tw-block tw-text-blue tw-text-sm ">Month</label>
          <input
            type="text"
            id="month"
            defaultValue={electionMonth}

            onChange={handleElectionMonthChange}
            placeholder="Enter election month"
            class= " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5"

          />
        <label for="start" class="tw-block tw-text-blue tw-text-sm ">Start Date</label>
          <input
            type="date"
            id="start"
            value={start === "" ? "" : dayjs(start).format("YYYY-MM-DD")}


            onChange={handleStartDtChange}
            class= " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5"

          />

<label for="end" class="tw-block tw-text-blue tw-text-sm ">End Date</label>
          <input
            type="date"
            id="end"
            value={endDate === "" ? "" : dayjs(endDate).format("YYYY-MM-DD")}


            onChange={handleEndDtChange}
            class= " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5"

          />
          
        </Mui.DialogContent>

        <Mui.DialogActions>
          <Button id="back" onClick={handleClose}>Cancel</Button>
          <Button id="submit" onClick={update}>Update</Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </div>
  );
}