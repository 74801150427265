import React, { useState } from 'react';
import { FormControl, FormLabel, Input } from '@mui/material';
import axios from "axios";
import FormHelperText from '@mui/material/FormHelperText';


function FileUploadPage(props) {
	return (
		<div >
			<div class="tw-callout tw-callout--secondary tw-text-blue tw-text-base tw-text-center">
				<hr /><div><p>Documents</p></div>
				<hr />
			</div>

			<div  class="tw-inline-block ">
				<form >
					
						{/* <FormLabel error= {!props.attachmentValid} helperText={!props.attachmentValid ?  "Attachment is required" : ""} sx={{color: "black"}}>Contingency Contract*</FormLabel> */}
						<label class= "  tw-block tw-mb-2 tw-text-md tw-font-medium  " for="first">First Attachment</label>
						<input
							type="file"
							id="first"
							accept="application/pdf, image/png, image/jpg, image/jpeg, image/bmp"
							class="tw-mb-2"
							onChange={props.handleAttachment1Change}
						//accept="application/pdf, image/png, image/jpg, image/jpeg, image/bmp" 

						//error= {!props.attachmentValid}
						/>
						{/* <FormHelperText sx={{color:"red"}} >{!props.attachmentValid ?  "Attachment is required" : ""}</FormHelperText> */}


</form>



<form>
						<label class="tw-pr-60 tw-block tw-mb-2 tw-text-md tw-font-medium  " for="second">Second Attachment</label>
						<input
							id="second"
							type="file"
							
							accept="application/pdf, image/png, image/jpg, image/jpeg, image/bmp"
							onChange={props.handleAttachment2Change}
						//accept="application/pdf, image/png, image/jpg, image/jpeg, image/bmp" 

						/>

					
				</form >
			</div >
		</div >
	)
}

export default FileUploadPage;