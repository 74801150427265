import React, { Component } from 'react';
import { InputLabel, TextField, FormControl, Select, MenuItem } from '@mui/material';


export default class subissue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      superIssue: this.props.superissue,
      subIssues: [],
      subissueValid: true,
    };
  }

  fetchsubs = (parentissue) => {

    const url = process.env.REACT_APP_API_URL + "IssueType/SubIssues/?issue=" + parentissue;
    //console.log(url)
    const headers = new Headers();
    const authkey = 'E436997B4F77CF3456CDFF788A25D';

    headers.append("Authorization", authkey);

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(url, options)
      .then(res => res.json())
      .then(
        (result) => {

          this.setState({ subIssues: result, isLoaded: true, superIssue: parentissue })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )


    // if (error) {
    //   return <div>Error: {error.message}</div>;
    // } else if (!isLoaded) {
    //   return <div>Loading...</div>;
    // } else {
    //   return (
    //     <div>
    //       <FormControl fullWidth>
    //         <InputLabel id="issue-select-label">SubIssue</InputLabel>
    //         <Select
    //           value={values.subissue1}
    //           labelId="issue-select-label"
    //           id="issue-select"
    //           label="Issue"
    //           onChange={handleChange('subissue1')}
    //         >
    //           {subissues.map((subissue) => <MenuItem value={subissue}>{subissue}</MenuItem>)}
    //         </Select>

    //       </FormControl>

    //     </div>
    //   )
    // }


  }

  render() {
    const { superissue, value, onChange, subissueerrorvar, admin } = this.props;

    let subissuelabel;
    if (this.props.page == "Detail") {
      if (admin) {
        subissuelabel = <label class={this.props.disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "} id="subissue-select-label" required>Issue</label>
      }
      else {
        subissuelabel = <label class={this.props.disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "} id="subissue-select-label">Issue</label>
      }

    } else if (this.props.page == "Search") {

      if (admin) {
        subissuelabel = <label class={this.props.disabled ? "tw-block  tw-text-sm tw-font-medium" : "tw-pt-2 tw-block  tw-text-sm tw-font-medium"} id="subissue-select-label" required>Issue</label>
      }
      else {
        subissuelabel = <label class={this.props.disabled ? "tw-block tw-pt-2 tw-text-md tw-font-medium tw-text-dark-gray" : "tw-pt-2 tw-block  tw-text-md tw-font-medium "} id="subissue-select-label">Issue</label>
      }
    } else {

      if (admin) {
        subissuelabel = <label class={this.props.subissueerrorvar ? "tw-block  tw-text-md tw-font-medium " : " tw-block  tw-text-md tw-font-medium tw-text-light-red"} id="subissue-select-label" required>Issue *</label>
      }
      else {
        subissuelabel = <label class={this.props.subissueerrorvar ? "tw-block  tw-text-md tw-font-medium " : " tw-block  tw-text-md tw-font-medium tw-text-light-red"} id="subissue-select-label">Issue *</label>
      }
    }


    const { error, isLoaded, subIssues, superIssue } = this.state;
    //console.log(subIssues)

    if (superissue === "None of the Above / Other Issue Not Captured Elsewhere") {
      //console.log(superissue);
      if (this.props.page == "Detail") {
        return (
          <>

            <label class={this.props.disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Other Issue</label>
            <textarea
              class={this.props.disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
              type="text"
              required
              placeholder="Please explain the issue you're reporting"

              onChange={onChange}
              defaultValue={value}
              disabled={this.props.disabled}
              value={value}

              error={!subissueerrorvar}
            />
          </>
        )


      } else {
        return (
          <>

            <label class={this.props.subissueerrorvar ? "tw-block  tw-text-md tw-font-medium " : "tw-block  tw-text-md tw-font-medium tw-text-light-red"}>Other Issue*</label>
            <textarea
              class={this.props.disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-lg tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
              type="text"
              required
              placeholder="Please explain the issue you're reporting"

              onChange={onChange}
              defaultValue={value}
              disabled={this.props.disabled}
              value={value}
              maxLength={400}
              error={!subissueerrorvar}
            />
            <label class={this.props.subissueerrorvar ? "tw-hidden" : "tw-block  tw-text-sm tw-font-medium tw-text-light-red "} id="subissue-select-label">Please explain the Issue or remove Type of Complaint</label>

            <br />
          </>
        )
      }
    } else {
      if (!isLoaded)
        this.fetchsubs(superissue)
      else { superIssue !== superissue && this.fetchsubs(superissue) };

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else if (this.props.page === "Detail") {
        return (<form fullWidth>
          {/* <InputLabel id="subissue-select-label" required>Issue</InputLabel> */}
          {subissuelabel}
          <select
            class={this.props.disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
            disabled={this.props.disabled}
            value={value}
            id="subissue-select"
            label="Issue x" //I put an X here as this determines how much room the outline gives to the InputLabel above
            onChange={onChange}
            error={!subissueerrorvar}
          >
            <option value="">

            </option>
            {subIssues.map((subissue) => <option value={subissue}>{subissue}</option>)}
            {/* <MenuItem value='test'>Test</MenuItem>
            <MenuItem value='bank'>Bank</MenuItem>
            <MenuItem value='can'>Can</MenuItem> */}
          </select>
          {/* <FormHelperText>Required</FormHelperText> */}
        </form>)

      } else if (this.props.page == "Search") {
        return (
          <form fullWidth>
            {/* <InputLabel id="subissue-select-label" required>Issue</InputLabel> */}
            {subissuelabel}
            <select
              class="tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "
              disabled={this.props.disabled}
              value={value}
              labelId="subissue-select-label"
              id="subissue-select"
              label="Issue x" //I put an X here as this determines how much room the outline gives to the InputLabel above
              onChange={onChange}
              error={!subissueerrorvar}
            >
              <option value="">

              </option>
              {subIssues.map((subissue) => <option value={subissue}>{subissue}</option>)}
              {/* <MenuItem value='test'>Test</MenuItem>
              <MenuItem value='bank'>Bank</MenuItem>
              <MenuItem value='can'>Can</MenuItem> */}
            </select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </form>


        )
      } else {
        return (
          <div>
            <form fullWidth>
              {/* <InputLabel id="subissue-select-label" required>Issue</InputLabel> */}
              {subissuelabel}
              <select
                class="tw-h-[63px]  tw-border   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                disabled={this.props.disabled}
                value={value}
                labelId="subissue-select-label"
                id="subissue-select"
                label="Issue x" //I put an X here as this determines how much room the outline gives to the InputLabel above
                onChange={onChange}
                error={!subissueerrorvar}
              >
                <option value="">

                </option>
                {subIssues.map((subissue) => <option value={subissue}>{subissue}</option>)}
                {/* <MenuItem value='test'>Test</MenuItem>
              <MenuItem value='bank'>Bank</MenuItem>
              <MenuItem value='can'>Can</MenuItem> */}
              </select>
              <label class={this.props.subissueerrorvar ? "tw-hidden" : "tw-block  tw-text-sm tw-font-medium tw-text-light-red "} id="subissue-select-label">Please select Issue or remove Type of Complaint</label>
              {/* <FormHelperText>Required</FormHelperText> */}
            </form>
            <br />
          </div>
        )
      }
    }
  } //End of Render
}
