import React, { Component } from 'react'
import { OAGButtonGroup, OAGStyle, OAGTextField } from '../helpers/StyledPaper.js';
import { FormControl, InputLabel, Select, Autocomplete, Button, Box } from '@mui/material';

export class caseStatus extends Component {
  render() {


    const { handleChange, status, page } = this.props;
    if (page == "search") {
      return (

        <form fullWidth>
          <label class={this.props.disabled ? "tw-block  tw-text-sm tw-font-medium" : "tw-block  tw-text-sm tw-font-medium"} id="status-select-label">Status</label>
          <select
            value={status}
            disabled={this.props.disabled}
            labelId="status-select-label"
            id="status-select"
            label="Status"
            onChange={handleChange('status')}
            class={this.props.disabled ? "tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  " : "tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "}
          >
            {/* <option value="Action Needed / Not Assigned">Action Needed / Not Assigned</option>
                <option value="Assigned / Awaiting Information">Assigned / Awaiting Information</option>
                <option value="Resolved">Resolved</option> */}
            <option value="">

            </option>
            <option value="Not Assigned / Action Needed">Not Assigned / Action Needed</option>
            <option value="Assigned">Assigned</option>
            <option value="Awaiting Voter Information">Awaiting Voter Information</option>
            <option value="BOE Contact Needed">BOE Contact Needed</option>
            <option value="Awaiting BOE Response">Awaiting BOE Response</option>
            <option value="Need to Contact Voter with BOE Update">Need to Contact Voter with BOE Update</option>
            <option value="Resolved">Resolved</option>
            <option value="Refer to District Attorney / Other Agency">Refer to District Attorney / Other Agency</option>
            <option value="Can be Addressed Post-Election">Can be Addressed Post-Election</option>
          </select>
        </form>

      )

    } else {
      return (

        <form fullWidth>
          <label class={this.props.disabled ? "tw-block  tw-mb-2 tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium tw-mb-2 "} id="status-select-label">Status</label>
          <select
            value={status}
            disabled={this.props.disabled}
            labelId="status-select-label"
            id="status-select"
            label="Status"
            onChange={handleChange('status')}
            class={this.props.disabled ? "tw-bg-light-gray tw-h-[63px] tw-border tw-text-dark-gray tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-h-[63px] tw-border  tw-text-lg tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
          >
            {/* <option value="Action Needed / Not Assigned">Action Needed / Not Assigned</option>
                <option value="Assigned / Awaiting Information">Assigned / Awaiting Information</option>
                <option value="Resolved">Resolved</option> */}
            <option value="">

            </option>
            <option value="Not Assigned / Action Needed">Not Assigned / Action Needed</option>
            <option value="Assigned">Assigned</option>
            <option value="Awaiting Voter Information">Awaiting Voter Information</option>
            <option value="BOE Contact Needed">BOE Contact Needed</option>
            <option value="Awaiting BOE Response">Awaiting BOE Response</option>
            <option value="Need to Contact Voter with BOE Update">Need to Contact Voter with BOE Update</option>
            <option value="Resolved">Resolved</option>
            <option value="Refer to District Attorney / Other Agency">Refer to District Attorney / Other Agency</option>
            <option value="Can be Addressed Post-Election">Can be Addressed Post-Election</option>
          </select>
        </form>

      )
    }

  }
}

export default caseStatus