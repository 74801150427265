import React, { Component } from 'react'
import { Paper, FormControl, TextField, ButtonGroup } from '@mui/material';
import NoteCard from './notecomponent';
import Button from '../../../components/Button.js';

import { loginRequest, protectedResources } from "../../../authConfig";
import { msalInstance } from "../../../index";

export default class noteblock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            note_complaint_id: '',
            notetype: '',
            note: '',
            cruser: this.props.volunteer,
            incidentnotes: [],
        };

    }

    async componentDidMount() {
        const token = await this.getToken();
        this.getIncidentNotes(this.props.id, token);
    }

    getToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            ...loginRequest
        });

        return response.accessToken;
    }

    // handle field change
    handleChange = input => (event) => {
        this.setState({ [input]: event.target.value });
    }

    getIncidentNotes(id, token) {
        const url = process.env.REACT_APP_API_URL + "Notes/Notes/" + id;
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };

        //console.log("Targeting url: " + url);
        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        incidentnotes: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        //console.log(this.state.incidentnotes);
    }

    deletenote = async (noteid) => {
        //e.preventDefault();
        const token = await this.getToken();

        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        headers.append("Authorization", bearer);

        const options = {
            method: "DELETE",
            headers: headers,
        };

        const deleteurl = process.env.REACT_APP_API_URL + "Notes/" + noteid;
        try {
            let response = await fetch(deleteurl, options);
        } catch (err) {
            //console.log(err);
        }
        window.location.reload(true);
    }

    onSubmit = async e => {
        e.preventDefault();
        const token = await this.getToken();

        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        headers.append("Authorization", bearer);

        const PostData = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                noteType: this.props.notetype,
                noteContClob: this.state.note,
                crUser: this.state.cruser,
                cmpltId: this.props.id,
            }),
        };
        const url = process.env.REACT_APP_API_URL + "Notes";
        try {
            let response = await fetch(url, PostData);
        }
        catch (err) {
            //console.log(err);
        }
        window.location.reload(false);
    }

    render() {

        return (
            <div>

                <label>Notes</label>
                <Paper style={{ maxHeight: 555, overflow: 'auto' }}>
                    {this.state.incidentnotes.map((note, key) => (
                        <NoteCard key={key} noteid={note.noteId} title={note.crUser} date={note.crDt} content={note.noteContClob} deletenote={this.deletenote} />
                    ))}
                </Paper>
                <form className='tw-flex tw-flex-col'>

                    <textarea
                        placeholder="Please write a note of any changes or communications with the Voter."
                        id="Note"
                        rows="3"
                        //maxRows="4"
                        onChange={this.handleChange('note')}
                        defaultValue={this.state.note}
                        className="tw-border tw-rounded tw-p-2 tw-placeholder-gray"
                        maxLength={1000}
                    ></textarea>
                    <div align = "right" class ="tw-pt-2 ">

                        <Button id="NoteSubmit" onClick={this.onSubmit}>Submit</Button>
                    </div>
                </form>

            </div>


        )
    }
}
