import React, {View, Component } from 'react';
import { Outlet, Link, Redirect } from "react-router-dom";
import { SignOutButton } from "../helpers/SignOutButton";

function adminnav(props) {
  let adminlinks, userlink1, userlink2, signoutlink;
  if (props.role == "Volunteer") {
    userlink1 = <>
                
              </>

    signoutlink = <div className="col-sm-4" style={{paddingTop: '5px'  }}>
                    <SignOutButton />
                  </div>

  } else if (props.role == "Admin" || props.role == "IT") {

    
                
    adminlinks =  <div className="col-sm-2" >
                    <label color="white !important" >{props.role}</label>
                  </div>
    signoutlink = <div className="col-sm-3" style={{paddingTop: '5px'  }}>
                    <SignOutButton />
                  </div>
              
  }
  
  return (
    <div className="row">
      {userlink1}
      {userlink2}
      {adminlinks}   
      {signoutlink}                   
    </div>
  )
}

export default adminnav
