import React, { useEffect } from "react";

const GoogleTranslate = () => {
  useEffect(() => {
    // Function to initialize Google Translate element
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          multilanguagePage: true,
          gaTrack: true,
          gaId: 'UA-33973995-1', // Change this to your PROD Google Analytics ID #
        },
        "google_translate_element"
      );
    };

    // Load the Google Translate API script
    const addScript = document.createElement("script");
    addScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    addScript.async = true;
    document.body.appendChild(addScript);

    // Set the googleTranslateElementInit function to the global scope
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div id="google_translate_element"></div>
  );
};

export default GoogleTranslate;
