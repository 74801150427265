import React, { useState, useEffect } from 'react';
import {IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import {Edit} from '@mui/icons-material';
import { OAGTextField,OAGColor } from '../helpers/StyledPaper';
import Button from '../components/Button';
import { protectedResources, loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import edit from "../images/icons/eva/outline/edit-outline.svg";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [issue, setIssue] = React.useState(props.issue);
  const [subissue, setSubIssue] = React.useState(props.subissue);
  const [token, setToken] = React.useState(null);

  useEffect(() => {
    async function getToken() {
      const account = msalInstance.getActiveAccount();

      if (!account) {
          console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...loginRequest
    });

      setToken(response.accessToken);
    };
    getToken();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIssueChange = React.useCallback(e => {        // ***
    setIssue(e.target.value)                          // ***
  }, [])

  const handleSubIssueChange = React.useCallback(e => {        // ***
    setSubIssue(e.target.value)                          // ***
  }, [])

  const update = async () => {
    //e.preventDefault();
    //await getToken();

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("content-type", "application/json");
    headers.append("accept", "*/*");
    headers.append("Authorization", bearer);

    const PostData = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
            issueType: issue,
            issueSubtype: subissue,    
        }),
      };

    //console.log(PostData);

    const url = process.env.REACT_APP_API_URL + "IssueType/" + props.issuetypeid;
    
    try{
        let response = await fetch(url, PostData);
        //console.log(response);
    }
    catch (err){
        //console.log(err);
    }

    handleClose();
    window.location.reload(false);
}

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <button id="lastPage" className=" tw-pt-1" onClick={handleClickOpen}>
        <img className="  hover:tw-bg-gray tw-w-[40px]  tw-h-[40px] " src={edit} />
      </button>
      <Dialog open={open} onClose={handleClose}>

        <DialogTitle >
          <h4>
            Edit
          </h4>
          </DialogTitle>
        <DialogContent>
        <h5>
        Change the below information as needed and click Update. These changes will be reflected on any new submissions.
        </h5>
        <hr />
        <label for="issue" class="tw-block tw-text-blue tw-text-sm ">Type of Complaint</label>
          <input
            type="text"
            id="issue"
            defaultValue={props.issue}

            onChange={handleIssueChange}
            placeholder="Type of Complaint"
            class= " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5"

          />
          <label for="subissue" class="tw-block tw-text-blue tw-text-sm ">Issue</label>
          <input
            type="text"
            id="subissue"
            defaultValue={props.subissue}

            onChange={handleSubIssueChange}
            placeholder="Enter Issue"
            class= " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5"

          />
          
          
          
        </DialogContent>
        <DialogActions>
        <Button id="back" onClick={handleClose}>Cancel</Button>
          <Button id="submit" onClick={update}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}