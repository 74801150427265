import React, { Component } from 'react'
import { useMemo } from 'react';

import {Box, Stack} from '@mui/material';
//import * as MuIcons from '@mui/icons-material';
import EditModal from '../../modals/electioneditmodal';
import DeleteModal from '../../modals/electiondeletemodal';
import AddModal from '../../modals/electionaddmodal';
import CustomPagination from '../../components/customPagination';
import TableCont from '../../helpers/tablecontainer';

import {OAGColor} from '../../helpers/StyledPaper.js';

import { loginRequest, protectedResources } from "../../authConfig";
import { DataGrid } from '@mui/x-data-grid';
import { msalInstance } from "../../index";

export class ElectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            disabled: true,
            open: false,
            elections: [],
            test: '',
            pageSize: 25,  // Number of items per page
		
            page: 0,
        };

        this.unlock = this.unlock.bind(this) 
        this.lock = this.lock.bind(this) 
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    async componentDidMount() {
      //console.log("In componentdidmount");
      const accessToken = await this.getToken();
      //console.log(accessToken);

      const url =  process.env.REACT_APP_API_URL + "ElectionYear";
      
      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
  
      headers.append("Authorization", bearer);

      const options = {
          method: "GET",
          headers: headers,
      };
      //console.log(options);

      fetch(url, options)
        .then(res => res.json())
        .then(
          (result) => {
           // console.log("hellllo")
            this.setState({
              isLoaded: true,
              elections: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
          
        )
    }

  getToken = async () => {
      const account = msalInstance.getActiveAccount();
  
      if (!account) {
          console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }
      //console.log("About to get the Election List token")
      const response = await msalInstance.acquireTokenSilent({
          account: account,
          //...protectedResources.apiElectionHotline.scopes
          ...loginRequest
          //scopes: [ "api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user" ]
      });
      //console.log(response);
  
      return response.accessToken;
  }
  handlePageSizeChange = (newPageSize) => {
		this.setState({ pageSize: newPageSize });
	};
    unlock() {
    this.setState({disabled: false});
    };
    lock () {
    this.setState({disabled: true});
    };

    open() {
      this.setState({open: true});
    };
    
    close() {
      this.setState({open: false});
    };

    // handle field change
  handleChange = input => (event) => {
    this.setState({ [input]: event.target.value });
  }
  handlePageChange = (newPage) => {
		this.setState({ page: newPage }); // Update the 'page' state with the new page number
	};

  render() {
    //console.log(this.state.elections)
    const { error, isLoaded, elections,page,pageSize } = this.state;
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
          <div>
            {/* <ButtonGroup>
              <Button
                sx={{ width: 250,
                      marginLeft: 'auto',}}
                color="primary"
                variant="contained"
                onClick={this.add}
                endIcon={<MuIcons.Add />}> Add Another Issue
              </Button>
            </ButtonGroup> */}

            <AddModal />

            {/* <OAGColor>
            <TableContainer sx={{ width: "100%", marginRight: "auto", marginLeft: "auto", marginBottom: "auto" }} component={Paper} >
              <Table sx={{ overflowX: "scroll", width: "max-content" }} size="medium" aria-label="a dense table" tableLayout="auto">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Election Year</TableCell>
                            <TableCell align="left">Election Month</TableCell>
                            <TableCell align="left">Effective Start Date</TableCell>
                            <TableCell align="left">Effective End Date</TableCell>
                            <TableCell align="left">Edit</TableCell>
                            <TableCell align="left">Close this Election</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.elections.map((row, key) => (
                        <TableRow
                          key={key}                    
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        > 
                          <TableCell width= "100px" align="left">{row.electionYearId}</TableCell>
                          <TableCell width= "200px" align="left">{row.electionYear}</TableCell>
                          <TableCell width= "200px" align="left">{row.electionMonth}</TableCell>
                          <TableCell width= "200px" align="left">{new Date(row.startDt).toLocaleDateString()}</TableCell>
                          <TableCell width= "200px" align="left">{row.endDt == null ? row.endDt : new Date(row.endDt).toLocaleDateString()}</TableCell>
                          <TableCell align="center"> <EditModal issuetypeid={row.electionYearId} year={row.electionYear} month={row.electionMonth} start={row.startDt} end={row.endDt} /></TableCell>
                          <TableCell align="center"><DeleteModal issuetypeid={row.electionYearId} /></TableCell> 
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> 
                
            </OAGColor>   */}

        

            {/* <h2>NEW CODE BELOW</h2> */}
            <hr/>
            <div className="tw-bg-dot-pattern">
            <div class="tw-px-5">
              <div className=" tw-shadow-xl tw-overflow-x-auto">

                <Box sx={{ height: 800, width: '100%', backgroundColor: "white" }}>
                  <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'electionYearId', sort: 'desc' }],
                    },
                  }}
                  page={page}
                  onPageChange={(newPage) => this.handlePageChange(newPage)}
                  rows={elections}
                  pageSize={pageSize}
                  columns={columns}
                  onPageSizeChange={(newPageSize) => this.handlePageSizeChange(newPageSize)} // Handle page size change

                  getRowId={(row) => row.electionYearId}
                  rowsPerPageOptions={[25, 50, 100]} // You can customize the available page sizes

                  disableSelectionOnClick
                  />
                </Box>
              </div>
            </div>
          </div> 
          </div>
        )
      }
  }
}
export default ElectionList;

const columns =  [
  { field: 'electionYearId', headerName: 'ID', width: 90 },
  {
    field: 'electionYear',
    headerName: 'Election Year',
    width: 200,
    editable: false,
  },
  {
    field: 'electionMonth',
    headerName: 'Election Month',
    // type: 'number',
    width: 200,
    editable: false,
  },
  {
    field: 'startDt',
    headerName: 'Start Date',
    width: 200,
    editable: false,
    renderCell: (params) => {
      const stdate = new Date(params.row.startDt).toLocaleDateString();
      return stdate;
    }
  },
  {
    field: 'endDt',
    headerName: 'End Date',
    width: 200,
    editable: false,
    renderCell: (params) => 
    {
      const endt = params.row.endDt == null ? params.row.endDt : new Date(params.row.endDt).toLocaleDateString();
      return endt;
    }
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 80,
    sortable: false,
    disableClickEventBubbling: true,
    
    renderCell: (params) => {
      // const onClick = (e) => {
      //   const currentRow = params.row;
      //   return alert(JSON.stringify(currentRow, null, 4));
      // };
        
      return (
       
          <EditModal issuetypeid={params.row.electionYearId} year={params.row.electionYear} month={params.row.electionMonth} start={params.row.startDt} end={params.row.endDt} />
          
       
      );
    },
  },
  {
    field: 'closeelection',
    headerName: 'Close This Election',
    width: 200,
    sortable: false,
    disableClickEventBubbling: true,
    
    renderCell: (params) => {
      // const onClick = (e) => {
      //   const currentRow = params.row;
      //   return alert(JSON.stringify(currentRow, null, 4));
      // };
        
      return (
        
          
          <DeleteModal issuetypeid={params.row.electionYearId} />
          
        
      );
    },
  }
  ]