import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Mui from '@mui/material';
import * as MuIcons from '@mui/icons-material';
import { OAGColor, OAGStyle, OAGTextField } from '../helpers/StyledPaper';
import Button from '../components/Button';

import { protectedResources, loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import deleteElec from "../images/icons/eva/outline/close-circle-outline.svg";

export default function IssueDelete(props) {
  const [open, setOpen] = React.useState(false);
  const [confirm, setConfirm] = React.useState(props.issue);
  const [err, setErr] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [yesterday, setYesterday] = React.useState(null);

  useEffect(() => {

    var tdy = new Date();
    tdy.setDate(tdy.getDate() - 1);
    var defaultdate = tdy.toISOString().substr(0, 10);
    setYesterday(defaultdate);

    async function getToken() {
      const account = msalInstance.getActiveAccount();

      if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...loginRequest
      });

      setToken(response.accessToken);
    };
    getToken();
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmChange = React.useCallback(e => {        // ***
    setConfirm(e.target.value)                          // ***
  }, [])


  const deleteissue = async () => {
    //e.preventDefault();
    if (confirm === 'CONFIRM') {
      //We go
      //await getToken();

      const headers = new Headers();
      const bearer = `Bearer ${token}`;

      headers.append("content-type", "application/json");
      headers.append("accept", "*/*");
      headers.append("Authorization", bearer);


      const PostData = {
        method: "DELETE",
        headers: headers,
      };

      //console.log(PostData);

      const url = process.env.REACT_APP_API_URL + "ElectionYear/" + props.issuetypeid;

      try {
        let response = await fetch(url, PostData);
        //console.log(response);
      }
      catch (err) {
        //console.log(err);
      }

      handleClose();
      window.location.reload(false);
    }
    else {
      //We throw an error on the modal
      setErr(true);
    }


  }

  return (
    <div>

      <button id="lastPage" className="tw-pt-1" onClick={handleClickOpen}>
        <img className="  hover:tw-bg-gray tw-w-[40px]  tw-h-[40px] " src={deleteElec} />
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <h4>
            End-Date
          </h4>
        </DialogTitle>
        <DialogContent>
          <h5>
          Please type CONFIRM in the box below to confirm setting the end date of this Election to <b>{yesterday}</b>.

          </h5>
          

          <hr />
          <label for="end" class={err ? "tw-block tw-text-red tw-text-sm" : "tw-block tw-text-blue tw-text-sm "}>Type CONFIRM to continue</label>
          <input
            type="text"
            id="end"
            onChange={handleConfirmChange}
            placeholder="Type CONFIRM to continue"
            class={err ? "tw-border-red tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   " : " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   "}

          />
          <label for="end" class={!err ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Please Type CONFIRM in all caps</label>

        </DialogContent>
        <DialogActions>
          <Button id="back" onClick={handleClose}>Cancel</Button>
          <Button id="submit" onClick={deleteissue}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}