import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from '@mui/material/Button';


function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

// function handleLogin(instance) {
//     instance.loginPopup(loginRequest).catch(e => {
//         console.error(e);
//     });
// }

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    //console.log("loginRequest  " + loginRequest);
   // console.log("instance:  " + instance);

   // console.log("loginRequest scopes: " + loginRequest.scopes);
    return (
        
        <button  class="menu-link menu-link--button" onClick={() => handleLogin(instance)}>Sign in</button>
        
        
    );
}