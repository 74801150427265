import React, { Component } from 'react'
import { Link } from "react-router-dom";
import VoterDetails from "../common/formcomponents/voterdetails";
import IncidentDetails from "../common/formcomponents/incidentdetails";
import BoeQuestions from "../common/formcomponents/boequestions";
import Confirm from "../common/formcomponents/confirmation";
import SubmissionStatus from "../common/formcomponents/SubmissionStatus"
import { loginRequest, protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";
import axios from 'axios';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import VolunteerCases from '../admin/volunteercases';
import MOTDBanner from '../../helpers/motdbanner';
import staticIcon from "../../images/icons/eva/status/static.svg";
import active from "../../images/icons/eva/status/active.svg";
import complete from "../../images/icons/eva/status/complete.svg";

export default class ComplaintSbS extends Component {
  state = {
    step: 1,
    issuesCount: 1,
    lastname: '',
    firstname: '',
    phonenumber: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: 'New York',
    zip: '',
    county: '',
    dob: '',
    contactmethod: 'Email / Web Form',
    contactdate: '',
    pollingplace: '',
    pollingaddr: '',
    dateofincident: '',
    incidentdetails: '',
    issues: [],
    subIssues: [],
    otherIssues: [],
    issue1: "",
    subissue1: "",
    otherissue1: "",
    issue2: "",
    subissue2: "",
    otherissue2: "",
    issue3: "",
    subissue3: "",
    otherissue3: "",
    electiondistrict: '',
    assemblydistrict: '',
    affidavitcast: '',

    pollworker: '',
    boecontacted: '',
    boecontact: '',
    followupreqd: '',
    issuerslved: '',
    independentescalation: '',
    votercontactlocalboe: '',

    localboeressolve: '',
    formerrors: false,
    currentelection: [],
    submissionStatusCode: '',
    confirmationCode: '',
    complaints: [],
    firstAttachment: [],
    secondAttachment: [],
    firstAttachmentName: [],
    secondAttachmentName: [],
    firstAttachmentExtension: [],
    secondAttachmentExtension: [],
    messageString: '',
    link: '',
    link_text: '',
  };

  componentDidMount() {
    //this.getIssues();
    //this.getMOTD();

  }


  setSubIssue = (e, index) => {
    var newIssue = this.state.subIssues.slice();

    //Replace the array element if index is not at last element
    if (index + 1 < this.state.subIssues.length)
      newIssue.splice(index, 1, e.target.value);
    //if user try to change the subissue
    else if (this.state.issuesCount === this.state.subIssues.length) {
      newIssue.pop();
      newIssue.push(e.target.value);
    } else newIssue.push(e.target.value);

    this.setState({ subIssues: newIssue });
  };

  setOtherIssue = (e, index) => {
    var newIssue = this.state.otherIssues.slice();
    if (this.state.issuesCount <= this.state.otherIssues.length) newIssue.pop();
    newIssue.push(e.target.value);
    this.setState({ otherIssues: newIssue });
  };

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
    //console.log(this.state.step)
  };

  //Set step when edidt button pressed on confirmation screen
  setStep = (proceedToStep) => {
    this.setState({ step: proceedToStep })
  }

  setIssue = (e, index) => {
    var newIssue = this.state.issues.slice();

    //Need to
    var newSubIssue = this.state.subIssues.slice();

    //Replace the array element if index is not at last element
    if (index + 1 < this.state.issues.length) {
      newIssue.splice(index, 1, e.target.value);
      newSubIssue.splice(index, 1, "");
    }

    //IF user try to change the issue then store new issue
    else if (this.state.issuesCount === this.state.issues.length) {
      newIssue.pop();
      newSubIssue.pop();
      newIssue.push(e.target.value);
    } else newIssue.push(e.target.value);

    this.setState({ issues: newIssue, subIssues: newSubIssue });
  };

  setSubmissionCodes = (statusCode, confCode) => {
    this.setState({
      submissionStatusCode: statusCode,
      confirmationCode: confCode,
    });
  };

  // handle field change
  handleChange = (input) => (event) => {
    //this.setState({ [input]: event.target.value });
    //console.log(input);
    if (input == "phonenumber") {
      //console.log("inside")
      const formattedNumber = this.formatPhoneNumber(event.target.value);
      this.setState({ [input]: formattedNumber });
    }
    else if (input == "zip") {
      //console.log("inside")
      const formattedZip = this.formatZip(event.target.value);
      //console.log("formattedNumber: " + formattedZip);

      this.setState({ [input]: formattedZip })


    }
    else {
      this.setState({ [input]: event.target.value });
    }
  };

  formatPhoneNumber = (number) => {

    //console.log(number);
    //console.log("number: " + number);

    const phoneNumber = number.replace(/[^\d]/g, '');
    //console.log("phoneNumber: " + phoneNumber);
    const length = phoneNumber.length;

    if (length < 4) { return `(${phoneNumber}` };

    if (length < 7) {
      //console.log("< 7: " + phoneNumber);

      //console.log(`(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`);

      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;

    }
    //console.log(`(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6,)}-${phoneNumber.slice(6,10)}`);
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6,)}-${phoneNumber.slice(6, 10)}`;

  }
  formatZip = (number) => {

    const zipNum = number.replace(/[^\d]/g, '');
    const length = zipNum.length;
    if (length < 6) {
      return zipNum;
    } else if (length == 6) {

      //console.log(`${zipNum.slice(0, 5,)}-${zipNum.slice(5, 10)}`);

      return `${zipNum.slice(0, 5,)}-${zipNum.slice(5, 9)}`;
    }
    return `${zipNum.slice(0, 5,)}-${zipNum.slice(5, 9)}`;


  }
  handleattachment1FileSelect = (event) => {
    if (event.target.files[0].size > 20e6) {
      window.alert("Please upload a file smaller than 20 MB");
      return false;
    }
    //console.log("In the attachment 1 change handler")

    var files = event.target.files;
    var fileName = files[0].name;
    //console.log("what we got? " + fileName);

    const fileExtension = event.target.files[0].name.split(".").at(-1);
    const allowedFileTypes = ["pdf", "png", "jpg", "jpeg", "bmp"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`Unsupported  file type. Files type must be .${allowedFileTypes}`);
      return false;
    }

    this.setState({ firstAttachment: event.target.files[0] })
    this.setState({ firstAttachmentName: fileName })
    this.setState({ firstAttachmentExtension: fileExtension })

  };

  handleattachment2FileSelect = (event) => {
    if (event.target.files[0].size > 20e6) {
      window.alert("Please upload a file smaller than 20 MB");
      return false;
    }
    const fileExtension = event.target.files[0].name.split(".").at(-1);
    const fileName = event.target.files[0].name;
    const allowedFileTypes = ["pdf", "png", "PNG", "jpg", "jpeg", "bmp"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`Unsupported file type. Files type must be .${allowedFileTypes}`);
      return false;
    }

    this.setState({ secondAttachment: event.target.files[0] })
    this.setState({ secondAttachmentName: fileName })
    this.setState({ secondAttachmentExtension: fileExtension })

  };

  // clear issues
  removeIssues = () => {
    var newIssue = this.state.issues.slice();
    var newSubIssue = this.state.subIssues.slice();

    if (this.state.issues[this.state.issuesCount - 1] === undefined)
      this.setCount("remove");

    else {

      if (this.state.subIssues.length === this.state.issues.length) {
        newIssue.pop();
        newSubIssue.pop();
        this.setState({ issues: newIssue, subIssues: newSubIssue });
      }
      else {
        newIssue.pop();
        this.setState({ issues: newIssue });
      }
      this.setCount("remove")
    };
  }

  setCount = (action) => {
    const { issuesCount } = this.state;
    if (action === "add") this.setState({ issuesCount: issuesCount + 1 });
    else this.setState({ issuesCount: issuesCount - 1 });
  };

  getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...loginRequest
    });

    return response.accessToken;
  }

  // getMOTD = () => {
  //   const url = process.env.REACT_APP_API_URL + "MOTD";
  //   const token = this.getToken()
  //   const headers = new Headers();
  //   const bearer = `Bearer ${token}`;

  //   headers.append("Authorization", bearer);

  //   const options = {
  //       method: "GET",
  //       headers: headers,
  //   };

  //   fetch(url, options)
  //       .then(res => res.json())
  //       .then(
  //           (result) => {
  //               this.setState({
  //                   isLoaded: true,
  //                   messageString: result.adminAlert,
  //                   link: result.link,
  //                   link_text: result.linkText
  //               });
  //           },
  //           (error) => {
  //               this.setState({
  //                   isLoaded: true,
  //                   error
  //               });
  //           }

  //       )
  // }

  toISOLocal = (d) => {
    var z = n => ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off > 0 ? '-' : '+';
    off = Math.abs(off);

    return d.getFullYear() + '-'
      + z(d.getMonth() + 1) + '-' +
      z(d.getDate()) + 'T' +
      z(d.getHours()) + ':' +
      z(d.getMinutes()) + ':' +
      z(d.getSeconds()) + '.' +
      zz(d.getMilliseconds()) +
      sign + z(off / 60 | 0) + ':' + z(off % 60);
  }
  checkDate = (date) => {
    const dateNum = date.replace(/[^\d]/g, '')
    const today = new Date();

    //Check if the year is < today
    if (dateNum.slice(0, 4) < today.getFullYear()) {

      return true;

      //console.log("Valid Year :" + dateNum.slice(0,4));

      //if the year is the same as today check if the month is < today
    } else if (dateNum.slice(0, 4) == today.getFullYear() && dateNum.slice(4, 6) < (today.getMonth() + 1)) {

      return true;

      //if the year and month are the same as today check if the date is =< today
    } else if (dateNum.slice(0, 4) == today.getFullYear() && dateNum.slice(4, 6) == (today.getMonth() + 1) && dateNum.slice(6, 8) <= today.getDate()) {

      return true;

    } else {
      //console.log("Invalid Date");

      return false;
    }


  };
  render() {
    var tdy = new Date();
    //tdy.setDate(tdy.getDate());
    var localdate = this.toISOLocal(tdy);
    var defaultdate = localdate.substr(0, 10);

    const { step } = this.state;
    const { submissionStatusCode } = this.state;
    const steps = [
      'Your Information',
      'Incident Details',
      'Questions relating to Board of Elections',
      'Details Confirmation and Submission',
      'Submission Status'
    ];

    const { lastname, firstname, phonenumber, email, address, address2, city, state, zip, county, dob,
      contactmethod, contactdate, pollingplace, pollingaddr, dateofincident, incidentdetails, electiondistrict, assemblydistrict, affidavitcast,
      issues, issuesCount, subIssues, otherIssues,
      issue1, subissue1, issue2, subissue2, issue3, subissue3, otherissue1, otherissue2, otherissue3,
      pollworker, boecontacted, issuerslved, votercontactlocalboe, boecontact, independentescalation,
      firstAttachment, secondAttachment, firstAttachmentName, firstAttachmentExtension, secondAttachmentName, secondAttachmentExtension, messageString, link, link_text } = this.state;
    const values = {
      lastname, firstname, phonenumber, email, address, address2, city, state, zip, county, dob,
      contactmethod, contactdate, pollingplace, pollingaddr, dateofincident, incidentdetails, electiondistrict, assemblydistrict, affidavitcast,
      issues, issuesCount, subIssues, otherIssues,
      issue1, subissue1, issue2, subissue2, issue3, subissue3, otherissue1, otherissue2, otherissue3,
      pollworker, boecontacted, issuerslved, votercontactlocalboe, boecontact, independentescalation,
      firstAttachment, secondAttachment, firstAttachmentName, firstAttachmentExtension, secondAttachmentName, secondAttachmentExtension, messageString, link, link_text
    }
    if (values.dateofincident == "") {
      values.dateofincident = defaultdate;

    }
    if (values.contactdate == "") {
      values.contactdate = defaultdate;

    }
    // let motdbanner = '';
    // if(values.messageString === null)
    // {
    //   motdbanner = <div></div>;
    // }
    // else
    // {
    //   motdbanner = <MOTDBanner/>
    // }

    if (this.props.userfullname) {
      return (
        <div >
          <div class="tw-px-5">
            <MOTDBanner />
          </div>

          <br />
          <div class="tw-px-5">
            <label class = "tw-text-sm md:tw-text-base"> Hello {this.props.userfullname}! </label>
            <br />
            <label class = "tw-text-sm md:tw-text-base">
              Please use the menu to navigate to the internal Complaint Form, or Search for an existing complaint.

            </label>

          </div>

          <div>
            <VolunteerCases useremail={this.props.useremail} />
          </div>



        </div>
      )
    }
    else {
      switch (step) {
        case 1:
          return (
            <div>
              <br />
              <div class="tw-overflow-x-auto">
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step - 1} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel icon={
                          label == "Your Information" ?
                            <img height="30px" width="30px" src={active} />
                            : (
                              <img height="30px" width="30px" src={staticIcon} />
                              // You can use other icons or components for incomplete steps
                            )

                        }>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              <VoterDetails
                checkDate={this.checkDate}
                toISOLocal={this.toISOLocal}
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                values={values} />

            </div>
          )

        case 2:
          return (

            <div>
              <br />
              <br /><div class="tw-overflow-x-auto">
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step - 1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel icon={label == "Your Information" ? <img height="30px" width="30px" src={complete} /> : label == "Incident Details" ? <img height="30px" width="30px" src={active} /> : <img height="30px" width="30px" src={staticIcon} />}>
                          {label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              <IncidentDetails
                checkDate={this.checkDate}
                toISOLocal={this.toISOLocal}
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                setIssue={this.setIssue}
                setCount={this.setCount}
                removeIssues={this.removeIssues}
                setSubIssue={this.setSubIssue}
                handleChange={this.handleChange}
                values={values}
                steps={steps}
                clearissue2={this.clearissue2}
                clearissue3={this.clearissue3}
                handleattachment1FileChange={this.handleattachment1FileSelect}
                handleattachment2FileChange={this.handleattachment2FileSelect}


              />


            </div>
          )
        case 3:
          return (

            <div>
              <br />
              <div class="tw-overflow-x-auto">
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step - 1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel icon={label == "Your Information" || label == "Incident Details" ? <img height="30px" width="30px" src={complete} /> : label == "Questions relating to Board of Elections" ? <img height="30px" width="30px" src={active} /> : <img height="30px" width="30px" src={staticIcon} />}>
                          {label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              <BoeQuestions
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                values={values}
                steps={steps}
              />

            </div>
          )
        case 4:
          return (

            <div>
              <br />
              <div class="tw-overflow-x-auto">
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step - 1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel icon={label == "Your Information" || label == "Incident Details" || label == "Questions relating to Board of Elections" ? <img height="30px" width="30px" src={complete} /> : label == "Details Confirmation and Submission" ? <img height="30px" width="30px" src={active} /> : <img height="30px" width="30px" src={staticIcon} />}>
                          {label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              <Confirm
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                steps={steps}
                setStep={this.setStep}
                handleSubmit={this.handleChange}
                setSubmissionCodes={this.setSubmissionCodes}
              />

            </div>

          )
        case 5:
          return (
            <div>
              <br />
              <div class="tw-overflow-x-auto">
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step - 1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel icon={label == "Your Information" || label == "Incident Details" || label == "Questions relating to Board of Elections" || label == "Details Confirmation and Submission" ? <img height="30px" width="30px" src={complete} /> : label == "Submission Status" ? <img height="30px" width="30px" src={active} /> : <img height="30px" width="30px" src={staticIcon} />}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              <SubmissionStatus
                nextStep={this.nextStep}
                submissionStatusCode={this.state.submissionStatusCode}
                confirmationCode={this.state.confirmationCode}
              />
            </div>

          )
        default:
          // do nothing
          return (
            <div>
              <h1> hello </h1>
            </div>
          )
      }
    }

  }
}
