import React from 'react';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/system';
import back from "../images/icons/eva/fill/arrow-ios-back.svg";
import forward from "../images/icons/eva/fill/arrow-ios-forward.svg";


const CustomPaginationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& button': {
    margin: '0.5rem',
  },
});

const CustomPagination = (props) => {
    const adjustedPage = props.page + 1;
  return (
    <CustomPaginationContainer>
       
          <Pagination count={props.pageCount -1} page={adjustedPage} onChange={(_, page) => props.onChange(page - 1)} />
          
      
      
      
    </CustomPaginationContainer>
  );
};

export default CustomPagination;
