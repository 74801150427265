import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { yellow } from "@mui/material/colors";
import { PrimaryBlue } from "./OAGColors";
import { ButtonGroup, Grid, Input, InputLabel, Select, Stepper, TextField, Typography } from "@mui/material";

export const OAGStyle = styled(Typography, 
  {
    name: "OAGStyle", 
    slot: "Wrapper"

  //focused border color
})({
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": {
borderColor: "#053451"}},

//focused text color
'& label.Mui-focused': {
  color: '#007EA8',
},

//Border color on hover
"& .MuiOutlinedInput-root:active:hover": {
  "& > fieldset": {
  borderColor: "#053451"}},

//Focused Text color
"& .MuiInputBase-root":{
  color: "#000000"
},

//default label text color
'& label':{

  color: "#005481"
},

  //default border color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#005481',
    }},





  //required text color
  "& .MuiFormHelperText-root" :{
    color: "red !important"
  },



//back buttons 
"& #back.MuiButton-root":{
  backgroundColor: "#053451",
  
},
"& #back.MuiButton-root:hover":{
  backgroundColor: "#042a41",
  
},


  //next buttons 
  "& #next.MuiButton-root":{
    backgroundColor: "#005481",
    
  },
  "& #next.MuiButton-root:hover":{
    backgroundColor: "#004367",
    
  },

//submit button
  "& #submit.MuiButton-root":{
    backgroundColor: "#005481",
    
  },
  "& #submit.MuiButton-root:hover":{
    backgroundColor: "#004367",
    
  },





  //Remove button incident detail page
  "& #Removed.MuiButton-root":{
    width: 250,
    marginLeft: 'auto',
    backgroundColor: "#9B2915"
    
  },
  "& #Removed.MuiButton-root:hover":{
    backgroundColor: "#7c2111"

  },
  //Add button incident detail page
  "& #Add.MuiButton-root":{
    width: 250,
    backgroundColor: "#00A676"
  },
  "& #Add.MuiButton-root:hover":{
    backgroundColor: "#00855e"


  },
  "& .MuiTablehead-root":{
      color: "#005481"

  },

  "& .MuiTableCell-root" :{
    color: "#005481"

  },
  "& .MuiTableCell-head" :{
    color: "#054351"

  }



  
  
});







//Stepper Color changes
export const OAGStepper = styled(Grid, {
  name: "OAGStepper",
  slot: "Wrapper"
})({

  
  '& .MuiStepLabel-root .Mui-completed':{
    color: "#00A676"
  },
  '& .MuiStepLabel-root .Mui-active':{
    color: "#005481"
  }
});





//BOE extra questions
export const OAGBOE = styled(TextField, {
  name: "OAGBOE",
  slot: "Wrapper"
})({
  "&.container-md.bg-blue":{
    backgroundColor: "red"
  
  }
});




//Text color
export const OAGColor = styled(Typography, {
  name: "OAGColor",
  slot: "Wrapper"
})({
  WebkitTextFillColor: "#005481"
    });



    export const OAGButtonGroup = styled(ButtonGroup, {
      name: "OAGButtonGroup",
      slot: "Wrapper"
    
    
    })({

    //back buttons 
    "& #back.MuiButton-root":{
      backgroundColor: "#053451",
      
    },
    "& #back.MuiButton-root:hover":{
      backgroundColor: "#042a41",
      
    },


    //next buttons 
    "& #next.MuiButton-root":{
      backgroundColor: "#005481",
      
    },
    "& #next.MuiButton-root:hover":{
      backgroundColor: "#004367",
      
    },

    //submit button
      "& #submit.MuiButton-root":{
        backgroundColor: "#005481",
        
      },
      "& #submit.MuiButton-root:hover":{
        backgroundColor: "#004367",
        
      },

 //Remove button incident detail page
 "& #Removed.MuiButton-root":{
  width: 250,
  marginLeft: 'auto',
  backgroundColor: "#9B2915"
  
},
"& #Removed.MuiButton-root:hover":{
  backgroundColor: "#7c2111"

},

//Disabled Remove button incident detail page
"& #DisableRemoved.MuiButton-root":{
  width: 250,
  marginLeft: 'auto',
  backgroundColor: "#9e9e9e"
  
},
"& #Removed.MuiButton-root:hover":{
  backgroundColor: "#7c2111"

},


  //Add button incident detail page
  "& #Add.MuiButton-root":{
    width: 250,
    backgroundColor: "#00A676"
  },
  "& #Add.MuiButton-root:hover":{
    backgroundColor: "#00855e"


  }
      
    });
    export const OAGButton = styled(Button, {
      name: "OAGButton",
      slot: "Wrapper"
    
    
    })({
      

      //back buttons 
"& #back.MuiButton-root":{
  backgroundColor: "#053451",
  
},
"& #back.MuiButton-root:hover":{
  backgroundColor: "#042a41",
  
},


  //next buttons 
  "& #next.MuiButton-root":{
    backgroundColor: "#005481",
    
  },
  "& #next.MuiButton-root:hover":{
    backgroundColor: "#004367",
    
  },

//submit button
  "& #submit.MuiButton-root":{
    backgroundColor: "#005481",
    
  },
  "& #submit.MuiButton-root:hover":{
    backgroundColor: "#004367",
    
  },





  //Remove button incident detail page
  "& #Removed.MuiButton-root":{
    width: 250,
    marginLeft: 'auto',
    backgroundColor: "#9B2915"
    
  },
  "& #Removed.MuiButton-root:hover":{
    backgroundColor: "#7c2111"

  },
  
  //Add button incident detail page
  "& #Add.MuiButton-root":{
    width: 250,
    backgroundColor: "#00A676"
  },
  "& #Add.MuiButton-root:hover":{
    backgroundColor: "#00855e"


  }
      
    });
    
    export const OAGTypography = styled(Typography, {
      name: "OAGTypography",
      slot: "Wrapper"
    })({
      color: "#000000",
      marginBottom:"10px"
        });




        export const OAGTextField = styled(TextField, {
          name: "OAGTextField",
          slot: "Wrapper"
        })({
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
        borderColor: "#053451"}},
        
        //focused text color
        '& label.Mui-focused': {
          color: '#007EA8',
        },
        
        //Border color on hover
        "& .MuiOutlinedInput-root:active:hover": {
          "& > fieldset": {
          borderColor: "#053451"}},
        
        //Focused Text color
        "& .MuiInputBase-root":{
          color: "#000000"
        },
        
        //default label text color
        '& label':{
        
          color: "#005481"
        },
        
          //default border color
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#005481',
            }},
        
          //required text color
          "& .MuiFormHelperText-root" :{
            color: "red !important"
          },
            });






            
            export const OAGInputLabel = styled(InputLabel, {
              name: "OAGInputLabel",
              slot: "Wrapper"
            })({
              color: "#007EA8",
            
                });

                export const OAGSelect = styled(Select, {
                  name: "OAGSelect",
                  slot: "Wrapper"
                })({
                  color: "#005481",
                
                    });

   



                    