import React, { Component } from 'react'
//import * as Mui from '@mui/material';
import { form, TextField, select, option } from '@mui/material';
//import * as MuIcons from '@mui/icons-material';

export default class boextraquestions extends Component {
    render() {
        const { values, handleChange } = this.props;

        let ifboecontacted;
        if (values.Oagboecontacted === "Yes") {
            ifboecontacted =

                <div class="tw-grid md:tw-grid-cols-1">
                    <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                        <label for="Oagboecontact" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Who was contacted and what is the status?</label>
                        <textarea
                            id="Oagboecontact"
                            defaultValue={values.Oagboecontact}
                            onChange={handleChange('Oagboecontact')}
                            placeholder="BOE Contact and Status"
                            class=" tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
maxLength={1000}
                        />
                    </div>
                </div>


        } else {
            ifboecontacted = ""
        };

        let ifnotresolved;
        if (values.Oagrslvdissue === "No") {
            ifnotresolved =

                <div class="tw-grid md:tw-grid-cols-1">
                    <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                        <label for="Oagboecontact" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Please provide status/progress with BOE</label>
                        <textarea
                            id="oagrslvdissuedtl"
                            defaultValue={values.oagrslvdissuedtl}
                            onChange={handleChange('oagrslvdissuedtl')}
                            placeholder="Please provide status/progress with BOE"
                            class=" tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                            maxLength={1000}

                        />
                    </div>
                </div>


        } else {
            ifnotresolved = ""
        };

        let iffollowupneeded;
        if (values.Oagfollowup === "Yes") {
            iffollowupneeded =

                <div class="tw-grid md:tw-grid-cols-1">
                    <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                        <label for="oagfollowupdtl" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Please provide status of Contact Efforts</label>
                        <textarea
                            id="oagfollowupdtl"
                            defaultValue={values.oagfollowupdtl}
                            onChange={handleChange('oagfollowupdtl')}
                            placeholder="Please provide status/progress with BOE"
                            class=" tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                            maxLength={1000}

                        />
                    </div>
                </div>


        } else {
            iffollowupneeded = ""
        };

        return (
            <main >
                <form>
                    <div >
                        <div class="tw-grid md:tw-grid-cols-1">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="oagfollowupdtl" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Did the OAG contact the local BOE after receiving the voter’s complaint?</label>
                                <select
                                    id="oagfollowupdtl"
                                    defaultValue={values.Oagboecontacted}
                                    onChange={handleChange('Oagboecontacted')}
                                    class="tw-h-[63px]  tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                >
                                   <option value=""></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Unknown">Unknown</option>
                                </select>
                                {ifboecontacted}
                            </div>
                        </div>
                        <div class="tw-grid md:tw-grid-cols-1">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="Oagfollowup" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Is additional OAG follow up still needed?</label>
                                <select
                                    id="Oagfollowup"
                                    defaultValue={values.Oagfollowup}
                                    onChange={handleChange('Oagfollowup')}
                                    class="tw-h-[63px]  tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                >
                                    <option value=""></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Unknown">Unknown</option>
                                </select>
                                {iffollowupneeded}
                            </div>
                        </div>
                        <div class="tw-grid md:tw-grid-cols-1">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="Oagrslvdissue" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Did the OAG resolve the voter's issue?</label>
                                <select
                                    id="Oagrslvdissue"
                                    defaultValue={values.Oagrslvdissue}
                                    onChange={handleChange('Oagrslvdissue')}
                                    class="tw-h-[63px]  tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                >
                                    <option value=""></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Unknown">Unknown</option>
                                </select>
                                {ifnotresolved}
                            </div>
                        </div>
                        <div class="tw-grid md:tw-grid-cols-1">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="Voternotifiedstatus" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Was the voter updated on the status of their complaint?</label>
                                <select
                                    id="Voternotifiedstatus"
                                    defaultValue={values.Voternotifiedstatus}
                                    onChange={handleChange('Voternotifiedstatus')}
                                    class="tw-h-[63px]  tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
                                >
                                    <option value=""></option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Unknown">Unknown</option>
                                </select>
                                
                            </div>
                        </div>
                        
                       
                    </div>
                </form>
            </main>
        )
    }
}