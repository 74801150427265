import React, { useState, useEffect } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import notAssigned from "../images/icons/eva/status/notAssigned.svg";
import assigned from "../images/icons/eva/status/assigned.svg";
import resolved from "../images/icons/eva/status/resolved.svg";
import defaultCircle from "../images/icons/eva/status/default.svg";


function StatusStoplight(props) {
  const [status, setStatus] = useState(props.status);
  const [circle, setCircleColor] = useState("");

  useEffect(() => {
    //console.log(status);
    switch (status) {

      case "Not Assigned / Action Needed":
      case "BOE Contact Needed":
      case "Need to Contact Voter with BOE Update":
        // code block
        setCircleColor(notAssigned);
        break;

      case "Assigned":
      case "Awaiting Voter Information":
      case "Awaiting BOE Response":
        // code block
        setCircleColor(assigned);
        break;
      case "Resolved":
      case "Refer to District Attorney / Other Agency":
      case "Can be Addressed Post-Election":
        // code block
        setCircleColor(resolved)
        break;

      default:
        // code block
        setCircleColor(defaultCircle)
    }

  }, []);

  return (
    <div class = "tw-flex">
      <div class = "tw-px-1">
        <img height="25px" width="25px" src={circle} />
      </div>
      {status}
    </div>

  )
}

export default StatusStoplight