import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton} from '@mui/material';
import {Delete} from '@mui/icons-material';
import { OAGTextField } from '../helpers/StyledPaper';
import { protectedResources, loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import trash from "../images/icons/eva/outline/trash-outline.svg";
import Button from '../components/Button';
export default function IssueDelete(props) {
  const [open, setOpen] = React.useState(false);
  const [confirm, setConfirm] = React.useState(props.issue);
  const [err, setErr] = React.useState(false);
  const [token, setToken] = React.useState(null);
  
  useEffect(() => {
    async function getToken() {
      const account = msalInstance.getActiveAccount();

      if (!account) {
          console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...loginRequest
    });

      setToken(response.accessToken);
    };
    getToken();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmChange = React.useCallback(e => {        // ***
    setConfirm(e.target.value)                          // ***
  }, [])


  const deleteissue = async () => {
    //e.preventDefault();
    if(confirm ==='CONFIRM') {

      //await getToken();

      const headers = new Headers();
      const bearer = `Bearer ${token}`;

      headers.append("content-type", "application/json");
      headers.append("accept", "*/*");
      headers.append("Authorization", bearer);
      //We go
      const PostData = {
        method: "DELETE",
        headers: headers,
      };

      //console.log(PostData);

      const url = process.env.REACT_APP_API_URL + "IssueType/" + props.issuetypeid;
      
      try{
          let response = await fetch(url, PostData);
          //console.log(response);
      }
      catch (err){
          //console.log(err);
      }

      handleClose();
      window.location.reload(false);
      }
    else {
      //We throw an error on the modal
      setErr(true);
    }

    
}

  return (
    <div>
      <button id="lastPage" className=" tw-pt-1" onClick={handleClickOpen}>
        <img className="  hover:tw-bg-gray tw-w-[40px]  tw-h-[40px] " src={trash} />
      </button>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >
        <h4>
            Delete Issue
          </h4>
          </DialogTitle>
        <DialogContent>
        <h5>
        Please type CONFIRM in the box below to confirm deletion of this Issue/SubIssue combination
        </h5>
        <hr/>
          
        <label for="delete" class={err ? "tw-block tw-text-red tw-text-sm" : "tw-block tw-text-blue tw-text-sm "}>Type CONFIRM to continue</label>
          <input
            type="text"
            id="delete"
            onChange={handleConfirmChange}
            placeholder="Type CONFIRM to continue"
            class={err ? "tw-border-red tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   " : " tw-mb-2 tw-border  tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5   "}

          />
          <label for="delete" class={!err ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Please Type CONFIRM in all caps</label>

          
          
        </DialogContent>
        <DialogActions>
        <Button id="back" onClick={handleClose}>Cancel</Button>
          <Button id="submit" onClick={deleteissue}>Delete</Button>
        </DialogActions>
        
      </Dialog>
    </div>
  );
}