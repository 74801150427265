import React, { Component } from 'react'
import {
  IconButton, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Box, ButtonGroup,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { loginRequest, protectedResources } from "../../../authConfig";
import { msalInstance } from "../../../index";
import Issue from '../../common/formcomponents/issues';
import Edit from "../../../images/icons/eva/outline/edit-outline.svg";
import Save from "../../../images/icons/eva/outline/save-outline.svg";
import Close from "../../../images/icons/eva/outline/close-circle-outline.svg";
import { Add } from '@mui/icons-material';
import Delete from "../../../images/icons/eva/outline/trash-outline.svg";
import Button from '../../../components/Button';

import {
  OAGButtonGroup,
  OAGInputLabel,
  OAGStyle,
  OAGTextField,
  OAGSelect,
  OAGTypography,
} from "../../../helpers/StyledPaper.js";

export default class complaintblock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuesCount: this.props.incdtls.issuedtls.length,
      error: null,
      isLoaded: false,
      disabled: true,
      issueIDs: [],
      issues: [],
      subIssues: [],
      otherIssues: [],
      issue1Valid: true,
      subissue1Valid: true,
      issue2Valid: true,
      subissue2Valid: true,
      issue3Valid: true,
      subissue3Valid: true,
      modalIssueValid: true,
      modalSubIssueValid: true,
      formValid: true,
      open: false
    };

    this.unlock = this.unlock.bind(this)
    this.lock = this.lock.bind(this)
    this.savechanges = this.savechanges.bind(this)
  }

  componentDidMount() {
    var newIssueID = this.state.issueIDs.slice();
    var newIssue = this.state.issues.slice();
    var newSubIssue = this.state.subIssues.slice();
    var countIssues = 0;

    //console.log("in component did  mount")

    this.props.incdtls.issuedtls.map((issue) => {
      //console.log(issue)
      newIssueID.push(issue.issueId);
      newIssue.push(issue.issueType);
      newSubIssue.push(issue.issueSubtype);
      countIssues++;
    })

    //console.log("We mapped the incoming issue array");
    this.setState({ issueIDs: newIssueID });
    this.setState({ issues: newIssue });
    this.setState({ subIssues: newSubIssue });
    this.setState({ issuesCount: countIssues });

    //console.log("We updated the state variables");
  }

  getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...loginRequest
    });

    return response.accessToken;
  }

  unlock() {
    this.setState({ disabled: false });
  };
  lock() {
    this.setState({ disabled: true });
  };

  setSubIssue = (e, index) => {
    var newIssue = this.state.subIssues.slice();

    //Replace the array element if index is not at last element
    if (index + 1 < this.state.subIssues.length)
      newIssue.splice(index, 1, e.target.value);
    //if user try to change the subissue
    else if (this.state.issuesCount === this.state.subIssues.length) {
      newIssue.pop();
      newIssue.push(e.target.value);
    } else newIssue.push(e.target.value);

    this.setState({ subIssues: newIssue });
  };

  setOtherIssue = (e, index) => {
    var newIssue = this.state.otherIssues.slice();

    //Replace the array element if index is not at last element
    if (index + 1 < this.state.otherIssues.length)
      newIssue.splice(index, 1, e.target.value);
    //if user try to change the subissue
    else if (this.state.issuesCount === this.state.otherIssues.length) {
      newIssue.pop();
      newIssue.push(e.target.value);
    } else newIssue.push(e.target.value);

    this.setState({ otherIssues: newIssue });
  };

  setIssue = (e, index) => {
    //console.log(e.target.value, index)
    var newIssue = this.state.issues.slice();

    //Need to
    var newSubIssue = this.state.subIssues.slice();

    //Replace the array element if index is not at last element
    if (index + 1 < this.state.issues.length) {
      newIssue.splice(index, 1, e.target.value);
      newSubIssue.splice(index, 1, "");
    }

    //IF user try to change the issue then store new issue
    else if (this.state.issuesCount === this.state.issues.length) {
      //console.log("comin in here")
      newIssue.pop();
      newSubIssue.pop();
      newIssue.push(e.target.value);
    } else newIssue.push(e.target.value);

    this.setState({ issues: newIssue, subIssues: newSubIssue });
  };

  add = (e) => {
    e.preventDefault();
    var maxnumberofissues = 3;
    //console.log(this.state.issuesCount);
    if (this.state.issuesCount < maxnumberofissues) {
      this.setState({ issuesCount: this.state.issuesCount + 1 });
    }
    this.handleClickOpen();
  }

  addIssue = async () => {
    var validationerrors = false;

    if (this.state.issues[this.state.issuesCount - 1] == null || this.state.issues[this.state.issuesCount - 1] == "") {
      validationerrors = true;
      this.setState({
        modalIssueValid: false,
      });
    } else {
      this.setState({ modalIssueValid: true });
    }
    if (this.state.subIssues[this.state.issuesCount - 1] == null || this.state.subIssues[this.state.issuesCount - 1] == "") {
      validationerrors = true;
      this.setState({
        modalSubIssueValid: false,
      });
    } else {
      this.setState({ modalSubIssueValid: true });
    }

    if (!validationerrors) {
      //console.log("Everything is good");
      this.setState({
        modalIssueValid: true,
        modalSubIssueValid: true,
      });



      const token = await this.getToken();
      const headers = new Headers();
      const bearer = `Bearer ${token}`;

      headers.append("content-type", "application/json");
      headers.append("accept", "*/*");
      headers.append("Authorization", bearer);


      const PostData = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          issueType: this.state.issues[this.state.issues.length - 1],
          issueSubtype: this.state.subIssues[this.state.subIssues.length - 1],
          cmpltId: this.props.incdtls.cmpltID,
        }),
      };

      //console.log(PostData);

      const url = process.env.REACT_APP_API_URL + "Issue/";

      try {
        let response = await fetch(url, PostData);

        //console.log(response);
      }
      catch (err) {
        //console.log(err);
      }

      window.location.reload(false)
    }

  }

  deleteissue = async (issueid) => {
    //e.preventDefault();
    const token = await this.getToken();

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("content-type", "application/json");
    headers.append("accept", "*/*");
    headers.append("Authorization", bearer);

    const options = {
      method: "DELETE",
      headers: headers,
    };

    const deleteurl = process.env.REACT_APP_API_URL + "Issue/" + issueid;
    // console.log("We want to call this:");
    // console.log(deleteurl);

    try {
      let response = await fetch(deleteurl, options);
    } catch (err) {
      //console.log(err);
    }
    window.location.reload(true);
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false, issuesCount: this.state.issuesCount - 1 })
  };

  savechanges = async e => {
    e.preventDefault();

    const token = await this.getToken();
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("content-type", "application/json");
    headers.append("accept", "*/*");
    headers.append("Authorization", bearer);

    let validationerrors = false;

    //Validation below
    switch (this.state.issuesCount) {
      case 1:
        if (
          this.state.issues[0] === "" ||
          this.state.issues[0] == null
        ) {
          //console.log("Issue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            issue1Valid: false,
          });
        } else {
          this.setState({ issue1Valid: true });
        }

        if (
          this.state.subIssues[0] === "" ||
          this.state.subIssues[0] == null
        ) {
          //console.log("SubIssue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            subissue1Valid: false,
          });
        } else {
          this.setState({ subissue1Valid: true });
        }
        break;

      case 2:
        if (
          this.state.issues[0] === "" ||
          this.state.issues[0] == null
        ) {
          //console.log("Issue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            issue1Valid: false,
          });
        } else {
          this.setState({ issue1Valid: true });
        }

        if (
          this.state.subIssues[0] === "" ||
          this.state.subIssues[0] == null
        ) {
          //console.log("SubIssue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            subissue1Valid: false,
          });
        } else {
          this.setState({ subissue1Valid: true });
        }

        if (
          this.state.issues[1] === "" ||
          this.state.issues[1] == null
        ) {
          //console.log("Issue Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            issue2Valid: false,
          });
        } else {
          this.setState({ issue2Valid: true });
        }

        if (
          this.state.subIssues[1] === "" ||
          this.state.subIssues[1] == null
        ) {
          //console.log("SubIssue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            subissue2Valid: false,
          });
        } else {
          this.setState({ subissue2Valid: true });
        }
        break;

      case 3:
        if (
          this.state.issues[0] === "" ||
          this.state.issues[0] == null
        ) {
          //console.log("Issue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            issue1Valid: false,
          });
        } else {
          this.setState({ issue1Valid: true });
        }

        if (
          this.state.subIssues[0] === "" ||
          this.state.subIssues[0] == null
        ) {
          //console.log("SubIssue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            subissue1Valid: false,
          });
        } else {
          this.setState({ subissue1Valid: true });
        }

        if (
          this.state.issues[1] === "" ||
          this.state.issues[1] == null
        ) {
          //console.log("Issue Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            issue2Valid: false,
          });
        } else {
          this.setState({ issue2Valid: true });
        }

        if (
          this.state.subIssues[1] === "" ||
          this.state.subIssues[1] == null
        ) {
          //console.log("SubIssue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            subissue2Valid: false,
          });
        } else {
          this.setState({ subissue2Valid: true });
        }

        if (
          this.state.issues[2] === "" ||
          this.state.issues[2] == null
        ) {
          //console.log("Issue Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            issue3Valid: false,
          });
        } else {
          this.setState({ issue3Valid: true });
        }

        if (
          this.state.subIssues[2] === "" ||
          this.state.subIssues[2] == null
        ) {
          //console.log("SubIssue1 Bad ");
          validationerrors = true;
          this.setState({
            formValid: false,
            subissue3Valid: false,
          });
        } else {
          this.setState({ subissue3Valid: true });
        }
        break;

      default:
      // do nothing
    }

    if (!validationerrors) {
      //console.log("Everything is good");
      this.setState({
        issuesValid: [],
        subIssuesValid: [],
        formValid: true,
      });

      let issuearray = [];
      //console.log(issuearray);
      {
        this.state.issues.map((e, key) => {
          issuearray.push({
            issueType: this.state.issues[key],
            issueSubType: this.state.subIssues[key]
          });
        })
      }


      const complaintPostData = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          ContMethod: this.props.incdtls.contactmethod,
          ContDt: this.props.incdtls.contactdate,
          PollingPlace: this.props.incdtls.pollingplace,
          IncDt: this.props.incdtls.dateofincident,
          Detail: this.props.incdtls.incidentdetails,
          affidavitBallot: this.props.incdtls.affidavitballot,
          interrelationcode: this.props.incdtls.interrelationcode,
          lmUser: this.props.volunteer,
        }),
      };

      this.state.issues.map((issue, key) => {
        const issuePostData = {
          method: "PUT",
          headers: headers,
          body: JSON.stringify({
            IssueType: issue,
            IssueSubtype: this.state.subIssues[key],
            issueOther: "",

          }),
        };
        const issueUrl = process.env.REACT_APP_API_URL + "Issue/" + this.props.incdtls.issuedtls[key].issueId;

        try {
          let issueResponse = fetch(issueUrl, issuePostData);
          //console.log(response);
        }
        catch (err) {
          //console.log(err);
        }
      })

      const complaintUrl = process.env.REACT_APP_API_URL + "Complaint/" + this.props.incdtls.cmpltID;

      try {
        let complaintResponse = await fetch(complaintUrl, complaintPostData);
        //console.log(response);
      }
      catch (err) {
        //console.log(err);
      }

      this.lock();
    }


  }

  formatDate(date) {

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      //day = '' + d.getDate(),
      day = '' + d.getUTCDate(),
      year = d.getFullYear();

    //console.log(d.getDate() + " < result of getDate");
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2) {
      //console.log("Day length is less than 2");
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  render() {
    const { incdtls, handleChange, formatDate } = this.props;

    const { disabled } = this.state;
    var crTime;
    var timelessdateOfBirth = new Date(this.props.incdtls.contactdate).toUTCString();
    const yearfirstDate = formatDate(timelessdateOfBirth);

    var timelessdateOfInc = new Date(this.props.incdtls.dateofincident).toUTCString();
    const yearfirstDateInc = formatDate(timelessdateOfInc);
    if (incdtls.created != null) {
      crTime = new Date(incdtls.created).toLocaleTimeString();
    }
    var issueButton;
    if (this.state.issuesCount == 3) {
      issueButton = <div></div>

    } else {

      issueButton = <Button id="issue" disabled={disabled} className="text-white hover:text-blue-500" onClick={this.add}>
        Add a New Type of Complaint/Issue pair
      </Button>
    }



    const divStyle = {
      backgroundColor: 'lightgray',
      borderRadius: 5 + 'px',
      paddingTop: 2.5 + 'px',
      paddingBottom: 2.5 + 'px'

      //paddingBottom: 8 + 'px'
    };

    const divFormStyle = {
      paddingTop: 8 + 'px'
    };

    let menu, editable;

    if (disabled) {
      editable = disabled;
      menu =
        <button id="ComUnlock" className="text-white hover:text-blue-500" onClick={this.unlock}>
          <img className="tw-block  tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Edit} />
        </button>

    } else {
      editable = "enabled";
      menu =
        <div className="tw-flex tw-space-x-4">
          <button
            id="ComSaveChanges"
            className="  tw-transition tw-rounded-full hover:tw-bg-gray tw-duration-300"
            onClick={this.savechanges}
          >
            <img className="tw-block tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Save} alt="Save Icon" />
          </button>
          <button
            id="ComLock"
            className="tw-text-white    tw-rounded tw-hover:bg-white tw-hover:text-blue-500 tw-transition tw-duration-300"
            onClick={this.lock}
          >
            <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Close} alt="Lock Icon" />
          </button>
        </div>

    }

    let issuearray;
    if (this.state.issuesCount === 1) {
      issuearray = (
        <div class="row">
          <div class="md:tw-grid-cols-1 tw-flex">
            <div>
              <Issue
                id="Issue"
                disable={disabled}
                setIssue={this.setIssue}
                setSubIssue={this.setSubIssue}
                index={0}
                page="Detail"

                value={this.state.issues[0]}
                subvalue={this.state.subIssues[0]}
                issueerrorvar={this.state.issue1Valid}
                subissueerrorvar={this.state.subissue1Valid}
              />
            </div>
            <div class="tw-px-10 tw-py-10">
              <button id="Delete" className="text-white hover:text-blue-500" onClick={() => this.deleteissue(this.state.issueIDs[0])}>
                <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
              </button>

            </div>

          </div>

        </div>
      );
    } else if (this.state.issuesCount === 2) {
      issuearray = (
        <div>
          <div class="row">
            <div class="md:tw-grid-cols-1 tw-flex">
              <Issue
                id="Issue"
                disable={disabled}
                setIssue={this.setIssue}
                setSubIssue={this.setSubIssue}
                index={0}
                page="Detail"
                value={this.state.issues[0]}
                subvalue={this.state.subIssues[0]}
                issueerrorvar={this.state.issue1Valid}
                subissueerrorvar={this.state.subissue1Valid}
              />
              <div class="tw-px-10 tw-py-10">
                <button id="Delete" className="text-white hover:text-blue-500" onClick={() => this.deleteissue(this.state.issueIDs[0])}>
                  <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
                </button>

              </div>
            </div>

          </div>
          <br />
          <div class="row">
            <div class="md:tw-grid-cols-1 tw-flex">
              <Issue
                id="Issue"
                disable={disabled}
                setIssue={this.setIssue}
                index={1}
                page="Detail"

                setSubIssue={this.setSubIssue}
                issueid={this.state.issueIDs[1]}
                value={this.state.issues[1]}
                subvalue={this.state.subIssues[1]}
                issueerrorvar={this.state.issue2Valid}
                subissueerrorvar={this.state.subissue2Valid}
              />
              <div class="tw-px-10 tw-py-10">
                <button id="Delete" className="text-white hover:text-blue-500" onClick={() => this.deleteissue(this.state.issueIDs[1])}>
                  <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
                </button>

              </div>
            </div>

          </div>

        </div>

      );
    } else if (this.state.issuesCount === 3) {
      issuearray = (
        <div>
          <div class="row">
            <div class="md:tw-grid-cols-1 tw-flex">
              <Issue
                id="Issue"
                disable={disabled}
                setIssue={this.setIssue}
                setSubIssue={this.setSubIssue}
                index={0}
                page="Detail"

                value={this.state.issues[0]}
                subvalue={this.state.subIssues[0]}
                issueerrorvar={this.state.issue1Valid}
                subissueerrorvar={this.state.subissue1Valid}
              />
              <div class="tw-px-10 tw-py-10">
                <button id="Delete" className="text-white hover:text-blue-500" onClick={() => this.deleteissue(this.state.issueIDs[0])}>
                  <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
                </button>

              </div>
            </div>

          </div>
          <br />
          <div class="row">
            <div class="md:tw-grid-cols-1 tw-flex ">
              <Issue
                id="Issue"
                disable={disabled}
                setIssue={this.setIssue}
                index={1}
                page="Detail"

                setSubIssue={this.setSubIssue}
                issueid={this.state.issueIDs[1]}
                value={this.state.issues[1]}
                subvalue={this.state.subIssues[1]}
                issueerrorvar={this.state.issue2Valid}
                subissueerrorvar={this.state.subissue2Valid}
              />
              <div class="tw-px-10 tw-py-10">
                <button id="Delete" className="text-white hover:text-blue-500" onClick={() => this.deleteissue(this.state.issueIDs[1])}>
                  <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
                </button>

              </div>
            </div>

          </div>
          <br />
          <div class="row">
            <div class="md:tw-grid-cols-1 tw-flex">
              <Issue
                id="Issue"
                disable={disabled}
                setIssue={this.setIssue}
                index={2}
                page="Detail"

                setSubIssue={this.setSubIssue}
                issueid={this.state.issueIDs[2]}
                value={this.state.issues[2]}
                subvalue={this.state.subIssues[2]}
                issueerrorvar={this.state.issue2Valid}
                subissueerrorvar={this.state.subissue2Valid}
              />
              <div class="tw-px-10 tw-py-10">
                <button id="Delete" className="text-white hover:text-blue-500" onClick={() => this.deleteissue(this.state.issueIDs[2])}>
                  <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
                </button>

              </div>
            </div>

          </div>

        </div>
      );
    }

    return (
      <div class="tw-px-5  tw-shadow-xl tw-rounded-2xl tw-bg-lightest-gray">
        <div class="tw-pt-4">
          <div className="tw-flex tw-items-center tw-justify-between tw-px-4">
            <h3>
              Incident Details
            </h3>
            {menu}
          </div>
        </div>
        {/* <br/> */}
        <div >
          <FormControl fullWidth>
            {/* <TextField
              disabled={disabled}
              id="outlined-disabled"
              label="Contact Method"
              value={incdtls.contactmethod !== null ? incdtls.contactmethod : "Not Available"}
              onChange={handleChange('contactmethod')}
              margin="dense"
            /> */}

            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="contactmethod" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Contact Method</label>
                <select
                  type="text"
                  id="contactmethod"
                  value={incdtls.contactmethod !== null ? incdtls.contactmethod : "Not Available"}
                  onChange={handleChange('contactmethod')}
                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                >
                  <option id="PhoneCall" value="Phone Call">Phone Call</option>
                  <option id="Email" value="Email / Web Form">Email / Web Form</option>
                  <option id="Other" value="Other">Other</option>

                </select>
              </div>
            </div>

            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="contactdate" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Contact Date</label>
                <input
                  type="date"
                  id="contactdate"
                  value={yearfirstDate}
                  onChange={handleChange('contactdate')}
                  class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                />
              </div>
            </div>
            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="ContactTime" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Contact Time</label>
                <input
                  type="text"
                  id="ContactTime"
                  value={incdtls.created !== null ? crTime : "Not Available"}
                  onChange={handleChange('created')}
                  class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                />
              </div>
            </div>
            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="incidentdetails" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Details</label>
                <textarea
                  type="text"
                  id="incidentdetails"
                  value={incdtls.incidentdetails !== null ? incdtls.incidentdetails : "Not Available"}
                  onChange={handleChange('incidentdetails')}
                  class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                  maxLength={4000}
                  rows={2}
                />
              </div>
            </div>
            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="pollingplace" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Polling Place</label>
                <input
                  type="text"
                  id="pollingplace"
                  value={incdtls.pollingplace !== null ? incdtls.pollingplace : "Not Available"}
                  onChange={handleChange('pollingplace')}
                  class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                  maxLength={1000}
                />
              </div>
            </div>
            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="IncidentDate" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Incident Date</label>
                <input
                  type="date"
                  id="IncidentDate"
                  value={yearfirstDateInc}
                  onChange={handleChange('dateofincident')}
                  class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                />
              </div>
            </div>
            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="Affadavit" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Did you cast an Affidavit Ballot?</label>
                <select
                  type="text"
                  id="Affadavit"
                  value={incdtls.affidavitballot !== null ? incdtls.affidavitballot : "Not Available"}
                  onChange={handleChange('affidavitballot')}
                  class={disabled ? "tw-h-[57px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : "tw-h-[57px] tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                >
                  <option value=""></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="N/A">N/A</option>
                  <option value="Unknown">Unknown</option>
                </select>
              </div>
            </div>
            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
              <div class="">
                <label for="InterrelationCode" class={disabled ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "}>Interrelation Code</label>
                <input
                  type="text"
                  id="InterrelationCode"
                  value={incdtls.interrelationcode !== null ? incdtls.interrelationcode : "Not Available"}
                  onChange={handleChange('interrelationcode')}
                  class={disabled ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                  disabled={disabled}
                  maxLength={250}
                />
              </div>
            </div>

            {/*
            <TextField
              disabled={disabled}
              id="outlined-disabled"
              label="Assembly District"
              value={incdtls.assemblydistrict !== null ? incdtls.assemblydistrict : "Not Available"}
              onChange={handleChange('assemblydistrict')}
              margin="dense"
            /> */}

            <div >
              {issuearray}
            </div>

            {issueButton}



            <Dialog open={this.state.open} >

              <DialogTitle sx={{ color: "#054351" }}>Add Issue</DialogTitle>

              <DialogContent>

                <DialogContentText sx={{ color: "#054351" }}>
                  Please enter the Issue and SubIssue below
                </DialogContentText>

                {/* {console.log("Index is " + this.state.issuesCount)} */}
                <Issue
                  id="Issue"
                  setIssue={this.setIssue}
                  setSubIssue={this.setSubIssue}
                  index={this.state.issuesCount - 1}
                  page="Detail"

                  value={this.state.issues[this.state.issuesCount - 1]}
                  subvalue={this.state.subIssues[this.state.issuesCount - 1]}
                  issueerrorvar={this.state.modalIssueValid}
                  subissueerrorvar={this.state.modalSubIssueValid}
                />

              </DialogContent>

              <DialogActions>

                <Button id="back" onClick={this.handleClose}>Cancel</Button>
                <Button id="submit" onClick={this.addIssue}>Submit</Button>

              </DialogActions>
            </Dialog>
            <br></br>




          </FormControl>
        </div>
      </div>
    )
  }
}
