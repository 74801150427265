import React, { Fragment } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Button } from "@mui/material";
import Check from "../../../images/icons/eva/fill/checkmark-circle-success.svg"

const SubmissionStatus = (props) => {
  //const status = props.submissionStatusCode === 200 ? "Success" : "Failed";
  const status = props.submissionStatusCode === 200 && props.confirmationCode >= 1 ? "Success" : "Failed";

  return (
    <>
      {status === "Success" ? (
        <div class="tw-px-5 tw-py-5" >
          <div class="tw-rounded-xl tw-bg-light-gray">
            <div align="center">
              <img height="200px" width="200px" src={Check} />
              <h2>
                We have successfully received your complaint. Here is your confirmation code:{" "}
              </h2>
            </div>
            <h3 style={{ textAlign: "center", fontSize: "30px" }}>Confirmation Code: {props.confirmationCode}</h3>
          </div>
        </div>
      ) : (
        <>
          <div class="tw-px-5 tw-py-5" >
            <div class="tw-rounded-xl tw-bg-light-gray">
              <div align="center">
                <img height="200px" width="200px" src={Check} />
                <h2>
                  An error occured while submitting your complaint. Please try again.{" "}
                </h2>
              </div>
              <h3 style={{ textAlign: "center", fontSize: "30px" }}>Error Code: {props.confirmationCode}</h3>
              <button
                className="tw-ml-auto tw-button tw-inline-flex tw-flex-row tw-button-md tw-button--primary"
                href="/"
              >
                Try Again
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SubmissionStatus;
