import React from "react"
import agencyLogo from "../images/oag_seal_white.svg";
import Facebook from "../images/icons/social/facebook.svg";
import Twitter from "../images/icons/social/twitter.svg";
import Instagram from "../images/icons/social/instagram.svg";
//import "../components/oag.css";
import { FaceOutlined } from "@mui/icons-material";


const Footer = () => {
    return (

        <div class = "tw-grow">
            <footer role="contentinfo" class="tw-mt-10 ">


                <div class="tw-bg-blue tw-py-5">
                    <div class="tw-container">
                        <div class="tw-flex tw-flex-col -tw-m-4 lg:tw-flex-row lg:tw-justify-between lg:tw-items-stretch">
                            <div class="tw-flex-initial tw-m-4">
                                <img class="tw-mb-4 tw-max-w-[100px] lg:tw-mx-auto" src={agencyLogo} alt="Office of the Attorney General Seal" />

                                <div id="social-menu">


                                    <nav class="social-menu tw-relative">
                                        <ul>
                                            <li><a href="https://www.facebook.com/newyorkstateag" title="Facebook"><img src={Facebook} alt="Facebook" /></a></li>
                                            <li><a href="https://www.instagram.com/newyorkstateag/" title="Instagram"><img src={Instagram} alt="Instagram" /></a></li>
                                            <li><a href="https://twitter.com/newyorkstateag" title="Twitter"><img src={Twitter} alt="Twitter" /></a></li>
                                        </ul>
                                    </nav>
                                    

                                </div>
                            </div>

                            <div id="contact-info" class="tw-m-4">
                                <div class="tw-text-base tw-text-white">
                                    <div class="tw-font-black tw-text-sm">Contact
                                    </div>
                                    <address class="tw-text-sm tw-not-italic">
                                        Office of the New York State Attorney General<br />
                                        The Capitol<br />
                                        Albany NY 12224-0341<br />
                                        Phone: <a href="tel:1-800-771-7755">1-800-771-7755</a>
                                    </address>
                                </div>
                            </div>

                            <div id="footer-menu" class="tw-m-4">

                                <nav class="footer-menu tw-relative">









                                    <ul class="menu " tabIndex="-1">
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/about/about-office/regional-offices" class="menu-link menu-link--link tw-text-sm" data-ariacontrols="statewide-offices-submenu-1">Statewide Offices</a>
                                        </li>
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/press-releases" class="menu-link menu-link--link tw-text-sm" data-ariacontrols="press-releases-submenu-2">Press Releases</a>
                                        </li>
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/file-complaint" class="menu-link menu-link--link tw-text-sm" data-ariacontrols="find-a-complaint-form-submenu-3">Find a Complaint Form</a>
                                        </li>
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/job-postings" class="menu-link menu-link--link tw-text-sm" data-ariacontrols="employment-opportunities-submenu-4">Employment Opportunities</a>
                                        </li>
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/election-hotline-guide" class=" menu-link menu-link--link tw-text-sm">User Guide</a>
                                        </li>
                                    </ul>


                                </nav>

                            </div>

                        </div>
                    </div>
                </div>

                <div id="bottom-bar" class="tw-bg-light-gray">
                    <div class="tw-container">
                        <div class="tw-py-4 tw-flex tw-flex-col -tw-m-1 lg:tw-flex-row">
                            <div class="tw-flex-1 tw-text-dark tw-leading-none tw-text-xs tw-m-1">Copyright &copy;
                                2023
                                &mdash; Office of the New York State Attorney General. All Rights Reserved.
                            </div>

                            <div id="policy-menu" class="tw-flex-initial tw-m-1">

                                <nav class="policy-menu tw-relative">
                                    <ul class="menu " tabIndex="-1">
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/privacy-policy" class="menu-link menu-link--link" data-ariacontrols="privacy-policy-submenu-1">Privacy Policy</a>
                                        </li>
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/disclaimer" class="menu-link menu-link--link" data-ariacontrols="disclaimer-submenu-2">Disclaimer</a>
                                        </li>
                                        <li class="menu-item menu-item--link ">
                                            <a href="https://ag.ny.gov/accessibility-policy" class="menu-link menu-link--link" data-ariacontrols="accessibility-policy-submenu-3">Accessibility Policy</a>
                                        </li>
                                    </ul>


                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
