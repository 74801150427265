import React, { Component, useEffect, useState } from 'react'
//import * as Mui from '@mui/material';

import EditModal from '../../modals/issuetypeeditmodal';
import DeleteModal from '../../modals/issuetypedeletemodal';
import AddModal from '../../modals/issuetypeaddmodal';
import { OAGStyle, OAGColor } from '../../helpers/StyledPaper.js';
import AppPagination from '../../helpers/Pagination';
import { Box, TablePagination, Stack } from "@mui/material";
import IssueUp from '../../helpers/issuemoveup';
import IssueDown from '../../helpers/issuemovedown';
import CustomPagination from '../../components/customPagination';
import { DataGrid } from '@mui/x-data-grid';

//import TableCont from '../../helpers/tablecontainer';

import { loginRequest, protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";

export class IssueList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      disabled: true,
      open: false,
      issues: [],
      allissues: [],
      test: '',
      pageNumber: 0,
      rowsPerPage: 10,
      totalIssues: 0,
      pageSize: 25,  // Number of items per page
		
		page: 0,
    };

    this.unlock = this.unlock.bind(this)
    this.lock = this.lock.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.setPageNumber = this.setPageNumber.bind(this)
    this.setRowsPerPage = this.setRowsPerPage.bind(this)
  }

  //Only called when the page is first loaded
  async componentDidMount() {
    //this.getIssues();
    this.getAllIssues();

  }

  //Only called when the page is first loaded
  async getAllIssues() {
    const accessToken = await this.getToken();
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    const url = process.env.REACT_APP_API_URL + `IssueType/AllIssues`;

    fetch(url, options)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            allissues: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }


  //Sets the pageNumber and rowsPerPage when Event happens in Pagination
  async getIssues(pageNum, rowsPer) {
    const accessToken = await this.getToken();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    var url = "";
    if (pageNum >= 0) {
      url = process.env.REACT_APP_API_URL + `IssueType?pageSize=${rowsPer}&pageIndex=${pageNum}`;
    } else {
      url = process.env.REACT_APP_API_URL + `IssueType?pageSize=${this.state.rowsPerPage}&pageIndex=${this.state.pageNumber}`;
    }

    fetch(url, options)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            issues: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

    const url1 = process.env.REACT_APP_API_URL + 'IssueType/IssueCount';

    fetch(url1, options)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            totalIssues: parseInt(result)
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  } ///end

  getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...loginRequest
      //scopes: [ "api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user" ]
    });
    return response.accessToken;
  }

  //Set the index
  setPageNumber = (pageNum) => {
    this.setState({ pageNumber: pageNum });
    this.getIssues(pageNum, this.state.rowsPerPage);
  };
  handlePageSizeChange = (newPageSize) => {
		this.setState({ pageSize: newPageSize });
	};
  //Set the amount of rows per page and reset the index to 0
  setRowsPerPage = (rowsPer) => {
    this.setState({ rowsPerPage: rowsPer });

    if (this.state.pageNumber > 0) {
      this.state.pageNumber = 0

    }
    this.getIssues(this.state.pageNumber, rowsPer);
  };

  unlock() {
    this.setState({ disabled: false });
  };

  lock() {
    this.setState({ disabled: true });
  };

  open() {
    this.setState({ open: true });
  };

  close() {
    this.setState({ open: false });
  };

  // handle field change
  handleChange = input => (event) => {
    this.setState({ [input]: event.target.value });
  }
  handlePageChange = (newPage) => {
		this.setState({ page: newPage }); // Update the 'page' state with the new page number
	};

  render() {
    const { error, isLoaded, issues, allissues, page, pageSize } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <AddModal />
          {/* <h2>NEW CODE BELOW</h2> */}
          <hr />
          <div className="tw-bg-dot-pattern">
            <div class="tw-px-5">
              <div className=" tw-shadow-xl tw-overflow-x-auto">

                <Box sx={{ height: 800, width: '100%', backgroundColor: "white" }}>
                  <DataGrid
                    page={page}
                    onPageChange={(newPage) => this.handlePageChange(newPage)}
                    rows={allissues}
                    pageSize={pageSize}
                    columns={columns}
                    onPageSizeChange={(newPageSize) => this.handlePageSizeChange(newPageSize)} // Handle page size change

                    getRowId={(row) => row.issueLuId}
                    rowsPerPageOptions={[25, 50, 100]} // You can customize the available page sizes

                    disableSelectionOnClick
                  />
                </Box>
              </div>
            </div>
          </div>



        </div>
      )
    }
  }
}
export default IssueList;


const columns = [
  { field: 'issueLuId', headerName: 'ID', width: 90 },
  {
    field: 'reorder',
    headerName: 'Reorder',
    width: 110,
    sortable: false,
    disableClickEventBubbling: false,

    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={0}>
          <IssueUp issueid={params.row.issueLuId} />
          <IssueDown issueid={params.row.issueLuId} />
        </Stack>
      );
    },
  },

  {
    field: 'dispOrder',
    headerName: 'Display Order',
    width: 200,
    editable: true,
  },
  {
    field: 'issueType',
    headerName: 'Type of Complaint',
    // type: 'number',
    width: 420,
    editable: true,
  },
  {
    field: 'issueSubtype',
    headerName: 'Issue',
    width: 500,
    editable: true,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    sortable: false,
    disableClickEventBubbling: true,

    renderCell: (params) => {

      return (
        <Stack direction="row" spacing={2}>
          <EditModal issuetypeid={params.row.issueLuId} issue={params.row.issueType} subissue={params.row.issueSubtype} />
          <DeleteModal issuetypeid={params.row.issueLuId} />
        </Stack>
      );
    },
  }

]