import './App.css';
import {  BrowserRouter as Router, Routes, Route, useOutlet } from "react-router-dom";

import {  InteractionRequiredAuthError,  InteractionStatus} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import Layout from "./pages/Layout";

import ComplaintExt from './pages/public/ComplaintEXT';
import ComplaintInt from './pages/admin/ComplaintINT';
import SearchForm from "./pages/admin/SearchForm";
import DetailView from './pages/admin/DetailView';
import IssueView from './pages/admin/IssueList';
import IssueList from './pages/admin/IssueList';
import ElectionView from './pages/admin/ElectionList';
import MOTDView from './pages/admin/MOTD';
import Logout from './helpers/Logout';
import NoPage from "./pages/NoPage";
import React from 'react';
// import { apiRequest } from './authConfig';


function App() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts/*, instance, inProgress*/ } = useMsal();

  let nav;
  if(isAuthenticated){
    const userfullname = accounts[0].idTokenClaims.preferred_username;
    const name = accounts[0].idTokenClaims.name;
    nav = <>
            <Route index element={<ComplaintExt userfullname={name} useremail={userfullname} />} />
            <Route path="admin" element={<ComplaintInt userfullname={userfullname} />} />
            <Route path="search" element={<SearchForm />} />
            <Route path="issuemgmt" element={<IssueView userfullname={userfullname} />} />
            {/* <Route path="issuelist" element={<IssueList />} />  */}
            {/* <Route path="issuemgmt" element={<ElectionView userfullname={userfullname} />} /> */}
            <Route path="electionmgmt" element={<ElectionView userfullname={userfullname} />} />
            <Route path="motdpage" element={<MOTDView userfullname={userfullname} />} />
            <Route path="detailview" element={<DetailView userfullname={userfullname} />} />
            <Route path="logout" element={<Logout/>} />
          </>
  } else {
      nav = <>
              <Route index element={<ComplaintExt />} />
            </>
  }

  return (
    <Router>
      <div class="layout-container tw-flex tw-flex-col tw-min-h-screen">
      {/* <div> */}
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* <Route index element={<ComplaintExt />} /> */}
              {nav}
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
      </div>
    </Router>
  );
}

export default App;
