import React, { useState, useEffect } from 'react';
import { loginRequest, protectedResources } from "../authConfig";
import { msalInstance } from "../index";
import "../App.css";

export default function MOTDBanner() {
    const [messageString, setMessageString] = useState('');
    const [link, setLink] = useState('');
    const [link_text, setLinkText] = useState('');
    const [token, setToken] = useState('');

    const getToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            ...loginRequest
        });

        return response.accessToken;
    }

    const getMOTD = () => {
        const url = process.env.REACT_APP_API_URL + "MOTD";
        // console.log("Getting Token")
       //const token = getToken();
       // console.log("token: " + token);
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                   // console.log("Hellllo: " + result);

                    setMessageString(result.adminAlert);
                    setLink(result.link);
                    setLinkText(result.linkText);
                    // this.setState({
                    //     isLoaded: true,
                    //     messageString: result.adminAlert,
                    //     link: result.link,
                    //     link_text: result.linkText
                    // });
                   // console.log("Hellllo: " + messageString);
                },
                (error) => {
                    // this.setState({
                    //     isLoaded: true,
                    //     error
                    // });
                }

            )
    }

    useEffect(() => {
        const getToken = async () => {
            const account = msalInstance.getActiveAccount();

            if (!account) {
                console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const response = await msalInstance.acquireTokenSilent({
                account: account,
                ...loginRequest
            });

            setToken(response.accessToken);
        }

        if (!token) {
            getToken();
        }

        getMOTD();

    })
    if (messageString === null) {
        return (
            <div></div>

        )
    } else {
        return (
            <div  class="tw-notice tw-rounded-lg tw-border-2 tw-w-full tw-font-bold tw-text-base tw-bg-white tw-border-gold">
                <h5 class="tw-text-center tw-pb-[2px] tw-font-bold lg:tw-text-lg  tw-bg-gold tw-text-dark">Message of the Day</h5>
                <div class="tw-p-3 ">
                    <ul class="tw-list-none xs:tw-text-sm sm:tw-text-sm lg:tw-text-base">
                        <li>{messageString === null ? "" : "Message of the Day: " + messageString}</li>
                        <li><a href={link}>{link_text}</a></li>
                    </ul>
                </div>
            </div>
            )
    }
}
