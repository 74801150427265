import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import { Audio, BallTriangle, Bars, Blocks, Circles, CirclesWithBar, ColorRing, Comment, Discuss, Dna, FallingLines, FidgetSpinner, Grid, Hearts, Hourglass, InfinitySpin, LineWave, MagnifyingGlass, MutatingDots, 
    Oval, ProgressBar, Puff, Radio, RevolvingDot, Rings, RotatingLines, RotatingSquare, RotatingTriangles, TailSpin, ThreeCircles, ThreeDots, Triangle, Vortex, Watch  } from 'react-loader-spinner'
import {
    FormControl, TextField, Box, CircularProgress, AccordionSummary, AccordionDetails,
    Typography, IconButton, ButtonGroup
} from '@mui/material';


import { ArrowBack, ArrowForward, Edit, ExpandMore } from '@mui/icons-material';

import { OAGStyle, OAGTypography } from '../../../helpers/StyledPaper.js';
import Recaptcha, { ReCAPTCHA } from "react-google-recaptcha"
import axios from "axios";
import { protectedResources } from "../../../authConfig.js";
import { msalInstance } from "../../../index";
import Button from "../../../components/Button.js";
import Accordion from '../../../components/Accordion.js';

export class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            count: 0,
            electionyearid: '',
            status: 'Not Assigned / Action Needed',
            assignedto: '',
            currentelection: [],
            issuearray: [],
            expanded: 'panel1',
            recaptchaValid: false,
            recaptchatoken: '',
            loadingspinner: false,
        };
    }
    componentDidMount() {
        const url = process.env.REACT_APP_API_URL + "ElectionYear/Current";
        const headers = new Headers();
        const authkey = 'B3BAAAEFB74D55636B43E843824A2';

        if (this.props.admin) {
            this.setState({ status: "Assigned" });
            this.setState({ assignedto: this.props.values.volunteer });
        }


        headers.append("Authorization", authkey);

        const options = {
            method: "GET",
            headers: headers,
        };

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        currentelection: result,
                        electionyearid: result.electionYearId,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    handleChange = (panel) => (event, newExpanded) => {
        if (newExpanded) {
            this.setState({ expanded: panel });
        } else {
            this.setState({ expanded: '' });
        }
    };

    captchaChange = (value) => {
        //console.log('Captcha value:', value);
        if (value == null) {
            this.setState({ recaptchaValid: false });
        } else {
            this.setState({
                recaptchaValid: true,
                recaptchatoken: value
            });
        }

    }

    editPage = (e) => {
        //console.log(e.target.id)
        if (e.target.id == "EditVot") {
            this.props.setStep(1);
        } else if (e.target.id == "EditInc") {
            this.props.setStep(2);
        }
        else {
            this.props.setStep(3);
        }
    }



    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
        window.scrollTo(0, 0);
    };




    //************************************ */

    docSubmit = async (id) => {
        //e.preventDefault();

        if (this.state.recaptchaValid) {

            const postData = new FormData();
            postData.append("CmpltId", id);

            //console.log("Post Data is", postData);

            if (this.props.values.firstAttachment.length == "0" && this.props.values.secondAttachment.length == "0") {
                this.props.nextStep();
            }
            else {
                if (this.props.values.firstAttachment.length == "0") {
                    postData.append("attachment1", null);
                } else {
                    postData.append("attachment1", this.props.values.firstAttachment);
                    postData.append("att1filename", this.props.values.firstAttachment.name);
                    postData.append("att1extension", this.props.values.firstAttachmentExtension);
                }

                if (this.props.values.secondAttachment.length == "0") {
                    postData.append("attachment2", null);
                } else {
                    postData.append("attachment2", this.props.values.secondAttachment);
                    postData.append("att2filename", this.props.values.secondAttachmentName);
                    postData.append("att2extension", this.props.values.secondAttachmentExtension);
                }

                const url = process.env.REACT_APP_API_URL + "Documents"


                await axios({
                    method: "POST",
                    url: url,
                    data: postData,
                    headers: { "Content-Type": "multipart/form-data" },

                })
                    .then((response) => {

                        //console.log("very good");
                        //this.props.setSubmissionCodes(response.status, response.data);

                    })
                    .catch((error) => {
                        //this.props.setSubmissionCodes(error.response.status, error.response.data);

                    })

                this.props.nextStep();

            }
        } else {
            //Bad bot, no donut
            //console.log("smells like a bot in here")
        }

    }


    //************************************ */

    onSubmit = async e => {
        e.preventDefault();

        if (this.state.recaptchaValid) {
            this.setState({ loadingspinner: true });
            //No Bot
            let issuearray = [];
           
            {
                this.props.values.issues.map((e, key) => {
                    if(this.props.values.issues[key] != ""){
                    issuearray.push({
                        issueType: this.props.values.issues[key],
                        issueSubType: this.props.values.subIssues[key]
                    });
                }
                })
            }
        
            //console.log(this.props.values.dob);
            const PostData = {
                method: "POST",
                headers: { "content-type": "application/json", "accept": "*/*" },
                body: JSON.stringify({
                    lastname: this.props.values.lastname,
                    firstname: this.props.values.firstname,
                    phoneNumber: this.props.values.phonenumber,
                    status: this.state.status,
                    contMethod: this.props.values.contactmethod,
                    contDt: this.props.values.contactdate,
                    detail: this.props.values.incidentdetails,
                    emailAddr: this.props.values.email,
                    address: this.props.values.address,
                    address2: this.props.values.address2,
                    city: this.props.values.city,
                    state: this.props.values.state,
                    zipCode: this.props.values.zip,
                    county: this.props.values.county,
                    dateOfBirth: this.props.values.dob,
                    electionYearId: this.state.electionyearid,
                    pollingPlace: this.props.values.pollingplace,
                    pollingPlaceAddr: this.props.values.pollingaddr,
                    assignedTo: this.props.values.volunteer,
                    crUser: this.props.values.volunteer,
                    incDt: this.props.values.dateofincident,
                    voterElecDist: this.props.values.electiondistrict,
                    voterAssemDist: this.props.values.assemblydistrict,
                    affidavitBallot: this.props.values.affidavitcast,
                    interrelationCode: this.props.values.interrelationcode,
                    //BOE Questions
                    pollworker: this.props.values.pollworker,
                    boeContacted: this.props.values.boecontacted,
                    boeContact: this.props.values.boecontact,
                    issueRslvd: this.props.values.issuerslved,
                    indepescalation: this.props.values.independentescalation,
                    votercontactlocalboe: this.props.values.votercontactlocalboe,


                    //BOE Extra Questions
                    oagboecontacted: this.props.values.Oagboecontacted,
                    oagboecontact: this.props.values.Oagboecontact,
                    oagfollowup: this.props.values.Oagfollowup,
                    oagfollowupdtl: this.props.values.oagfollowupdtl,
                    oagrslvdissue: this.props.values.Oagrslvdissue,
                    oagrslvdissuedtl: this.props.values.oagrslvdissuedtl,
                    voternotifiedstatus: this.props.values.Voternotifiedstatus,

                    issueDtls: issuearray,
                    interrelationCode: this.props.values.interrelationcode,

                    //Recaptcha token
                    recapToken: this.state.recaptchatoken,
                }),
            };

            //console.log(PostData);

            const url = process.env.REACT_APP_API_URL + "Forms/PublicForm";
            try {
                let response = await fetch(url, PostData);
                const data = await response.json();
                //Update State Variable to set HTTP status and Complaint ID (data)
                this.props.setSubmissionCodes(response.status, data);
                //Pass the Complaint ID to the docSubmit function which will save our files
                this.docSubmit(data);

                //this.props.nextStep();
            }
            catch (err) {
                //console.log(err);
            }

        } else {
            //Bad bot, no donut
            //console.log("smells like a bot in here")
        }



    }

    render() {
        const { values, steps, handleSubmit, setStep } = this.props;
        const { error, isLoaded, currentelection, electionyearid, issuearray } = this.state;
        let adminfields;
        if (this.props.admin) {

            adminfields =
                <form>
                    <div class="tw-grid tw-gap-6 md:tw-grid-cols-3">

                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="contact-method-select" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" >Contact Method</label>
                            <select
                                disabled
                                id="contact-method-select"
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                defaultValue={values.contactmethod}

                            >
                                <option value="Phone Call">Phone Call</option>
                                <option value="Email / Web Form">Email / Web Form</option>
                                <option value="Other">Other</option>
                            </select>

                        </div>
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="contactdate" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Contact Date</label>
                            <input
                                disabled
                                type="date"
                                id="contactdate"
                                defaultValue={values.contactdate}

                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="interrelationcode" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Interrelation Code</label>
                            <input
                                disabled
                                type="text"
                                id="interrelationcode"
                                placeholder="Enter a Interrelation Code if applicable"
                                defaultValue={values.interrelationcode}

                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                </form>;
        } else {
            adminfields = '';
        };

        //If user is logged in, show the extra fields they likely entered
        let adminboefields;
        if (this.props.admin) {
            adminboefields =
                <form >
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="Oagboecontacted" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did the PollWorker escalate to Local BOE?</label>
                            <input
                                type="text"
                                disabled
                                id="Oagboecontacted"
                                defaultValue={values.Oagboecontacted}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="Oagboecontact" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Who was contacted and what is the status?</label>
                            <input
                                type="text"
                                disabled
                                id="Oagboecontact"
                                defaultValue={values.Oagboecontact}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="Oagfollowup" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Is additional OAG follow up still needed?</label>
                            <input
                                type="text"
                                disabled
                                id="Oagfollowup"
                                defaultValue={values.Oagfollowup}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="oagfollowupdtl" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Please provide status of Contact Efforts</label>
                            <input
                                type="text"
                                disabled
                                id="oagfollowupdtl"
                                defaultValue={values.oagfollowupdtl}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="Oagrslvdissue" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did the OAG resolve the voter's issue?</label>
                            <input
                                type="text"
                                disabled
                                id="Oagrslvdissue"
                                defaultValue={values.Oagrslvdissue}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="oagrslvdissuedtl" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Please provide status/progress with BOE</label>
                            <input
                                type="text"
                                disabled
                                id="oagrslvdissuedtl"
                                defaultValue={values.oagrslvdissuedtl}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>
                    <div class="tw-grid md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="Voternotifiedstatus" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Was the voter updated on the status of their complaint?</label>
                            <input
                                type="text"
                                disabled
                                id="Voternotifiedstatus"
                                defaultValue={values.Voternotifiedstatus}
                                class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />
                        </div>
                    </div>

                </form>;
        } else {
            adminboefields = '';
        };
if(values.issues[1]){
var Issue2 =
    <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="issue2" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Issue 2</label>
                                    <input type="text"
                                        id="issue2"
                                        value={values.issues[1]}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="subIssue2" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Sub Issue 2</label>
                                    <input
                                        type="text"
                                        id="subIssue2"
                                        value={values.subIssues[1]}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />

                                </div>
                            </div>
}
if(values.issues[2]){
    var Issue3 =
        <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                    <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                        <label for="issue3" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Issue 3</label>
                                        <input type="text"
                                            id="issue3"
                                            value={values.issues[2]}
                                            class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                        />
                                    </div>
                                    <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                        <label for="subIssue3" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Sub Issue 3</label>
                                        <input
                                            type="text"
                                            id="subIssue3"
                                            value={values.subIssues[2]}
                                            class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                        />
    
                                    </div>
                                </div>
    }
        const accordionItems = [
            {
                title: 'Voter Details',
                content:
                    <main>
                        <div class="tw-px-5 tw-rounded-xl tw-bg-lightest-gray">
                            <hr />

                            {/* First Name and Last Name */}
                            <div align="right">
                                <Button id="EditVot" onClick={this.editPage}>Edit</Button>
                            </div>
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="firstName" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">First Name</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        value={values.firstname}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />

                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="last_name" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Last Name</label>
                                    <input type="text"
                                        id="lastName"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.lastname}

                                        disabled />
                                </div>
                            </div>

                            {/* Business Name Organization Name */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-3">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="phone" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Phone Number</label>
                                    <input type="text"
                                        id="phone"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.phonenumber}
                                        disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="email" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Email</label>
                                    <input type="text"
                                        id="email"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                        value={values.email}
                                        disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="dob" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Date of Birth</label>
                                    <input type="date"
                                        id="dob"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                        value={values.dob}
                                        disbaled
                                    />
                                </div>
                            </div>
                            {/* Address Block - Street Info */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="address" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Address</label>
                                    <input type="text"
                                        id="address"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.address}
                                        disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="address2" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Address Line 2</label>
                                    <input type="text"
                                        id="address2"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.address2}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* Address Block - Region Info */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-4">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="city" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">City</label>
                                    <input type="text"
                                        id="city"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.city}
                                        disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="state" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">State</label>
                                    <input
                                        type="text"
                                        id="state"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.state}
                                        disabled
                                    />



                                </div>


                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="zip" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Zip/Postal Code</label>
                                    <input type="text"
                                        id="zip"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.zip}
                                        disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="county" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">County</label>
                                    <input
                                        type="text"
                                        id="county"
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " value={values.county}
                                        disabled
                                    />


                                </div>
                            </div>



                            <hr />

                        </div>
                    </main>
            },
            {
                title: 'Incident Details',
                content:
                    <main >
                        <div class="tw-px-5 tw-rounded-xl tw-bg-lightest-gray">
                            <hr />
                            {/* First Name and Last Name */}
                            <div align="right">
                                <Button id="EditInc" onClick={this.editPage}>Edit</Button>
                            </div>
                            {adminfields}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="pollingplace" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Polling Place</label>
                                    <input
                                        type="text"
                                        id="pollingplace"
                                        value={values.pollingplace}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />

                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="pollingaddr" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Polling Place Address or Address Where Incident Occurred</label>
                                    <input type="text"
                                        id="pollingaddr"
                                        value={values.pollingaddr}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />
                                </div>
                            </div>
                            {/* Address Block - Street Info */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="dateofincident" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Date Of Incident</label>
                                    <input type="date"
                                        id="dateofincident"
                                        value={values.dateofincident}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="affidavitcast" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did you cast an affidavit ballot?</label>
                                    <input
                                        type="text"
                                        id="affidavitcast"
                                        value={values.affidavitcast}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />

                                </div>
                            </div>

                            {/* Business Name Organization Name */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="incidentdetails" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">What Happened?</label>
                                    <textarea type="text"
                                        id="incidentdetails"
                                        value={values.incidentdetails}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                        rows="3"

                                    />
                                </div>
                            </div>
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="issue1" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Issue 1</label>
                                    <input type="text"
                                        id="issue1"
                                        value={values.issues[0]}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="subIssue1" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Sub Issue 1</label>
                                    <input
                                        type="text"
                                        id="subIssue1"
                                        value={values.subIssues[0]}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />

                                </div>
                            </div>
                            {Issue2}
                            {Issue3}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="firstAttachment" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">First Attachment</label>
                                    <input type="text"
                                        id="firstAttachment"
                                        value={values.firstAttachment.name}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />
                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="secondAttachment" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Second Attachment</label>
                                    <input
                                        type="text"
                                        id="secondAttachment"
                                        value={values.secondAttachment.name}
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " disabled
                                    />

                                </div>
                            </div>
                        </div>
                    </main>
            },
            // Add more items as needed
            {
                title: 'BOE Questions',
                content:
                    <main class="tw-px-5 tw-py-5">
                        <div class="tw-px-5 tw-rounded-xl tw-bg-lightest-gray">
                            <hr />
                            {/* First Name and Last Name */}
                            <div align="right">
                                <Button id="EditBOE" onClick={this.editPage}>Edit</Button>
                            </div>

                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="boecontacted" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did a poll worker solve your problem or contact the local Board of Elections about it?  </label>
                                    <input

                                        id="boecontacted"
                                        type="text"
                                        value={values.boecontacted}
                                        disabled
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />


                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="pollworker" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did a poll worker tell you what you should do next to solve your problem or follow up? </label>
                                    <input

                                        id="pollworker"
                                        type="text"
                                        value={values.pollworker}
                                        disabled

                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                    />


                                </div>
                            </div>
                            {/* Address Block - Street Info */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="independentescalation" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did you know how to contact the Board of Elections about your problem?  </label>
                                    <input

                                        id="independentescalation"
                                        type="text"
                                        value={values.independentescalation}
                                        disabled

                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                    />


                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="issuerslved" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did your local Board of Elections solve your problem?  </label>
                                    <input
                                        type="text"
                                        id="issuerslved"
                                        defaultValue={values.issuerslved}
                                        disabled
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                    />


                                </div>


                            </div>

                            {/* Business Name Organization Name */}
                            <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="votercontactlocalboe" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">Did you contact the local Board of Elections office for help?  </label>
                                    <input
                                        type="text"
                                        id="votercontactlocalboe"
                                        value={values.votercontactlocalboe}
                                        disabled

                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                    />


                                </div>
                                <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                    <label for="votercontactlocalboe" class="tw-block  tw-text-sm tw-font-medium tw-text-dark-gray">If yes, provide details</label>
                                    <input
                                        type="text"
                                        id="boecontact"
                                        value={values.boecontact}
                                        disabled
                                        class="tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
                                    />

                                </div>
                            </div>





                            {adminboefields}
                        </div>
                    </main>
            }];

        //If user is logged in, show the extra fields they likely entered

        //Let's only display enough text boxes to cover the amount of Issues that were input.
        let issuefields;
        if (values.issues[1] != '' && values.issues[1] != null) {
            //Issue2 has values
            if (values.issues[2] != '' && values.issues[2] != null) {
                //Issue3 has values too
                issuefields = <form >
                    <input
                        type="text"
                        disabled
                        id="issue2"
                        label="Issue 2"
                        value={values.issues[1]}
                        margin="dense"
                    />
                    <input
                        type="text"
                        disabled
                        id="subissue2"
                        label="SubIssue 2"
                        value={values.subIssues[1]}
                        margin="dense"
                    />
                    <input
                        type="text"
                        disabled
                        id="issue3"
                        label="Issue 3"
                        value={values.issues[2]}
                        margin="dense"
                    />
                    <input
                        type="text"
                        disabled
                        id="subissue3"
                        label="SubIssue 3"
                        value={values.subIssues[2]}
                        margin="dense"
                    />
                </form>
            } else {
                issuefields = <form fullWidth>
                    <input
                        type="text"
                        disabled
                        id="issue2"
                        label="Issue 2"
                        value={values.issues[1]}
                        margin="dense"
                    />
                    <input
                        type="text"
                        disabled
                        id="subissue2"
                        label="SubIssue 2"
                        value={values.subIssues[1]}
                        margin="dense"
                    />
                </form>
            }
        }

        let workingonit;
        if (this.state.loadingspinner) {
            workingonit =
            
            <Button id="submitCon"><Puff   align="center" height="40" width="40" radius="9"color="green"ariaLabel="loading" wrapperStyle wrapperClass /></Button> 

            

                
        }else{
            workingonit =
             
            <Button id="submit" disabled={this.state.loadingspinner} onClick={this.onSubmit} > Submit</Button>
              
        }

        return (
            <div class="tw-px-5 tw-py-5">
                <Accordion editPage={this.editPage} items={accordionItems} />
                
                <div class="tw-px-5   sm:tw-text-xs lg:tw-text-lg">
                    <p><i>By submitting this complaint, you understand that the Attorney General is not your private attorney, but represents the public in enforcing laws designed to protect the public. Filing this complaint does not mean that the Attorney General has initiated a lawsuit or proceeding on your behalf or that it will do so. The Attorney General cannot give you legal advice or represent you in court. If you have any questions concerning your legal rights or responsibilities, you should contact a private attorney. The Attorney General routinely works with other state, local, and federal government agencies to investigate complaints and may share your information with the Board of Elections for your county or the State of New York or another outside party, if necessary. In addition, the Attorney General may use information from your complaint in legal proceedings to establish violations of law. Any false statements made in this complaint are punishable offenses.</i></p>
                </div>
                <div>

                    <Recaptcha
                        align="center"
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={this.captchaChange}
                    />

                </div>

               
                    <div align="center" class="tw-pt-2">
                        
                        <Button id="back" onClick={this.back}>Back</Button>

                        &nbsp;&nbsp;&nbsp;

                        
                       {workingonit}
                    </div>

                
            </div>
        );
    }
}

export default Confirm;
