import React, { Component } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import SubIssue from './subissue';

export class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      issues: [],
      subIssues: [],
      issueValid: true,
      formValid: true,
    };

  }
  componentDidMount() {
    //console.log("In Comp Mount")
    const url = process.env.REACT_APP_API_URL + "IssueType/OnlyIssues";
    const headers = new Headers();
    const authkey = 'E436997B4F77CF3456CDFF788A25D';

    headers.append("Authorization", authkey);

    const options = {
      method: "GET",
      headers: headers,
    };

    //console.log(options)
    //console.log(url)

    fetch(url, options)
      .then(res => res.json())
      .then(
        (result) => {
          //console.log("In the good side")
          this.setState({
            isLoaded: true,
            issues: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
          //console.log(error)
        }
      )
  }

  render() {
    const { value, subvalue, issueerrorvar, index, subissueerrorvar, setIssue, setSubIssue, admin } = this.props;
    const { error, isLoaded, issues } = this.state;


    let subinput;
    //console.log("This is the current issue1 values: '" + value + "'")
    if (value === null || value === '') {
      //console.log("Null is true")
      subinput = <div></div>
    } else {
      //console.log("Value variable is currently " + value)
      subinput = <SubIssue
        disabled={this.props.disable}
        admin={admin}
        page={this.props.page}
        superissue={value}
        onChange={(e) => setSubIssue(e, index)}
        value={subvalue}
        subissueerrorvar={subissueerrorvar} />
    }

    let issuelabel;


    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.page === "Detail") {
      if (admin) {
        issuelabel = <label class={this.props.disable ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "} id="issue-select-label" required={!this.props.admin}>Type of Complaint</label>
      }
      else {
        issuelabel = <label class={this.props.disable ? "tw-block  tw-text-sm tw-font-medium tw-text-dark-gray" : "tw-block  tw-text-sm tw-font-medium "} id="issue-select-label">Type of Complaint</label>
      }
      return (
        <div>
          <form >
            {/* <InputLabel id="issue-select-label" required>Type of Complaint</InputLabel> */}
            {issuelabel}
            <select
              class={this.props.disable ? "tw-bg-light-gray tw-border tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-text-sm tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
              disabled={this.props.disable}
              value={value}
              id="issue-select"
              label="Type of Complaint x" //I put an X here as this determines how much room the outline gives to the InputLabel above
              onChange={(e) => setIssue(e, index)}
              error={!issueerrorvar}
            >
              <option value="">

              </option>
              {issues.map((issue, key) => <option key={key} value={issue}>{issue}</option>)}
            </select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </form>

          <form fullWidth>
            {subinput}
          </form>
        </div>

      )


    } else if (this.props.page === "Search") {
      if (admin) {
        issuelabel = <label class={this.props.disable ? "tw-block  tw-text-sm tw-font-medium" : "tw-block  tw-text-sm tw-font-medium"} id="issue-select-label" required={!this.props.admin}>Type of Complaint</label>
      }
      else {
        issuelabel = <label class={this.props.disable ? "tw-block  tw-text-sm tw-font-medium" : "tw-block  tw-text-sm tw-font-medium"} id="issue-select-label">Type of Complaint</label>
      }
      return (
        <div>
          <form >
            {/* <InputLabel id="issue-select-label" required>Type of Complaint</InputLabel> */}
            {issuelabel}
            <select
              class={this.props.disable ? "tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  " : "tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "}
              disabled={this.props.disable}
              value={value}
              id="issue-select"
              label="Type of Complaint x" //I put an X here as this determines how much room the outline gives to the InputLabel above
              onChange={(e) => setIssue(e, index)}
              error={!issueerrorvar}
            >
              <option value="">

              </option>
              {issues.map((issue, key) => <option key={key} value={issue}>{issue}</option>)}
            </select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </form>

          <form fullWidth>
            {subinput}
          </form>
        </div>

      )


    }
    else {
      if (admin) {
        issuelabel = <label class={this.props.disable ? "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray" : "tw-block tw-mb-2 tw-text-md tw-font-medium "} id="issue-select-label" required={!this.props.admin}>Type of Complaint</label>
      }
      else {
        issuelabel = <label class={this.props.disable ? "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray" : "tw-block tw-mb-2 tw-text-md tw-font-medium "} id="issue-select-label">Type of Complaint</label>
      }
      return (
        <div>
          <form >
            {/* <InputLabel id="issue-select-label" required>Type of Complaint</InputLabel> */}
            {issuelabel}
            <select
              class="tw-h-[63px]  tw-border   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "
              disabled={this.props.disable}
              value={value}
              labelId="issue-select-label"
              id="issue-select"
              label="Type of Complaint x" //I put an X here as this determines how much room the outline gives to the InputLabel above
              onChange={(e) => setIssue(e, index)}
              error={!issueerrorvar}
            >
              <option value="">

              </option>
              {issues.map((issue, key) => <option key={key} value={issue}>{issue}</option>)}
            </select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </form>
          <br />
          <form fullWidth>
            {subinput}
          </form>

        </div>
      )
    }

  }
};

export default Issue;
