

import React, { Component } from 'react';

import { FormControl, Autocomplete, ButtonGroup } from '@mui/material';
import * as MuIcons from '@mui/icons-material';
//import StyledPaper from '/./helpers/StyledPaper.js';
import { styled } from "@mui/system";
import { red } from '@mui/material/colors';
//import { OAGStyle, OAGTextField, OAGButtonGroup } from '../../../helpers/StyledPaper.js';
import OAGTextField from '../../../components/OAGTextField.js';
import Alert from '../../../components/Alert.js';
import Button from '../../../components/Button.js';
import Callout from '../../../components/Callout.js';
import GoogleTranslate from '../../../helpers/GoogleTranslate.jsx';
const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validPhoneRegex = RegExp(
    /^\({0,1}\d{3}\){0,1}[\-\. ]{0,1}\d{3}[\-\. ]{0,1}\d{4}.*$/i
);

const validZIPRegex = RegExp(
    /^\d{5}(?:\-\d{4})*$/i
);

export class VoterDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastnameValid: true,
            firstnameValid: true,
            emailValid: true,
            phonenumberValid: true,
            addressValid: true,
            cityValid: true,
            stateValid: true,
            zipValid: true,
            countyValid: true,
            dobValid: true,
            formValid: true,
            maskedphone: this.props.phonenumber,
            errors: {},
        };
    }

    continue = () => {
        this.props.nextStep();
        window.scrollTo(0, 0);
    };

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();

    };
    
    validateForm = (e) => {
        e.preventDefault();
        let validationerrors = false;

        //For the record I'm not proud of how I've had to do this. TH 07072022
        if (this.props.values.firstname === '' || this.props.values.firstname == null) {
            //console.log("First Name Bad ");
            validationerrors = true;
            this.setState({
                formValid: false,
                firstnameValid: false
            })
        } else { this.setState({ firstnameValid: true }) };

        if (this.props.values.lastname === '' || this.props.values.lastname == null) {
            //console.log("Last Name Bad ");
            validationerrors = true;
            this.setState({
                formValid: false,
                lastnameValid: false
            })
        } else { this.setState({ lastnameValid: true }) };

        if (this.props.values.phonenumber === '' || this.props.values.phonenumber == null) {
            //console.log("Phone Number Bad ");
            //If this is being filled in by a Volunteer, email is optional
            if (!this.props.admin) {
                validationerrors = true;
                this.setState({
                    formValid: false,
                    phonenumberValid: false
                })
            }
            else { this.setState({ phonenumberValid: true }) }
        }
        else {
            //Check if the Phone is formatted right
            if (!validPhoneRegex.test(this.props.values.phonenumber)) {
                //console.log("Phone Bad Format");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    phonenumberValid: false
                })
            }
            else {
                this.setState({ phonenumberValid: true })
            }
        };

        if (this.props.values.email === '' || this.props.values.email == null) {
            //If this is being filled in by a Volunteer, email is optional
            if (!this.props.admin) {
                //console.log("Email Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    emailValid: false
                })
            }
            else {
                this.setState({ emailValid: true });
            }
        }
        else {
            //Something was provided, check the email Format
            if (!validEmailRegex.test(this.props.values.email)) {
                //console.log("Email format Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    emailValid: false
                })
            }
            else {
                this.setState({ emailValid: true })
            }
        };

        if (this.props.values.address === '' || this.props.values.address == null) {
            //If this is being filled in by a Volunteer, address is optional
            if (!this.props.admin) {
                //console.log("Address Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    addressValid: false
                })
            } else { this.setState({ addressValid: true }) }

        }
        else { this.setState({ addressValid: true }) };

        if (this.props.values.city === '' || this.props.values.city == null) {
            if (!this.props.admin) {
                //console.log("City Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    cityValid: false
                })
            } else { this.setState({ cityValid: true }) }
        }
        else {
            this.setState({ cityValid: true })
        };

        if (this.props.values.state === '' || this.props.values.state == null) {
            if (!this.props.admin) {
                //console.log("State Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    stateValid: false
                })
            }
        }
        else {
            if (statevalidations.includes(this.props.values.state)) {
                this.setState({ stateValid: true })
            }
            else {
                validationerrors = true;
                this.setState({
                    formValid: false,
                    stateValid: false
                })
            }
            // console.log("State is good")
            // this.setState({ stateValid: true }) 
        }

        if (this.props.values.zip === '' || this.props.values.zip == null) {
            //console.log("Seems like zip is empty");
            if (!this.props.admin) {
                //console.log("Zip Empty ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    zipValid: false
                })
            } else {
                //It's an admin and Zip is not provided, all is well.
                this.setState({ zipValid: true })
            }
        }
        else {
            //console.log("Zip Optional for Admin")
            //Check if the Zip is formatted right
            if (!validZIPRegex.test(this.props.values.zip)) {
                //console.log("Zip Bad Format");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    zipValid: false
                })
            }
            else {
                this.setState({ zipValid: true })
            }

        };

        if (this.props.values.county === '' || this.props.values.county == null) {
            if (!this.props.admin) {
                //console.log("County Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    countyValid: false
                });
            }
        }
        else {
            //console.log("this.props.values.county is " +this.props.values.county)
            if (countyvalidations.includes(this.props.values.county)) {

                //console.log(countyvalidations.filter(a => { return a.label === this.props.values.county }))
                //console.log("County is valid");
                this.setState({ countyValid: true })
            }
            else {
                // Check for Case insensitivity
                // let low_case_array = countyvalidations.map(v => v.toLowerCase());
                // let case_ins_county = this.props.values.county.toLowerCase()
                // if(low_case_array.includes(case_ins_county)){
                //   //console.log("YA TAAA")
                //   console.log(case_ins_county);
                //   const words = case_ins_county.split(" ");

                //   for (let i = 0; i < words.length; i++) {
                //       words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                //   }

                //   words.join(" ");
                //   console.log(words.toString());
                // }


                //console.log("County not a good value")
                validationerrors = true;
                this.setState({
                    formValid: false,
                    countyValid: false
                })
            }

        };

        if (this.props.values.dob === '' || this.props.values.dob == null || this.props.checkDate(this.props.values.dob) == false) {
            // if(!this.props.admin)
            // {
            //console.log("DOB Bad ");
            validationerrors = true;
            this.setState({
                formValid: false,
                dobValid: false
            })
            // } else { this.setState({ dobValid: true }) }
        }
        else { this.setState({ dobValid: true }) };



        if (!validationerrors) {
            //console.log("Everything is good");
            this.setState({
                lastnameValid: true,
                firstnameValid: true,
                emailValid: true,
                phonenumberValid: true,
                addressValid: true,
                cityValid: true,
                stateValid: true,
                zipValid: true,
                countyValid: true,
                dobValid: true,
                formValid: true
            })
            //console.log("Correct");
            this.continue();

        }
        else {
            //console.log("Things are not good");
            //console.log("Validation Errors: " + validationerrors);

            // //console.log(this.state.lastnameValid);
            // console.log(this.state.firstnameValid);
            // console.log(this.state.emailValid);
            // console.log(this.state.phonenumberValid);
            // console.log(this.state.addressValid);
            // console.log(this.state.cityValid);
            // console.log(this.state.stateValid);
            // console.log(this.state.zipValid);
            // console.log(this.state.countyValid);
            // console.log(this.state.dobValid);
            // console.log(this.state.formValid);
        }

    };



    
    render() {
        const { values, handleChange, admin } = this.props;
        const states = usStates.map(state => (
            <option key={state.label} value={state.label}>
                {state.label}
            </option>
        ));
        const counties = nyCounties.map(county => (
            <option key={county.label} value={county.label}>
                {county.label}
            </option>
        ));



        const { errors } = this.state;
        var dis = false;
        var tdy = new Date();
        var localdate = this.props.toISOLocal(tdy);
        var defaultdate = localdate.substr(0, 10);
        return (

            <main class="tw-px-5 tw-py-5">
                
                <form>
                    <div class="tw-px-5 tw-rounded-xl tw-bg-light-gray">
                        <hr />
                        <h1 class=" xs:tw-text-xs tw-text-sm  tw-m-0 tw-font-blue tw-text-blue  ">Note: Asterisks (*) indicate required fields</h1>
                        {/* First Name and Last Name */}


                        <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="firstName" class={this.state.firstnameValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>First Name *</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    defaultValue={values.firstname}
                                    onChange={handleChange('firstname')}
                                    placeholder="Enter Your First Name"
                                    class={this.state.firstnameValid ? " tw-border tw-placeholder-gray tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    required
                                    maxLength={100}

                                />
                                <label for="firstName" class={this.state.firstnameValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>First Name is required</label>

                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="last_name" class={this.state.lastnameValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>Last Name *</label>
                                <input type="text"
                                    id="lastName"
                                    class={this.state.lastnameValid ? " tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Enter Your Last Name"
                                    defaultValue={values.lastname}
                                    onChange={handleChange('lastname')}
                                    required 
                                    maxLength={100}

                                    />
                                <label for="lastName" class={this.state.lastnameValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Last Name is required</label>

                            </div>
                        </div>

                        {/* Business Name Organization Name */}
                        <div class="tw-grid tw-gap-6 md:tw-grid-cols-3">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="Phone Number" class={this.state.phonenumberValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "Phone Number" : "Phone Number *"}</label>
                                <input
                                    type="tel"
                                    id="Phone Number"
                                    class={this.state.phonenumberValid ? "tw-placeholder-gray tw-border  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Enter your Phone Number"
                                    value={values.phonenumber}

                                    onChange={handleChange('phonenumber')}
                                    required
                                    maxLength={30}

                                />
                                <label for="phone" class={this.state.phonenumberValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Phone Number is required and must be in a valid format of either 5555555555 or (555)555-5555</label>

                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="email" class={this.state.emailValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "Email" : "Email *"}</label>
                                <input type="text"
                                    id="email"
                                    class={this.state.emailValid ? "tw-placeholder-gray tw-border tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5" : " tw-border  tw-border-light-red tw-placeholder-light-red tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Enter your Email"
                                    defaultValue={values.email}
                                    onChange={handleChange('email')}
                                    required
                                    maxLength={100}

                                />
                                <label for="email" class={this.state.emailValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Email is required and must be a valid email.</label>
                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="dob" class={this.state.dobValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>Date of Birth *</label>
                                <input type="date"
                                    id="dob"
                                    class={this.state.dobValid ? " tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Please enter your birthday"
                                    defaultValue={values.dob}
                                    onChange={handleChange('dob')}
                                    required
                                    max = {defaultdate}

                                />
                                <label for="dob" class={this.state.dobValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Date of Birth is required</label>

                            </div>
                        </div>
                        {/* Address Block - Street Info */}
                        <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="address" class={this.state.addressValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "Address" : "Address *"}</label>
                                <input type="text"
                                    id="address"
                                    class={this.state.addressValid ? " tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Enter your mailing Address"
                                    defaultValue={values.address}
                                    onChange={handleChange('address')}
                                    required
                                    maxLength={100}

                                />
                                <label for="address" class={this.state.addressValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Address is required</label>

                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="address2" class="tw-block tw-mb-2 tw-text-md tw-font-medium  ">Address Line 2</label>
                                <input type="text"
                                    id="address2"
                                    class=" tw-border  tw-placeholder-gray tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "
                                    placeholder="Enter your mailing address line"
                                    defaultValue={values.address2}
                                    onChange={handleChange('address2')}
                                    maxLength={100}

                                />
                            </div>
                        </div>
                        {/* Address Block - Region Info */}
                        <div class="tw-grid tw-gap-6 md:tw-grid-cols-4">
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="city" class={this.state.cityValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "City" : "City *"}</label>
                                <input type="text"
                                    id="city"
                                    class={this.state.cityValid ? " tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Enter your City"
                                    defaultValue={values.city}
                                    onChange={handleChange('city')}
                                    maxLength={100}

                                />
                                <label for="city" class={this.state.cityValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>City is required</label>

                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="state" class={this.state.stateValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "State" : "State *"}</label>
                                <select
                                    id="state"
                                    class={this.state.stateValid ? " tw-h-[63px]  tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-h-[63px] tw-p-2.5     "}
                                    placeholder="Enter your State"
                                    defaultValue={values.state}
                                    onChange={handleChange('state')}
                                    required

                                >
                                    {states}

                                </select>
                                <label for="state" class={this.state.stateValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>State is reauired and must be a value selected from the dropdown list</label>

                            </div>


                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="zip" class={this.state.zipValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "Zip/Postal Code" : "Zip/Postal Code *"}</label>
                                <input type="text"
                                    id="zip"
                                    class={this.state.zipValid ? " tw-border   tw-placeholder-gray tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     "}
                                    placeholder="Enter your Zip"
                                    value={values.zip}
                                    onChange={handleChange('zip')}
                                    required
                                    maxLength={10}

                                />
                                <label for="zip" class={this.state.zipValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Zip is required and must be in an acceptable format of either 12345 or 12345-6789</label>

                            </div>
                            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                                <label for="county" class={this.state.countyValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium  " : "tw-block tw-mb-2 tw-text-md tw-font-medium   tw-text-light-red"}>{this.props.admin ? "County" : "County *"}</label>
                                <select
                                    id="county"
                                    class={this.state.countyValid ? " tw-h-[63px]  tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5     " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-h-[63px] tw-p-2.5     "}
                                    placeholder="Enter your County"
                                    defaultValue={values.county}
                                    onChange={handleChange('county')}
                                >
                                    {counties}
                                </select>
                                <label for="county" class={this.state.countyValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>County is required and must be a value selected from the dropdown list</label>

                            </div>
                        </div>


                        <div class="tw-pt-2" align="center">
                            <Button disabled id="backDisabled" onClick={this.back}>Back</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button id="next" onClick={this.validateForm} >Next</Button>

                        </div>
                        <hr />

                    </div>
                    
                </form>
            </main>
        );
    }
}
export default VoterDetails;

const nyCounties = [
    { label: '' },
    { label: 'Albany' },
    { label: 'Allegany' },
    { label: 'Bronx' },
    { label: 'Broome' },
    { label: 'Cattaraugus' },
    { label: 'Cayuga' },
    { label: 'Chautauqua' },
    { label: 'Chemung' },
    { label: 'Chenango' },
    { label: 'Clinton' },
    { label: 'Columbia' },
    { label: 'Cortland' },
    { label: 'Delaware' },
    { label: 'Dutchess' },
    { label: 'Erie' },
    { label: 'Essex' },
    { label: 'Franklin' },
    { label: 'Fulton' },
    { label: 'Genesee' },
    { label: 'Greene' },
    { label: 'Hamilton' },
    { label: 'Herkimer' },
    { label: 'Jefferson' },
    { label: 'Kings' },
    { label: 'Lewis' },
    { label: 'Livingston' },
    { label: 'Madison' },
    { label: 'Monroe' },
    { label: 'Montgomery' },
    { label: 'Nassau' },
    { label: 'New York' },
    { label: 'Niagara' },
    { label: 'Oneida' },
    { label: 'Onondaga' },
    { label: 'Ontario' },
    { label: 'Orange' },
    { label: 'Orleans' },
    { label: 'Oswego' },
    { label: 'Otsego' },
    { label: 'Putnam' },
    { label: 'Queens' },
    { label: 'Rensselaer' },
    { label: 'Richmond' },
    { label: 'Rockland' },
    { label: 'St. Lawrence' },
    { label: 'Saratoga' },
    { label: 'Schenectady' },
    { label: 'Schoharie' },
    { label: 'Schuyler' },
    { label: 'Seneca' },
    { label: 'Steuben' },
    { label: 'Suffolk' },
    { label: 'Sullivan' },
    { label: 'Tioga' },
    { label: 'Tompkins' },
    { label: 'Ulster' },
    { label: 'Warren' },
    { label: 'Washington' },
    { label: 'Wayne' },
    { label: 'Westchester' },
    { label: 'Wyoming' },
    { label: 'Yates' }
]

const usStates = [
    { label: '' },
    { label: 'Alabama' },
    { label: 'Alaska' },
    { label: 'Arizona' },
    { label: 'Arkansas' },
    { label: 'California' },
    { label: 'Colorado' },
    { label: 'Connecticut' },
    { label: 'Delaware' },
    { label: 'Florida' },
    { label: 'Georgia' },
    { label: 'Hawaii' },
    { label: 'Idaho' },
    { label: 'Illinois' },
    { label: 'Indiana' },
    { label: 'Iowa' },
    { label: 'Kansas' },
    { label: 'Kentucky' },
    { label: 'Louisiana' },
    { label: 'Maine' },
    { label: 'Maryland' },
    { label: 'Massachusetts' },
    { label: 'Michigan' },
    { label: 'Minnesota' },
    { label: 'Mississippi' },
    { label: 'Missouri' },
    { label: 'Montana' },
    { label: 'Nebraska' },
    { label: 'Nevada' },
    { label: 'New Hampshire' },
    { label: 'New Jersey' },
    { label: 'New Mexico' },
    { label: 'New York' },
    { label: 'North Carolina' },
    { label: 'North Dakota' },
    { label: 'Ohio' },
    { label: 'Oklahoma' },
    { label: 'Oregon' },
    { label: 'Pennsylvania' },
    { label: 'Rhode Island' },
    { label: 'South Carolina' },
    { label: 'South Dakota' },
    { label: 'Tennessee' },
    { label: 'Texas' },
    { label: 'Utah' },
    { label: 'Vermont' },
    { label: 'Virginia' },
    { label: 'Washington' },
    { label: 'West Virginia' },
    { label: 'Wisconsin' },
    { label: 'Wyoming' }
]

const countyvalidations = ["Albany", "Allegany", "Bronx", "Broome", "Cattaraugus", "Cayuga", "Chautauqua", "Chemung", "Chenango", "Clinton", "Columbia", "Cortland", "Delaware", "Dutchess", "Erie", "Essex", "Franklin", "Fulton", "Genesee", "Greene", "Hamilton", "Herkimer", "Jefferson", "Kings", "Lewis", "Livingston", "Madison", "Monroe", "Montgomery", "Nassau", "New York", "Niagara", "Oneida", "Onondaga", "Ontario", "Orange", "Orleans", "Oswego", "Otsego", "Putnam", "Queens", "Rensselaer", "Richmond", "Rockland", "St. Lawrence", "Saratoga", "Schenectady", "Schoharie", "Schuyler", "Seneca", "Steuben", "Suffolk", "Sullivan", "Tioga", "Tompkins", "Ulster", "Warren", "Washington", "Wayne", "Westchester", "Wyoming", "Yates"];
const statevalidations = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]