import React, { Component } from 'react'
import axios from 'axios';
import { loginRequest, protectedResources } from "../../../authConfig";
import { msalInstance } from "../../../index";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import Input from '@mui/material/Input';
import Edit from "../../../images/icons/eva/fill/download.svg";
import Delete from "../../../images/icons/eva/outline/trash-outline.svg";


export default class documentblock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      disabled: true,
      open: false,
      attachment1: '',
      attachment2: '',
      //firstAttachment:'',
      //firstAttachmentName: '',
      //firstAttachmentExtension: '',
    };
  }

  getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...loginRequest
    });

    return response.accessToken;
  }

  //Only called when the page is first loaded
  async componentDidMount() {
    const token = await this.getToken();
    this.getAttachmentList(this.props.complaint, token);
  }

  getAttachmentList = async (id, token) => {
    //console.log("Getting File names")
    const url = process.env.REACT_APP_API_URL + "Documents/GetDocNamesForComplaint?cmpltid=" + id;
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("content-type", "application/json");
    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(url, options)
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result + " Hellloo");
          this.setState({
            isLoaded: true,
            //attachment1: result[0],
            //attachment2: result[1],
          });
         // console.log(result[0]);
         // console.log(result + " Hellloo");
        //  console.log(result[1]);
          (result[0] == undefined) ? this.setState({ attachment1: '' }) : this.setState({ attachment1: result[0] });
          (result[1] == undefined) ? this.setState({ attachment2: '' }) : this.setState({ attachment2: result[1] });

        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
          //console.log("Error in document GET; isLoaded was set to true");
          //console.log(error);
        }
      )


  }

  uploadAtt1 = async (e) => {
    if (e.target.files[0].size > 20e6) {
      window.alert("Please upload a file smaller than 20 MB");
      return false;
    }
    //console.log("In the attachment 1 change handler")

    var files = e.target.files;
    var fileName = files[0].name;
    //console.log("what we got? " + fileName);

    const fileExtension = e.target.files[0].name.split(".").at(-1);
    const allowedFileTypes = ["pdf", "png", "jpg", "jpeg", "bmp"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`Unsupported file type. Files type must be .${allowedFileTypes}`);
      return false;
    }

    const postData = new FormData();
    //console.log("ID: " + this.props.complaint);
    postData.append("CmpltId", this.props.complaint);

    postData.append("attachment1", files[0]);
    postData.append("att1filename", fileName);
    postData.append("att1extension", fileExtension);

    const url = process.env.REACT_APP_API_URL + "Documents/update-first-att"
    //console.log("state: " + files);
    //console.log("state: " + fileName);

    //console.log("state: " + fileExtension);

    await axios({
      method: "PUT",
      url: url,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },

    })
      .then((response) => {

        //console.log("very good");
        //this.props.setSubmissionCodes(response.status, response.data);

      })
      .catch((error) => {
        //this.props.setSubmissionCodes(error.response.status, error.response.data);

      })
    const token = await this.getToken();
    this.getAttachmentList(this.props.complaint, token);
  };



  uploadAtt2 = async (e) => {
    if (e.target.files[0].size > 20e6) {
      window.alert("Please upload a file smaller than 20 MB");
      return false;
    }
    //console.log("In the attachment 1 change handler")

    var files = e.target.files;
    var fileName = files[0].name;
    //console.log("what we got? " + fileName);

    const fileExtension = e.target.files[0].name.split(".").at(-1);
    const allowedFileTypes = ["pdf", "png", "jpg", "jpeg", "bmp"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`Unsupported file type. Files type must be .${allowedFileTypes}`);
      return false;
    }

    const postData = new FormData();
    //console.log("ID: " + this.props.complaint);
    postData.append("CmpltId", this.props.complaint);

    postData.append("attachment2", files[0]);
    postData.append("att2filename", fileName);
    postData.append("att2extension", fileExtension);

    const url = process.env.REACT_APP_API_URL + "Documents/update-att2/" + this.props.complaint;
    //console.log("state: " + files[0]);
    //console.log("state: " + fileName);

   // console.log("state: " + fileExtension);

    await axios({
      method: "Put",
      url: url,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },

    })
      .then((response) => {

        //console.log("very good");
        //this.props.setSubmissionCodes(response.status, response.data);

      })
      .catch((error) => {
        //this.props.setSubmissionCodes(error.response.status, error.response.data);

      })
    const token = await this.getToken();
    this.getAttachmentList(this.props.complaint, token);
  }

  downloadAtt1 = async (e) => {
    e.preventDefault();

    const token = await this.getToken();
    //console.log(token);

    //AXIOS METHOD 2
    axios({
      url: process.env.REACT_APP_API_URL + "Documents/get-first-att/" + this.props.complaint,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.state.attachment1);
      document.body.appendChild(link);
      link.click();
    });

  }


  downloadAtt2 = async (e) => {
    e.preventDefault();
    const token = await this.getToken();


    axios({
      url: process.env.REACT_APP_API_URL + "Documents/get-second-att/" + this.props.complaint,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.state.attachment2);
      document.body.appendChild(link);
      link.click();
    });
  }



  deleteAtt1 = async (e) => {
    e.preventDefault();

    const token = await this.getToken();
    //console.log(token);

    //AXIOS METHOD 2
    axios({
      url: process.env.REACT_APP_API_URL + "Documents/delete-att1/" + this.props.complaint,
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` },
      responseType: 'bool', // important
    }).then((response) => {
      //console.log(response);
    });

    //this.getAttachmentList(this.props.complaint, token);
    window.location.reload();
  }
  deleteAtt2 = async (e) => {
    e.preventDefault();

    const token = await this.getToken();
    //console.log(token);

    //AXIOS METHOD 2
    axios({
      url: process.env.REACT_APP_API_URL + "Documents/delete-att2/" + this.props.complaint,
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` },
      responseType: 'bool', // important
    }).then((response) => {
      //console.log(response);
    });
    window.location.reload();
  }

  // downloadEmployeeData = () => {
  //   fetch('https://localhost:7259/api/Documents/get-file-content/33')
  //     .then(response => {
  //       response.blob().then(blob => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement('a');
  //         a.href = url;
  //         a.download = 'plstemp.pdf';
  //         a.click();
  //       });
  //       //window.location.href = response.url;
  //   });
  // }


  render() {
    let attach1downloadable, attach2downloadable;
    (this.state.attachment1 == '') ? attach1downloadable =

      <div class="tw-grid md:tw-grid-cols-1">
        <div>
          <label class="  tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray-900 " for="first">First Attachment</label>
          <input
            type="file"
            id="first"
            accept="application/pdf, image/png, image/jpg, image/jpeg, image/bmp"
            class="tw-mb-2"
            onChange={this.uploadAtt1}
          />
        </div>
      </div>


      : attach1downloadable =

      <div class="tw-grid md:tw-grid-cols-2">
        <div>
          <label class="  tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray-900 " for="first">First Attachment</label>
          <input
          class= "tw-mb-2 tw-bg-light-gray tw-border tw-text-dark-gray tw-text-md tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "
            value={this.state.attachment1}
            type="text"
            id="first"
            disabled
          />
        </div>
        <div class="tw-flex tw-pt-2">
          <button id="Unlock" className="tw-px-4 text-white hover:text-blue-500" onClick={this.downloadAtt1}>
            <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Edit} />
          </button>
          <button id="Unlock" className="tw-px-4 text-white hover:text-blue-500" onClick={this.deleteAtt1}>
            <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
          </button>
        </div>
      </div>;
    (this.state.attachment2 == '') ? attach2downloadable =

      <div class="tw-grid md:tw-grid-cols-1">
        <div>
          <label class="  tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray-900 " for="second">Second Attachment</label>
          <input

            type="file"
            id="second"
            accept="application/pdf, image/png, image/jpg, image/jpeg, image/bmp"
            onChange={this.uploadAtt2}
          />
        </div>
      </div>
      : attach2downloadable =
      <div class="tw-grid md:tw-grid-cols-2">
        <div>
          <label class="  tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray-900 " for="second">Second Attachment</label>
          <input
                    class= "tw-mb-2 tw-bg-light-gray tw-border tw-text-dark-gray tw-text-md tw-rounded-lg  tw-block tw-w-full tw-p-2.5 "

            value={this.state.attachment2}
            type="text"
            id="second"
            disabled
          />
        </div>
        <div class="tw-flex tw-pt-2 ">
          <button id="Unlock" className="tw-px-4 text-white hover:text-blue-500" onClick={this.downloadAtt2}>
            <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Edit} />
          </button>
          <button id="Unlock" className="tw-px-4 text-white hover:text-blue-500" onClick={this.deleteAtt2}>
            <img className="tw-block tw-rounded-full hover:tw-bg-gray tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px] " src={Delete} />
          </button>
        </div>
      </div>;
      
    return (
      <div class="tw-px-5 tw-py-5 tw-shadow-xl tw-rounded-2xl tw-bg-lightest-gray">
        <h3>
          Documents
        </h3>
        {/* Provided Evidence.. */}
        {attach1downloadable}
        {attach2downloadable}

        {/* <ul>
        <li>
          <a href="#" onClick={this.downloadAtt1}>
            {this.state.attachment1}
          </a>
        </li>
        <li>
        <a href="#" onClick={this.downloadAtt2}>
          {this.state.attachment2}
          </a>
        </li>
        </ul> */}

        {/* <div id="container">
          <h1>Download File using React App</h1>
          <h3>Download Employee Data using Button</h3>
          <button onClick={this.downloadAtt1}>Download</button>
          <p/>
          <h3>Download Employee Data using Link</h3>
          <a href="#" onClick={this.downloadAtt2}>Download</a>
        </div>*/}
      </div>
    )
  }
}
