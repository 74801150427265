import React, { Component } from "react";
import StatusStoplight from "../../helpers/statusStoplight.js";
import axios from 'axios';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import VolunteerDropdown from "../../helpers/volunteerDropdown.js";
import CaseStatus from "../../helpers/caseStatus.js";
import Issue from "../common/formcomponents/issues.js";
import Button from "../../components/Button.js";
import { loginRequest, protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";
import collapseIcon from "../../images/icons/eva/fill/collapse.svg";
import expandIcon from "../../images/icons/eva/fill/expand.svg";
import { Resizable } from 'react-resizable';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CustomPagination from "../../components/customPagination.js";




class searchForm extends React.Component {
	state = {
		error: null,
		isLoaded: false,
		cmpltId: '',
		status: '',
		assignedTo: '',
		lastName: '',
		firstName: '',
		phoneNumber: '',
		emailAddr: '',
		county: '',
		pollingPlace: '',
		incDt: '',
		election: '',
		contDt: '',
		crDt: '',
		affidavitBallot: '',
		compType: '',
		issueType: '',
		issueSubtype: '',
		detail: '',
		interrelation: '',
		searched: false,
		complaints: [],
		elections: [],
		graphresult: [],
		assignedtos: [],
		graphToken: '',
		issues: [],
		issue: '',
		subissue: '',
		currentPage: 0, // Current page number
		pageSize: 25,  // Number of items per page
		iconSrc: collapseIcon,
		page: 0,


	}

	handlePageChange = (newPage) => {
		this.setState({ page: newPage }); // Update the 'page' state with the new page number
		localStorage.setItem("page", newPage)
	};
	handlePageSizeChange = (newPageSize) => {
		this.setState({ pageSize: newPageSize });
	};

	
	async componentDidMount() {
		localStorage.setItem("pageSize", this.state.pageSize)

		const graphAccessToken = this.getGraphToken();
		this.setState({ graphToken: graphAccessToken })

		//Get our Election Cycle LOV
		const url = process.env.REACT_APP_API_URL + "ElectionYear/LOV";
		const headers = new Headers();
		const authkey = 'E436997B4F77CF3456CDFF788A25D';

		headers.append("Authorization", authkey);

		const options = {
			method: "GET",
			headers: headers,
		};
		var electOptions = "";
		fetch(url, options)
			.then(res => res.json())
			.then(

				(result) => {
					//console.log(result);
					electOptions = result.map(test => (
						<option key={test.election} value={test.election}>
							{test.election}
						</option>
					));
					//console.log("electOptions: " + electOptions[0]);
					this.setState({
						isLoaded: true,
						elections: electOptions
					});
				},
				(error) => {
					this.setState({
						isLoaded: true,
						error
					});
				}
			)
		//localStorage.clear();
		//console.log(this.state.elections);
		if (localStorage.getItem("cmpltId")) { this.state.cmpltId = localStorage.getItem("cmpltId") }
		if (localStorage.getItem("status")) { this.state.status = localStorage.getItem("status") }
		if (localStorage.getItem("assignedTo")) { this.state.assignedTo = localStorage.getItem("assignedTo") }
		if (localStorage.getItem("lastName")) { this.state.lastName = localStorage.getItem("lastName") }
		if (localStorage.getItem("firstName")) { this.state.firstName = localStorage.getItem("firstName") }
		if (localStorage.getItem("phoneNumber")) { this.state.phoneNumber = localStorage.getItem("phoneNumber") }
		if (localStorage.getItem("emailAddr")) { this.state.emailAddr = localStorage.getItem("emailAddr") }
		if (localStorage.getItem("incDt")) { this.state.incDt = localStorage.getItem("incDt") }
		if (localStorage.getItem("pollingPlace")) { this.state.pollingPlace = localStorage.getItem("pollingPlace") }
		if (localStorage.getItem("county")) { this.state.county = localStorage.getItem("county") }
		if (localStorage.getItem("election")) { this.state.election = localStorage.getItem("election") }
		if (localStorage.getItem("contDt")) { this.state.contDt = localStorage.getItem("contDt") }
		if (localStorage.getItem("affidavitBallot")) { this.state.affidavitBallot = localStorage.getItem("affidavitBallot") }
		if (localStorage.getItem("crDt")) { this.state.crDt = localStorage.getItem("crDt") }
		if (localStorage.getItem("issueType")) { this.state.issueType = localStorage.getItem("issueType") }
		if (localStorage.getItem("issueSubtype")) { this.state.issueSubtype = localStorage.getItem("issueSubtype") }
		if (localStorage.getItem("detail")) { this.state.detail = localStorage.getItem("detail") }
		if (localStorage.getItem("interrelation")) { this.state.interrelation = localStorage.getItem("interrelation") }
		if (localStorage.getItem("page")) { this.state.page = localStorage.getItem("page") }


	}
	formatPhoneNumber = (number) => {

		//console.log(number);
		//console.log("number: " + number);
	
		const phoneNumber = number.replace(/[^\d]/g, '');
		//console.log("phoneNumber: " + phoneNumber);
		const length = phoneNumber.length;
	
		if (length < 4) { return `(${phoneNumber}` };
	
		if (length < 7) {
		  //console.log("< 7: " + phoneNumber);
	
		  //console.log(`(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`);
	
		  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	
		}
		//console.log(`(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6,)}-${phoneNumber.slice(6,10)}`);
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6,)}-${phoneNumber.slice(6, 10)}`;
	
	  }

	// handle field change
	handleChange = input => e => {
		//console.log("input: " + input);
		if (input == "issueType") {
			//console.log("in")
			this.setState({ "issueSubtype": "" });
			localStorage.setItem("issueSubtype", "");
			//console.log(localStorage.getItem("issueSubtype", ""));
			this.setState({ [input]: e.target.value });
		localStorage.setItem(input, e.target.value);
		}
		else{
		this.setState({ [input]: e.target.value });
		localStorage.setItem(input, e.target.value);
	}
	}

	getToken = async () => {
		const account = msalInstance.getActiveAccount();

		if (!account) {
			console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
		}

		const response = await msalInstance.acquireTokenSilent({
			account: account,
			...loginRequest
		});

		return response.accessToken;
	}

	getAPIToken = async () => {
		const account = msalInstance.getActiveAccount();

		if (!account) {
			console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
		}

		const response = await msalInstance.acquireTokenSilent({
			account: account,
			...loginRequest
			//scopes: [ "api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user" ]
		});
		//console.log(response);
		return response.accessToken;
	}

	getGraphToken = async () => {
		const account = msalInstance.getActiveAccount();

		if (!account) {
			console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
		}

		// "User.Read" stands as shorthand for "graph.microsoft.com/User.Read"
		const graphToken = await msalInstance.acquireTokenSilent({
			account: account,
			scopes: ["https://graph.microsoft.com/User.Read.All"]
		});
		//console.log(graphToken);


		return graphToken.accessToken;
	}

	toggleMenu = () => {
		const button = document.getElementById("collapse");
		const search = document.getElementById("searchInfo");
		//console.log("button:" + button.ariaExpanded);
		const collapse = search.getAttribute("class");

		//console.log("expanded:" + expanded);
		if (collapse == "") {
			this.setState({ "iconSrc": expandIcon });

			search.setAttribute("class", "tw-hidden")

			button.setAttribute("aria-expanded", "false");
		} else {
			this.setState({ "iconSrc": collapseIcon });

			search.setAttribute("class", "")



		}
	}
	handleSubmit = async (e) => {
		e.preventDefault();
		const token = await this.getAPIToken();
		const url = process.env.REACT_APP_API_URL + "Search";

		const params = {}
		if (this.state.cmpltId) { params.cmpltId = this.state.cmpltId; localStorage.setItem("cmpltId", this.state.cmpltId) }
		if (this.state.status) { params.status = this.state.status; localStorage.setItem("status", this.state.status) }
		if (this.state.assignedTo) { params.assignedTo = this.state.assignedTo; localStorage.setItem("assignedTo", this.state.assignedTo) }
		if (this.state.lastName) { params.lastName = this.state.lastName; localStorage.setItem("lastName", this.state.lastName) }
		if (this.state.firstName) { params.firstName = this.state.firstName; localStorage.setItem("firstName", this.state.firstName) }
		if (this.state.phoneNumber) { params.phoneNumber = this.state.phoneNumber; localStorage.setItem("phoneNumber", this.state.phoneNumber) }
		if (this.state.emailAddr) { params.emailAddr = this.state.emailAddr; localStorage.setItem("emailAddr", this.state.emailAddr) }
		if (this.state.incDt) { params.incDt = this.state.incDt; localStorage.setItem("incDt", this.state.incDt) }
		if (this.state.pollingPlace) { params.pollingPlace = this.state.pollingPlace; localStorage.setItem("pollingPlace", this.state.pollingPlace) }
		if (this.state.county) { params.county = this.state.county; localStorage.setItem("county", this.state.county) }
		if (this.state.election) { params.election = this.state.election; localStorage.setItem("election", this.state.election) }
		if (this.state.contDt) { params.contDt = this.state.contDt; localStorage.setItem("contDt", this.state.contDt) }
		if (this.state.affidavitBallot) { params.affidavitBallot = this.state.affidavitBallot; localStorage.setItem("affidavitBallot", this.state.affidavitBallot) }
		if (this.state.crDt) { params.crDt = this.state.crDt; localStorage.setItem("crDt", this.state.crDt) }
		if (this.state.issueType) { params.issueTypes = this.state.issueType; localStorage.setItem("issueTypes", this.state.issueType) }
		if (this.state.issueSubtype) { params.subIssueTypes = this.state.issueSubtype; localStorage.setItem("subIssueTypes", this.state.issueSubtype) }
		if (this.state.detail) { params.detail = this.state.detail; localStorage.setItem("detail", this.state.detail) }
		if (this.state.interrelation) { params.Interrelationcode = this.state.interrelation; localStorage.setItem("Interrelationcode", this.state.interrelation) }

		// console.log("cmpltId:" + this.state.cmpltId)
		// console.log("status:" + this.state.status)
		// console.log("assignedTo:" + this.state.assignedTo)
		// console.log("lastName:" + this.state.lastName)
		// console.log("firstName:" + this.state.firstName)
		// console.log("phoneNumber:" + this.state.phoneNumber)
		// console.log("emailAddr:" + this.state.emailAddr)
		// console.log("incDt:" + this.state.incDt)
		// console.log("pollingPlace:" + this.state.pollingPlace)
		// console.log("county:" + this.state.county)
		// console.log("election:" + this.state.election)
		// console.log("contDt:" + this.state.contDt)
		// console.log("affidavitBallot:" + this.state.affidavitBallot)
		// console.log("crDt:" + this.state.crDt)
		// console.log("issueType:" + this.state.issueType)
		// console.log("issueSubtype:" + this.state.issueSubtype)
		// console.log("detail:" + this.state.detail)
		// console.log("interrelation:" + this.state.interrelation)
		// console.log("Issue Type: " + this.state.issueType)
		//console.log("Issue Subtype: " + this.state.issueSubtype)


		//console.log("params: " + params);
		try {
			axios({
				method: "get",
				url: url,
				params: params,
				headers: { Authorization: `Bearer ${token}`, },
			}).then(res => {
				//console.log(res);
				const complaints = res.data;
				this.setState({ complaints });
				this.setState({ searched: true });
				localStorage.setItem("Cache", JSON.stringify(complaints));
				localStorage.setItem("length", complaints.length);
				//console.log("complaints: " + complaints);
				//console.log(JSON.parse(localStorage.getItem("Cache")));
			});
		} catch (err) {
			//console.log(err);
		}

	};
	handleResize = (index) => (e, { size }) => {
		this.setState((prevState) => {
			const nextColumns = [...prevState.columns];
			nextColumns[index] = {
				...nextColumns[index],
				width: size.width,
			};
			return { columns: nextColumns };
		});
	};

	render() {
		const { cmpltId, status, assignedTo, lastName, firstName, phoneNumber, emailAddr, county,
			pollingPlace, incDt, complaints, election, graphresult, contDt, affidavitBallot, crDt,
			issueType, issueSubtype, searched, detail, interrelation, currentPage, pageSize, page } = this.state;
		const { error, isLoaded, elections, assignedtos, issues, subIssues, graphToken } = this.state;
		const values = {
			cmpltId, status, assignedTo, lastName, firstName, phoneNumber, emailAddr, county,
			pollingPlace, incDt, election, contDt, affidavitBallot, crDt, issueType, issueSubtype, detail,
			interrelation
		}
		const counties = nyCounties.map(county => (
			<option key={county.label} value={county.label}>
				{county.label}
			</option>
		));
		const offset = currentPage * pageSize;


		const displayedComplaints = complaints.slice(offset, offset + pageSize);


		if (error) {
			return <div>Error: {error.message}</div>;
		} else if (!isLoaded) {
			return <div>Loading...</div>;
		} else {
			return (
				<main class="tw-px-5 tw-py-5">
					<div class="tw-px-5 tw-rounded-xl tw-bg-light-gray ">

						{/* Start of Form Area */}
						<div >
							<hr />
							<div class="tw-grid md:tw-grid-cols-2">
								<div>
									<h1 class=" tw-text-sm tw-m-0 tw-font-blue tw-text-blue  ">Please search on any details you know</h1>

								</div>
								<div align="right">

									<button id="collapse" onClick={this.toggleMenu}>
										<img width="30" height="30" src={this.state.iconSrc} />

									</button>
								</div>

							</div>
							<div id="searchInfo" class="">
								<div class="tw-grid tw-gap-6 md:tw-grid-cols-3 ">
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="Complaint ID" class="tw-block  tw-text-sm tw-font-medium ">Complaint Id</label>
										<input
											type="text"
											id="Complaint ID"
											defaultValue={values.cmpltId}
											onChange={this.handleChange('cmpltId')}
											value={localStorage.getItem("cmpltId")}
											class="  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										< CaseStatus page="search" handleChange={this.handleChange} status={values.status} />
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<VolunteerDropdown page="search" disabled={false} handleChange={this.handleChange} assignedTo={assignedTo} accessToken={graphToken} />								</div>
								</div>
								<div class="tw-grid tw-gap-6 md:tw-grid-cols-3">
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="firstName" class="tw-block  tw-text-sm tw-font-medium  ">First Name</label>
										<input
											type="text"
											id="firstName"
											defaultValue={values.firstName}
											onChange={this.handleChange('firstName')}
											value={localStorage.getItem("firstName")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="lastname" class="tw-block  tw-text-sm tw-font-medium  ">Last Name</label>
										<input
											type="text"
											id="lastname"
											defaultValue={values.lastName}
											onChange={this.handleChange('lastName')}
											value={localStorage.getItem("lastName")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="emailAddr" class="tw-block  tw-text-sm tw-font-medium  ">Email</label>
										<input
											type="text"
											id="emailAddr"
											defaultValue={values.emailAddr}
											onChange={this.handleChange('emailAddr')}
											value={localStorage.getItem("emailAddr")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>

								</div>
								<div class="tw-grid tw-gap-6 md:tw-grid-cols-3">
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="phoneNumber" class="tw-block  tw-text-sm tw-font-medium  ">Phone Number</label>
										<input
											type="text"
											id="phoneNumber"
											defaultValue={values.phoneNumber}
											onChange={this.handleChange('phoneNumber')}
											value={localStorage.getItem("phoneNumber")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="pollingPlace" class="tw-block  tw-text-sm tw-font-medium  ">Polling Place</label>
										<input
											type="text"
											id="pollingPlace"
											defaultValue={values.pollingPlace}
											onChange={this.handleChange('pollingPlace')}
											value={localStorage.getItem("pollingPlace")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="county" class="tw-block  tw-text-sm tw-font-medium  ">County</label>
										<select
											type="text"
											id="county"
											defaultValue={values.county}
											onChange={this.handleChange('county')}
											value={localStorage.getItem("county")}
											class="tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										>
											<option value=""></option>
											{counties}
										</select>
									</div>
								</div>
								<div class="tw-grid tw-gap-6 md:tw-grid-cols-3">
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="date" class="tw-block  tw-text-sm tw-font-medium  ">Incident Date</label>
										<input
											type="date"
											id="date"
											defaultValue={values.incDt}
											onChange={this.handleChange('incDt')}
											value={localStorage.getItem("incDt")}

											class=" tw-border tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="contDt" class="tw-block  tw-text-sm tw-font-medium  ">Contact Date</label>
										<input
											type="date"
											id="contDt"
											defaultValue={values.contDt}
											onChange={this.handleChange('contDt')}
											value={localStorage.getItem("contDt")}

											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="Incident Details" class="tw-block  tw-text-sm tw-font-medium  ">Incident Details</label>
										<input
											type="text"
											id="Incident Details"
											defaultValue={values.detail}
											onChange={this.handleChange('detail')}
											value={localStorage.getItem("detail")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>

								</div>

								<div class="tw-grid tw-gap-6 md:tw-grid-cols-3">
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="date" class="tw-block  tw-text-sm tw-font-medium  ">Election</label>
										<select
											type="text"
											id="date"
											defaultValue={values.election}
											onChange={this.handleChange('election')}
											value={localStorage.getItem("election")}

											class="tw-h-[57px] tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "
										>
											<option value=""></option>
											{elections}
										</select>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="affidavitBallot" class="tw-block  tw-text-sm tw-font-medium  ">Did you cast an affidavit ballot?</label>
										<select

											id="affidavitBallot"
											defaultValue={values.contDt}
											onChange={this.handleChange('affidavitBallot')}
											value={localStorage.getItem("affidavitBallot")}

											class="tw-h-[57px] tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										>
											<option value="">

											</option>
											<option id="Yes" value="Yes">Yes</option>
											<option id="No" value="No">No</option>
											<option id="N/A" value="N/A">N/A</option>
											<option id="Unknown" value="Unknown">Unknown</option>
										</select>
									</div>
									<div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
										<label for="interrelation Code" class="tw-block  tw-text-sm tw-font-medium  ">Interrelation Code</label>
										<input
											type="text"
											id="Interrelation Code"
											defaultValue={values.interrelation}
											onChange={this.handleChange('interrelation')}
											value={localStorage.getItem("interrelation")}
											class=" tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "

										/>
									</div>
								</div>
								<div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
									<Issue
										setIssue={this.handleChange('issueType')}
										setSubIssue={this.handleChange('issueSubtype')}
										id="Issue"
										index={0}
										page="Search"
										value={localStorage.getItem("issueType")}
										subvalue={localStorage.getItem("issueSubtype")}
										issueerrorvar={true}
										subissueerrorvar={true}
										admin={true}
									/>
								</div>
							</div>





							<hr />

							<Button
								id="search"
								onClick={this.handleSubmit}> Search
							</Button>


						</div>
						{/* End of Form Area */}

						<div>

							<hr />
							<div class=" tw-pb-6">
								<div className=" tw-shadow-xl tw-overflow-x-auto">

									<Box sx={{ height: 600, width: '100%', backgroundColor: 'white' }}>
										<DataGrid
											page={page}
											onPageChange={(newPage) => this.handlePageChange(newPage)}
											rows={JSON.parse(localStorage.getItem("Cache")) ? JSON.parse(localStorage.getItem("Cache")) : complaints}
											pageSize={pageSize}
											columns={columns}
											onPageSizeChange={(newPageSize) => this.handlePageSizeChange(newPageSize)} // Handle page size change

											getRowId={(row) => row.cmpltId}
											rowsPerPageOptions={[25, 50, 100]} // You can customize the available page sizes

											disableSelectionOnClick

										/>
									</Box>
								</div>
							</div>

						</div>




					</div>
				</main>

			);
		}
	}
}

export default searchForm;

const columns = [
	{
		field: 'cmpltId',
		headerName: 'ID',
		width: 90,
		sortable: false,
		disableClickEventBubbling: true,
		renderCell: (params) => {
			return (
				<Link to={`/detailview/?complaint=${params.row.cmpltId}`} style={{ textDecorationLine: 'underline', color: 'blue' }} >{params.row.cmpltId}</Link>
			);
		},
	},
	{
		field: 'status',
		headerName: 'Status',
		width: 450,
		editable: false,
		renderCell: (params) => {
			return (
				<StatusStoplight status={params.row.status} />
			);
		},
	},
	{
		field: 'assignedTo',
		headerName: 'Assigned To',
		width: 350,
		editable: false,
	},
	{
		field: 'lastName',
		headerName: 'Last Name',
		width: 200,
		editable: false,
	},
	{
		field: 'firstName',
		headerName: 'First Name',
		width: 200,
		editable: false,
	},
	{
		field: 'phoneNumber',
		headerName: 'Phone Number',
		width: 200,
		editable: false,
	},
	{
		field: 'emailAddr',
		headerName: 'Email Address',
		width: 275,
		editable: false,
	},
	{
		field: 'incDt',
		headerName: 'Incident Date',
		width: 200,
		editable: false,
		renderCell: (params) => {
			const incdate = new Date(params.row.incDt).toLocaleDateString();
			return incdate;
		}
	},
	{
		field: 'pollingPlace',
		headerName: 'Polling Place',
		width: 250,
		editable: false,
	},
	{
		field: 'county',
		headerName: 'County',
		width: 200,
		editable: false,
	},
	{
		field: 'election',
		headerName: 'Election Cycle',
		width: 200,
		editable: false,
	},
	{
		field: 'contDt',
		headerName: 'Contact Date',
		width: 200,
		editable: false,
		renderCell: (params) => {
			const condate = new Date(params.row.contDt).toLocaleDateString();
			//console.log(params.row.contDt)
			return condate;
		}
	},
	{
		field: 'crDt',
		headerName: 'Contact Time',
		width: 200,
		editable: false,
		renderCell: (params) => {
			const crTime = new Date(params.row.crDt).toLocaleTimeString();
			//console.log(params.row.crDt)
			return crTime;
		}

	},
	{
		field: 'affidavitBallot',
		headerName: 'Affidavit Ballot Submitted',
		width: 250,
		editable: false,
	},

	{

		field: 'issueTypes',
		headerName: 'Type of Complaint',
		width: 400,
		editable: false,
	},
	{
		field: 'subIssueTypes',
		headerName: 'Sub Issue',
		width: 400,
		editable: false,
	},
	{
		field: 'detail',
		headerName: 'Details',
		width: 200,
		editable: false,
	},
	{
		field: 'interrelationcode',
		headerName: 'Interrelation Code',
		width: 200,
		editable: false,
	},


]

const nyCounties = [
	{ label: 'Albany' },
	{ label: 'Allegany' },
	{ label: 'Bronx' },
	{ label: 'Broome' },
	{ label: 'Cattaraugus' },
	{ label: 'Cayuga' },
	{ label: 'Chautauqua' },
	{ label: 'Chemung' },
	{ label: 'Chenango' },
	{ label: 'Clinton' },
	{ label: 'Columbia' },
	{ label: 'Cortland' },
	{ label: 'Delaware' },
	{ label: 'Dutchess' },
	{ label: 'Erie' },
	{ label: 'Essex' },
	{ label: 'Franklin' },
	{ label: 'Fulton' },
	{ label: 'Genesee' },
	{ label: 'Greene' },
	{ label: 'Hamilton' },
	{ label: 'Herkimer' },
	{ label: 'Jefferson' },
	{ label: 'Kings' },
	{ label: 'Lewis' },
	{ label: 'Livingston' },
	{ label: 'Madison' },
	{ label: 'Monroe' },
	{ label: 'Montgomery' },
	{ label: 'Nassau' },
	{ label: 'New York' },
	{ label: 'Niagara' },
	{ label: 'Oneida' },
	{ label: 'Onondaga' },
	{ label: 'Ontario' },
	{ label: 'Orange' },
	{ label: 'Orleans' },
	{ label: 'Oswego' },
	{ label: 'Otsego' },
	{ label: 'Putnam' },
	{ label: 'Queens' },
	{ label: 'Rensselaer' },
	{ label: 'Richmond' },
	{ label: 'Rockland' },
	{ label: 'St. Lawrence' },
	{ label: 'Saratoga' },
	{ label: 'Schenectady' },
	{ label: 'Schoharie' },
	{ label: 'Schuyler' },
	{ label: 'Seneca' },
	{ label: 'Steuben' },
	{ label: 'Suffolk' },
	{ label: 'Sullivan' },
	{ label: 'Tioga' },
	{ label: 'Tompkins' },
	{ label: 'Ulster' },
	{ label: 'Warren' },
	{ label: 'Washington' },
	{ label: 'Wayne' },
	{ label: 'Westchester' },
	{ label: 'Wyoming' },
	{ label: 'Yates' }
]