import React from 'react';

const OAGTextField = (props) => {
  return (
    <input
      className="tw-border focus:tw-border-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-600 tw-px-3 tw-py-2 tw-text-black tw-placeholder-gray-500 tw-rounded-md tw-transition-all tw-duration-300 tw-ease-in-out"
      {...props}
    />
  );
};

export default OAGTextField;
