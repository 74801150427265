import React, { Component } from "react";
//import * as Mui from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
//import * as MuIcons from '@mui/icons-material';
import { Add, Remove, ArrowBack, ArrowForward } from '@mui/icons-material';
import Issues from './issues';
import Button from "../../../components/Button";


import {
  OAGButtonGroup,
  OAGInputLabel,
  OAGStyle,
  OAGTextField,
  OAGSelect,
  OAGTypography,
} from "../../../helpers/StyledPaper.js";
import { YellowGold } from '../../../helpers/OAGColors';
import NeutralDark from '../../../helpers/OAGColors';
import FileUploadPage from "../../../helpers/fileupload";

export class IncidentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      pollingplaceValid: true,
      incidentDetailsValid: true,
      incidentdateValid: true,
      contactDateValid: true,
      contactmethodValid: true,
      affidavitValid: true,
      issue1Valid: true,
      subissue1Valid: true,
      issue2Valid: true,
      subissue2Valid: true,
      issue3Valid: true,
      subissue3Valid: true,
      validInput: true,
      issuesValid: [],
      subIssuesValid: [],
      formValid: true,
      attachmentValid: true,
      errors: {},
    };
  }

  continue = () => {
    this.props.nextStep();
    window.scrollTo(0, 0);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
    window.scrollTo(0, 0);
  };

  add = (e) => {
    e.preventDefault();
    var maxnumberofissues = 3;

    if (this.props.values.issuesCount < maxnumberofissues) {
      this.props.setCount("add");
    }
  };

  remove = (e) => {
    e.preventDefault();
    var minnumberofissues = 1;

    if (this.props.values.issuesCount > minnumberofissues) {
      this.props.removeIssues();
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    let validationerrors = false;
    var newIssues = this.state.issuesValid.slice();
    var newSubIssues = this.state.subIssuesValid.slice();
    //console.log("newIssues: " + newIssues)
    if (
      this.props.values.pollingplace === "" ||
      this.props.values.pollingplace == null
    ) {
      if (!this.props.admin) {
        //console.log("Polling Place Bad ");
        validationerrors = true;
        this.setState({
          formValid: false,
          pollingplaceValid: false,
        });
      }
    } else {
      this.setState({ pollingplaceValid: true });

    };
    //console.log("validationerrors" +  validationerrors)
    if (
      this.props.values.incidentdetails === "" ||
      this.props.values.incidentdetails == null
    ) {
      if (!this.props.admin) {
        //console.log("Incident Details");
        validationerrors = true;
        this.setState({
          formValid: false,
          incidentDetailsValid: false,
        });
      }
    } else {
      this.setState({ incidentDetailsValid: true });
    };
    //console.log("heehe" +  validationerrors)
    if (
      this.props.values.dateofincident === "" ||
      this.props.values.dateofincident == null ||
      this.props.checkDate(this.props.values.dateofincident) == false
    ) {
      //console.log("Incident Date Bad ");
      //console.log(this.props.values.dateofincident);
      validationerrors = true;
      this.setState({
        formValid: false,
        incidentdateValid: false,
      });
    } else {
      //console.log(this.props.values.dateofincident);
      this.setState({ incidentdateValid: true });
    }
    //console.log("test" +  validationerrors)

    if (
      this.props.values.contactdate === "" ||
      this.props.values.contactdate == null ||
      this.props.checkDate(this.props.values.contactdate) == false
    ) {
      //console.log("help: " +  this.props.values.contactdate)

      ////console.log("Incident Date Bad ");
      validationerrors = true;
      this.setState({
        formValid: false,
        contactDateValid: false,
      });
    } else {
      this.setState({ contactDateValid: true });
    };
    //console.log("skeeyee: " +  validationerrors)

    if (
      this.props.values.affidavitcast === "" ||
      this.props.values.affidavitcast == null
    ) {
      if (!this.props.admin) {
        //console.log("Incident Date Bad ");
        validationerrors = true;
        this.setState({
          formValid: false,
          affidavitValid: false,
        });
      }
    } else {
      this.setState({ affidavitValid: true });
    };

    //Check if the Contact Method is filled in AND the Admin Prop is True
    if (
      this.props.values.contactmethod === "" ||
      this.props.values.contactmethod == null
    ) {
      if (this.props.admin) {
        //console.log("Contact Method Bad ");
        validationerrors = true;
        this.setState({
          formValid: false,
          contactmethodValid: false,
        });
      } else {
        this.setState({ contactmethodValid: true });
      }
    }




    //************************************************************** */
    //*******8/26/22 Update: Nancy wants Issues to be Optional for Public****** */
    // if (this.props.admin) {
    //   //console.log("We have this many issues");
    //   //console.log(this.props.values.issuesCount);
    //   switch (this.props.values.issuesCount) {
    //     case 1:
    //       console.log("issue: " + this.props.values.issues[0]);

    //       if (
    //         this.props.values.issues[0] === "" ||
    //         this.props.values.issues[0] == null
    //       ) {
    //         console.log("Issue1 Bad ");
    //         validationerrors = true;
    //         newIssues.push("issue1")
    //         this.setState({
    //           formValid: false,
    //           issuesValid: newIssues,
    //           issue1Valid: false,
    //         });
    //       } else {
    //         var newIssues = this.state.issuesValid.splice(this.state.issuesValid.indexOf("issues1"), 1)
    //         this.setState({ issuesValid: newIssues });
    //         this.setState({ issue1Valid: true });
    //       }
    //       console.log("sub: " + this.props.values.subIssues);
    //       if (

    //         this.props.values.subIssues[0] === "" ||
    //         this.props.values.subIssues[0] == null
    //       ) {
    //         console.log("SubIssue1 Bad ");
    //         validationerrors = true;
    //         newSubIssues.push("subIssue1")
    //         this.setState({
    //           formValid: false,
    //           subIssuesValid: newSubIssues,
    //           subissue1Valid: false,
    //         });
    //       } else {
    //         var newSubIssues = this.state.subIssuesValid.splice(this.state.subIssuesValid.indexOf("subIssue1"), 1)
    //         this.setState({ subIssuesValid: newSubIssues });
    //         this.setState({ subissue1Valid: true });

    //       }
    //       break;

    //     case 2:
    //       console.log("Issue1:" + this.props.values.issues[1])
    //       if (
    //         this.props.values.issues[0] === "" ||
    //         this.props.values.issues[0] == null
    //       ) {
    //         //console.log("Issue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           issue1Valid: false,
    //         });
    //       } else {
    //         this.setState({ issue1Valid: true });
    //       }

    //       if (
    //         this.props.values.subIssues[0] === "" ||
    //         this.props.values.subIssues[0] == null
    //       ) {
    //         //console.log("SubIssue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           subissue1Valid: false,
    //         });
    //       } else {
    //         this.setState({ subissue1Valid: true });
    //       }

    //       if (
    //         this.props.values.issues[1] === "" ||
    //         this.props.values.issues[1] == null
    //       ) {
    //         //console.log("Issue Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           issue2Valid: false,
    //         });
    //       } else {
    //         this.setState({ issue2Valid: true });
    //       }

    //       if (
    //         this.props.values.subIssues[1] === "" ||
    //         this.props.values.subIssues[1] == null
    //       ) {
    //         //console.log("SubIssue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           subissue2Valid: false,
    //         });
    //       } else {
    //         this.setState({ subissue2Valid: true });
    //       }
    //       break;

    //     case 3:
    //       if (
    //         this.props.values.issues[0] === "" ||
    //         this.props.values.issues[0] == null
    //       ) {
    //         //console.log("Issue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           issue1Valid: false,
    //         });
    //       } else {
    //         this.setState({ issue1Valid: true });
    //       }

    //       if (
    //         this.props.values.subIssues[0] === "" ||
    //         this.props.values.subIssues[0] == null
    //       ) {
    //         //console.log("SubIssue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           subissue1Valid: false,
    //         });
    //       } else {
    //         this.setState({ subissue1Valid: true });
    //       }

    //       if (
    //         this.props.values.issues[1] === "" ||
    //         this.props.values.issues[1] == null
    //       ) {
    //         //console.log("Issue Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           issue2Valid: false,
    //         });
    //       } else {
    //         this.setState({ issue2Valid: true });
    //       }

    //       if (
    //         this.props.values.subIssues[1] === "" ||
    //         this.props.values.subIssues[1] == null
    //       ) {
    //         //console.log("SubIssue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           subissue2Valid: false,
    //         });
    //       } else {
    //         this.setState({ subissue2Valid: true });
    //       }

    //       if (
    //         this.props.values.issues[2] === "" ||
    //         this.props.values.issues[2] == null
    //       ) {
    //         //console.log("Issue Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           issue3Valid: false,
    //         });
    //       } else {
    //         this.setState({ issue3Valid: true });
    //       }

    //       if (
    //         this.props.values.subIssues[2] === "" ||
    //         this.props.values.subIssues[2] == null
    //       ) {
    //         //console.log("SubIssue1 Bad ");
    //         validationerrors = true;
    //         this.setState({
    //           formValid: false,
    //           subissue3Valid: false,
    //         });
    //       } else {
    //         this.setState({ subissue3Valid: true });
    //       }
    //       break;
    //     //*********************************************************** */
    //     default:
    //     // do nothing
    //   }
    // }else{

    switch (this.props.values.issuesCount) {
      case 1:
        // console.log("issue:" + this.props.values.issues)
        //console.log("issue:" + this.props.values.issues[0])
        //console.log("sub:" + this.props.values.subIssues[0])
        // if(this.props.values.issues)
        if (this.props.values.issues[0] == "") {
          var newIssues = this.state.issuesValid.splice(this.state.issuesValid.indexOf("issues1"), 1)
          this.setState({ issuesValid: newIssues });
          var newSubIssues = this.state.subIssuesValid.splice(this.state.subIssuesValid.indexOf("subIssue1"), 1)
          this.setState({ subIssuesValid: newSubIssues });
          this.setState({ issue1Valid: true })
          this.setState({ subissue1Valid: true })
        } else {
          if (
            (this.props.values.issues[0] != null && this.props.values.subIssues[0] == null) || (this.props.values.issues[0] != null && this.props.values.subIssues[0] == "")

          ) {
            console.log("Issue1 Bad ");
            validationerrors = true;
            newIssues.push("issue1")
            this.setState({
              formValid: false,
              issuesValid: newIssues,
              issue1Valid: false,
              subissue1Valid: false
            });
          } else {
            var newIssues = this.state.issuesValid.splice(this.state.issuesValid.indexOf("issues1"), 1)
            this.setState({ issuesValid: newIssues });
            var newSubIssues = this.state.subIssuesValid.splice(this.state.subIssuesValid.indexOf("subIssue1"), 1)
            this.setState({ subIssuesValid: newSubIssues });
            this.setState({ issue1Valid: true })
            this.setState({ subissue1Valid: true })


          }

        }

        //console.log("issue1Valid: " + this.state.issue1Valid)
        // console.log("subissue1Valid: " + this.state.subissue1Valid)

        break;

      case 2:
        //console.log("sub:" + this.props.values.subIssues[0])
        //console.log("issue:" + this.props.values.issues)
        if (this.props.values.issues[0] == "") {
          
          
          this.setState({ issue1Valid: true })
          this.setState({ subissue1Valid: true })
          
        } else {
          if (
            (this.props.values.issues[0] != null && this.props.values.subIssues[0] == null) || (this.props.values.issues[0] != null && this.props.values.subIssues[0] == "")

          ) {
            //console.log("Issue1 Bad ");
            validationerrors = true;
            this.setState({
              formValid: false,
              issue1Valid: false,
              subissue1Valid: false
            });
          } else {
            this.setState({ issue1Valid: true })
            this.setState({ subissue1Valid: true })

          }

        }

        if (this.props.values.issues[1] == "") {
          this.setState({ issue2Valid: true })
          this.setState({ subissue2Valid: true })
         // console.log("help");
        } else {
          if (
            (this.props.values.issues[1] != null && this.props.values.subIssues[1] == null) || (this.props.values.issues[1] != null && this.props.values.subIssues[1] == "")|| (this.props.values.issues[1] != null && this.props.values.subIssues[0] == "")

          ) {
            //console.log("Issue2 Bad ");
            validationerrors = true;
            this.setState({
              formValid: false,
              issue2Valid: false,
              subissue2Valid: false
            });
          } else {
            this.setState({ issue2Valid: true })
            this.setState({ subissue2Valid: true })

          }

        }
        break;

      case 3:
        if (this.props.values.issues[0] == "") {
          this.setState({ issue1Valid: true })
          this.setState({ subissue1Valid: true })
        } else {
          if (
            (this.props.values.issues[0] != null && this.props.values.subIssues[0] == null) || (this.props.values.issues[0] != null && this.props.values.subIssues[0] == "")

          ) {
            //console.log("Issue1 Bad ");
            validationerrors = true;
            this.setState({
              formValid: false,
              issue1Valid: false,
              subissue1Valid: false
            });
          } else {
            this.setState({ issue1Valid: true })
            this.setState({ subissue1Valid: true })

          }

        }

        if (this.props.values.issues[1] == "") {
          this.setState({ issue2Valid: true })
          this.setState({ subissue2Valid: true })
        } else {
          if (
            (this.props.values.issues[1] != null && this.props.values.subIssues[1] == null) || (this.props.values.issues[1] != null && this.props.values.subIssues[1] == "") || (this.props.values.issues[1] != null && this.props.values.subIssues[0] == "")

          ) {
            //console.log("Issue1 Bad ");
            validationerrors = true;
            this.setState({
              formValid: false,
              issue2Valid: false,
              subissue2Valid: false
            });
          } else {
            this.setState({ issue2Valid: true })
            this.setState({ subissue2Valid: true })

          }

        }
        if (this.props.values.issues[2] == "") {
          this.setState({ issue3Valid: true })
          this.setState({ subissue3Valid: true })
        } else {
          if (
            (this.props.values.issues[2] != null && this.props.values.subIssues[2] == null) || (this.props.values.issues[2] != null && this.props.values.subIssues[2] == "")|| (this.props.values.issues[2] != null && this.props.values.subIssues[1] == "") || (this.props.values.issues[2] != null && this.props.values.subIssues[0] == "")

          ) {
            //console.log("Issue1 Bad ");
            validationerrors = true;
            this.setState({
              formValid: false,
              issue3Valid: false,
              subissue3Valid: false
            });
          } else {
            this.setState({ issue3Valid: true })
            this.setState({ subissue3Valid: true })

          }

        }
        break;
      //*********************************************************** */
      default:
      // do nothing
    }




    if (!validationerrors) {
      //console.log("Everything is good");
      this.setState({
        pollingplaceValid: true,
        contactDateValid: true,
        incidentDetailsValid: true,
        incidentdateValid: true,
        issuesValid: [],
        subIssuesValid: [],
        formValid: true,
      });

      this.continue();
    } else {
      // console.log(this.state.pollingplaceValid);
      // console.log(this.state.contactDateValid);
      // console.log(this.state.incidentDetailsValid);
      // console.log(this.state.incidentdateValid);
      // console.log(this.state.issuesValid);
      // console.log(this.state.subIssuesValid);
      // console.log(this.state.formValid);
      // console.log(validationerrors);


      //console.log("Everything is shit");

    }

  };


  render() {
    const { values, handleChange, setIssue, setSubIssue } = this.props;
    let removebtn = true;
    const { errors } = this.state;
    let removebtnid = "DisableRemoved";
    let addbtnid = "Add";
    let addbtn = false;

    var tdy = new Date();
    var localdate = this.props.toISOLocal(tdy);
    var defaultdate = localdate.substr(0, 10);

    let issuearray;
    if (values.issuesCount === 1) {

      issuearray = (
        <Issues
          setIssue={setIssue}
          setSubIssue={setSubIssue}
          admin={this.props.admin}
          index={0}
          value={values.issues[0] === undefined ? "" : values.issues[0]}
          subvalue={values.subIssues[0] === undefined ? "" : values.subIssues[0]}
          issueerrorvar={this.state.issue1Valid}
          subissueerrorvar={this.state.subissue1Valid}
        />
      );
    } else if (values.issuesCount === 2) {
      removebtn = false;
      addbtn = false;
      removebtnid = "Removed";
      addbtnid = "Add";
      issuearray = (
        <div>
          <Issues
            setIssue={setIssue}
            setSubIssue={setSubIssue}
            admin={this.props.admin}
            index={0}
            value={values.issues[0] === undefined ? "" : values.issues[0]}
            subvalue={values.subIssues[0] === undefined ? "" : values.subIssues[0]}
            issueerrorvar={this.state.issue1Valid}
            subissueerrorvar={this.state.subissue1Valid}
          />
          <br />
          <Issues
            setIssue={setIssue}
            index={1}
            admin={this.props.admin}
            setSubIssue={setSubIssue}
            value={values.issues[1] === undefined ? "" : values.issues[1]}
            subvalue={values.subIssues[1] === undefined ? "" : values.subIssues[1]}
            issueerrorvar={this.state.issue2Valid}
            subissueerrorvar={this.state.subissue2Valid}
          />
        </div>
      );
    } else if (values.issuesCount === 3) {
      removebtn = false;
      addbtn = true;
      removebtnid = "Removed";
      addbtnid = "disabled";
      issuearray = (
        <div>
          <Issues
            setIssue={setIssue}
            setSubIssue={setSubIssue}
            value={values.issues[0] === undefined ? "" : values.issues[0]}
            admin={this.props.admin}
            index={0}
            subvalue={values.subIssues[0] === undefined ? "" : values.subIssues[0]}
            issueerrorvar={this.state.issue1Valid}
            subissueerrorvar={this.state.subissue1Valid}
          />
          <br />
          <Issues
            setIssue={setIssue}
            setSubIssue={setSubIssue}
            admin={this.props.admin}
            index={1}
            value={values.issues[1] === undefined ? "" : values.issues[1]}
            subvalue={values.subIssues[1] === undefined ? "" : values.subIssues[1]}
            issueerrorvar={this.state.issue2Valid}
            subissueerrorvar={this.state.subissue2Valid}
          />
          <br />
          <Issues
            setIssue={setIssue}
            setSubIssue={setSubIssue}
            admin={this.props.admin}
            index={2}
            value={values.issues[2] === undefined ? "" : values.issues[2]}
            subvalue={values.subIssues[2] === undefined ? "" : values.subIssues[2]}
            issueerrorvar={this.state.issue3Valid}
            subissueerrorvar={this.state.subissue3Valid}
          />
        </div>
      );
    }

    let adminfields;
    if (this.props.admin) {
      adminfields =
        <form>
          <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="volunteer" class="tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-dark-gray">Volunteer</label>
              <input
                type="text"
                id="volunteer"
                defaultValue={values.volunteer}
                disabled
                class="tw-h-[63px] tw-bg-light-gray tw-border tw-placeholder-gray tw-text-dark-gray tw-text-sm tw-rounded-lg  tw-block tw-w-full tw-p-2.5 " />

            </div>
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="contact-method-select" class={this.state.contactmethodValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-light-red"}>Contact Method *</label>
              <select
                id="contact-method-select"
                class="tw-h-[63px]  tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 "
                defaultValue={values.contactmethod}
                onChange={handleChange('contactmethod')}
              >
                <option value="Phone Call">Phone Call</option>
                <option value="Email / Web Form">Email / Web Form</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">

            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="contactdate" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Contact Date</label>
              <input
                type="date"
                max={defaultdate}
                id="contactdate"
                defaultValue={values.contactdate}
                onChange={handleChange('contactdate')}
                class=" tw-border   tw-placeholder-gray tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 "
              />
            </div>
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="interrelationcode" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Interrelation Code</label>
              <input
                type="text"
                id="interrelationcode"
                placeholder="Enter a Interrelation Code if applicable"
                defaultValue={values.interrelationcode}
                onChange={handleChange('interrelationcode')}
                class=" tw-border  tw-placeholder-gray  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 "
                maxLength={250}

              />
            </div>
          </div>


        </form>;
    } else {
      adminfields = '';

    };

    return (
      <main class="tw-px-5 tw-py-5">
        <div class="tw-px-5 tw-rounded-xl tw-bg-light-gray">
          <hr />
          <h1 class=" tw-text-sm tw-m-0 tw-font-blue tw-text-blue  ">Note: Asterisks (*) indicate required fields</h1>
          {/* First Name and Last Name */}


          {adminfields}
          <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="pollingplace" class={this.state.pollingplaceValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium  tw-text-light-red"}>{this.props.admin ? "Polling Place" : "Polling Place *"}</label>
              <input
                type="text"
                id="pollingplace"
                defaultValue={values.pollingplace}
                onChange={handleChange('pollingplace')}
                placeholder="Enter Your Polling Place"
                class={this.state.pollingplaceValid ? " tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 "}
                required={!this.props.admin}
                maxLength={1000}
              />
              <label for="pollingplace" class={this.state.pollingplaceValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-light-red"}>Polling Place is required</label>

            </div>
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="pollingaddr" class="tw-block tw-mb-2 tw-text-md tw-font-medium ">Polling Place Address or Address Where Incident Occurred</label>
              <input type="text"
                id="pollingaddr"
                class=" tw-border   tw-text-lg tw-rounded-lg tw-placeholder-gray   tw-block tw-w-full tw-p-2.5 "
                placeholder="Polling Place Address or Address Where Incident Occurred"
                defaultValue={values.pollingaddr} local
                onChange={handleChange('pollingaddr')}
                maxLength={400}

              />
            </div>
          </div>
          {/* Address Block - Street Info */}
          <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="dateofincident" class={this.state.incidentdateValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium  tw-text-light-red"}>{this.props.admin ? "Date of Incident" : "Date of Incident *"}</label>
              <input type="date"
                id="dateofincident"
                class={this.state.incidentdateValid ? " tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 "}
                placeholder="Enter your mailing Address"
                defaultValue={values.dateofincident}
                onChange={handleChange('dateofincident')}
                required={!this.props.admin}
                max={defaultdate}
              />
              <label for="dateofincident" class={this.state.incidentdateValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Date of Incident is required</label>

            </div>
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="affidavitcast" class={this.state.affidavitValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium  tw-text-light-red"}>{this.props.admin ? "Did you cast an affidavit ballot?" : "Did you cast an affidavit ballot? *"}</label>
              <select
                id="affidavitcast"
                class={this.state.affidavitValid ? "tw-h-[63px]  tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-h-[63px] tw-p-2.5 "}

                defaultValue={values.affidavitcast}
                onChange={handleChange('affidavitcast')}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="N/A">N/A</option>
                <option value="Unknown">Unknown</option>
              </select>
              <label for="affidavitcast" class={this.state.affidavitValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

            </div>
          </div>

          {/* Business Name Organization Name */}
          <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
            <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
              <label for="incidentdetails" class={this.state.incidentDetailsValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium  tw-text-light-red"}>{this.props.admin ? "What happened?" : "What happened? *"}</label>
              <textarea type="text"
                id="incidentdetails"
                class={this.state.incidentDetailsValid ? " tw-border tw-placeholder-gray   tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 " : " tw-border tw-placeholder-light-red tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5 "}
                placeholder="Please provide details about the incident"
                defaultValue={values.incidentdetails}
                onChange={handleChange('incidentdetails')}
                rows="3"
                required
                maxLength={4000}

              />
              <label for="incidentdetails" class={this.state.incidentDetailsValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Incident Details are required</label>

            </div>
          </div>
          {issuearray}

          <div class={addbtnid == "Add" ? "tw-flex" : ""}>


            <div class="tw-mr-2">
              <Button
                id={addbtnid}
                disabled={addbtn}
                sx={{ marginRight: "auto", backgroundColor: "#00A676" }}

                size="small"
                variant="contained"
                onClick={this.add}

              >
                Add Another Issue
              </Button>

            </div>
            <Button
              id={removebtnid}
              disabled={removebtn}
              size="small"
              variant="contained"
              onClick={this.remove}

            >
              {" "}
              Remove Issue
            </Button>
          </div>



          <FileUploadPage values={values}
            attachmentValid={this.state.attachmentValid}
            handleAttachment1Change={this.props.handleattachment1FileChange}
            handleAttachment2Change={this.props.handleattachment2FileChange} />





          <div class="tw-py-5" align="center">
            <Button disabled id="back" onClick={this.back}>Back</Button>
            &nbsp;&nbsp;&nbsp;
            <Button id="next" onClick={this.validateForm} >Next</Button>
          </div>
        </div>
      </main>
    );
  }

}

export default IncidentDetails;
