import React, { Component } from 'react';
//import * as Mui from '@mui/material';
import { FormHelperText, FormControl, TextField, Select, MenuItem, } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import BoeExtraQuestions from './boextraquestions.js';
import { OAGButtonGroup, OAGStyle, OAGTypography } from '../../../helpers/StyledPaper.js';
import Button from '../../../components/Button.js';

export class BoeQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            issues: [],
            issuecount: 1,
            pollworkerValid: true,
            followupreqdValid: true,
            votercontactlocalboeValid: true,
            independentescalationValid: true,
            boecontactedValid: true,
            boecontactValid: true,
            issuerslvedValid: true,
            //affidavitcastValid: true,
            formValid: true
        };
    }
    continue = () => {
        //e.preventDefault();
        this.props.nextStep();
        window.scrollTo(0, 0);
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
        window.scrollTo(0, 0);;
    };

    validateForm = e => {
        e.preventDefault();
        let validationerrors = false;
        //console.log("made it into validate");

        const requiredCounties = new Set(['New York', 'Kings', 'Queens', 'Bronx', 'Richmond'])
        const isCountyInSet = requiredCounties.has(this.props.values.county)
        // BOE Questions are only required when the County is New York
        if (isCountyInSet) {
            //console.log("we are in new york or one of the five buroughs");
            //Check Poll Worker Question
            if (this.props.values.pollworker === '' || this.props.values.pollworker == null) {
                //console.log("Polling Worker Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    pollworkerValid: false
                })
            } else {
                this.setState({ pollworkerValid: true })
            };

            //Check followupreqd Question
            // if(this.props.values.followupreqd === '' || this.props.values.followupreqd == null ) {
            //     console.log("Follow Up Required Bad ");
            //     validationerrors = true;
            //     this.setState({
            //     formValid: false,
            //     followupreqdValid: false
            //     })
            // } else { this.setState({ followupreqdValid: true }) };

            //Check votercontactlocalboe Question
            if (this.props.values.votercontactlocalboe === '' || this.props.values.votercontactlocalboe == null) {
                //console.log("Voter Contact BOE Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    votercontactlocalboeValid: false
                })
            } else { this.setState({ votercontactlocalboeValid: true }) };

            //Check independentescalation Question
            if (this.props.values.independentescalation === '' || this.props.values.independentescalation == null) {
                //console.log("Independent Escalation Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    independentescalationValid: false
                })
            } else { this.setState({ independentescalationValid: true }) };

            //Check boecontacted Question
            if (this.props.values.boecontacted === '' || this.props.values.boecontacted == null) {
                //console.log("BOE Contacted Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    boecontactedValid: false
                })
            } else { this.setState({ boecontactedValid: true }) };

            //Check boecontact details
            //console.log("boecontact: " + this.props.values.boecontact);

            if (this.props.values.votercontactlocalboe === "Yes") {
                if (this.props.values.boecontact === '' || this.props.values.boecontact == null) {
                    //console.log("BOE Contacted Bad ");
                    validationerrors = true;
                    this.setState({
                        formValid: false,
                        boecontactValid: false
                    })
                } else { this.setState({ boecontactValid: true }) };
            }
            //console.log("validationerrors: " + validationerrors);

            //Check issuerslved Question
            if (this.props.values.issuerslved === '' || this.props.values.issuerslved == null) {
                //console.log("Issue Resolved Bad ");
                validationerrors = true;
                this.setState({
                    formValid: false,
                    issuerslvedValid: false
                })
            } else { this.setState({ issuerslvedValid: true }) };
            //console.log("validationerrors: " + validationerrors);
            //Check affidavitcast Question
            // if(this.props.values.affidavitcast === '' || this.props.values.affidavitcast == null ) {
            //     console.log("Affidavit Cast Bad ");
            //     validationerrors = true;
            //     this.setState({
            //     formValid: false,
            //     affidavitcastValid: false
            //     })
            // } else { this.setState({ affidavitcastValid: true }) };
            if (!validationerrors) {
                //console.log("Everything is good");
                this.setState({
                    pollworkerValid: true,
                    followupreqdValid: true,
                    independentescalationValid: true,
                    boecontactedValid: true,
                    boecontactValid: true,
                    issuerslvedValid: true,
                    votercontactlocalboeValid: true,
                    formValid: true
                })

                this.continue();

            }
        } else {

            //Our County is not New York, so everything is good!
            if (!validationerrors) {
                //console.log("Everything is good");
                this.setState({
                    pollworkerValid: true,
                    followupreqdValid: true,
                    independentescalationValid: true,
                    boecontactedValid: true,
                    boecontactValid: true,
                    issuerslvedValid: true,
                    votercontactlocalboeValid: true,
                    formValid: true
                })

                this.continue();

            }
        }

    }

    render() {
        const { values, handleChange, admin } = this.props;
        const { errors } = this.state;
        let adminfields;

        if (admin) {
            adminfields =
                <div>
                    <div class="tw-callout tw-callout--secondary tw-text-blue tw-text-base tw-text-center">
                        <hr /><div><p>Office of the Attorney General Section</p></div>
                        <hr />

                    </div>
                    <div >
                        <BoeExtraQuestions values={values} handleChange={handleChange} />
                    </div>
                </div>
        } else {
            adminfields = '';
        };

        let banner;
        let selecthelper;
        const requiredCounties = new Set(['New York', 'Kings', 'Queens', 'Bronx', 'Richmond'])
        const isCountyInSet = requiredCounties.has(values.county)
        if (isCountyInSet) {
            banner = <h3 align='center' > Are you (the complainant) a NYC voter? If yes, then answer these questions.</h3>
            selecthelper = <FormHelperText>Required</FormHelperText>
        }
        else {
            banner = '';
        }

        let ifboecontacted;
        if (values.votercontactlocalboe === "Yes") {
            ifboecontacted =
                <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                    <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                        <label for="boecontact" class="tw-block tw-mb-2 tw-text-md tw-font-medium  ">If yes, provide details</label>
                        <input

                            id="boecontact"
                            defaultValue={values.boecontact}
                            onChange={handleChange('boecontact')}

                            class={this.state.boecontactValid ? " tw-border    tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    "}
                            maxLength={1000}

                        />
                        <label for="boecontact" class={this.state.boecontactValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

                    </div>
                </div>

        } else {
            //console.log(values.votercontactlocalboe)
            ifboecontacted = ""
        };

        return (
            <main class="tw-px-5 tw-py-5">

                <div class="tw-px-5 tw-rounded-xl tw-bg-light-gray">
                    <hr />
                    {banner}
                    <h1 class=" tw-text-sm tw-m-0 tw-font-blue tw-text-blue  ">Note: Asterisks (*) indicate required fields</h1>
                    {/* First Name and Last Name */}


                    <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="boecontacted" class={this.state.boecontactedValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-light-red"}>Did a poll worker solve your problem or contact the local Board of Elections about it?  {(isCountyInSet && "*")}</label>
                            <select

                                id="boecontacted"
                                defaultValue={values.boecontacted}
                                onChange={handleChange('boecontacted')}

                                class={this.state.boecontactedValid ? "tw-h-[63px]  tw-border    tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-h-[63px] tw-w-full tw-p-2.5    "}

                            >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                            <label for="boecontacted" class={this.state.boecontactedValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

                        </div>
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="pollworker" class={this.state.pollworkerValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-light-red"}>Did a poll worker tell you what you should do next to solve your problem or follow up? {(isCountyInSet && "*")}</label>
                            <select

                                id="pollworker"
                                defaultValue={values.pollworker}
                                onChange={handleChange('pollworker')}

                                class={this.state.pollworkerValid ? "tw-h-[63px]  tw-border    tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-h-[63px] tw-w-full tw-p-2.5    "}

                            >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                            <label for="pollworker" class={this.state.pollworkerValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

                        </div>
                    </div>
                    {/* Address Block - Street Info */}
                    <div class="tw-grid tw-gap-6 md:tw-grid-cols-2">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="independentescalation" class={this.state.independentescalationValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-light-red"}>Did you know how to contact the Board of Elections about your problem?  {(isCountyInSet && "*")}</label>
                            <select

                                id="independentescalation"
                                defaultValue={values.independentescalation}
                                onChange={handleChange('independentescalation')}

                                class={this.state.independentescalationValid ? "tw-h-[63px]  tw-border    tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-h-[63px] tw-p-2.5    "}

                            >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                            <label for="independentexcalation" class={this.state.independentescalationValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

                        </div>
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="issuerslved" class={this.state.issuerslvedValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-light-red"}>Did your local Board of Elections solve your problem?  {(isCountyInSet && "*")}</label>
                            <select

                                id="issuerslved"
                                defaultValue={values.issuerslved}
                                onChange={handleChange('issuerslved')}

                                class={this.state.issuerslvedValid ? "tw-h-[63px]  tw-border    tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-h-[63px] tw-w-full tw-p-2.5    "}

                            >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                            <label for="issuerslved" class={this.state.issuerslvedValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

                        </div>


                    </div>

                    {/* Business Name Organization Name */}
                    <div class="tw-grid tw-gap-6 md:tw-grid-cols-1">
                        <div class="js-form-item form-item form-item-type--textfield js-form-type-textfield form-item-name js-form-item-name">
                            <label for="votercontactlocalboe" class={this.state.votercontactlocalboeValid ? "tw-block tw-mb-2 tw-text-md tw-font-medium " : "tw-block tw-mb-2 tw-text-md tw-font-medium tw-text-light-red"}>Did you contact the local Board of Elections office for help?  {(isCountyInSet && "*")}</label>
                            <select

                                id="votercontactlocalboe"
                                defaultValue={values.votercontactlocalboe}
                                onChange={handleChange('votercontactlocalboe')}

                                class={this.state.votercontactlocalboeValid ? "tw-h-[63px]  tw-border    tw-text-lg tw-rounded-lg   tw-block tw-w-full tw-p-2.5    " : " tw-border  tw-border-light-red  tw-text-lg tw-rounded-lg   tw-block tw-h-[63px] tw-w-full tw-p-2.5    "}

                            >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                            <label for="votercontactlocalboe" class={this.state.votercontactlocalboeValid ? "tw-hidden" : "tw-block tw-mb-2 tw-text-sm tw-font-medium   tw-text-light-red"}>Selection is required</label>

                            {ifboecontacted}
                        </div>
                        {adminfields}
                    </div>





                    <div class="tw-py-5" align="center">
                        <Button disabled id="back" onClick={this.back}>Back</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button id="next" onClick={this.validateForm} >Next</Button>
                    </div>
                </div>
            </main>

        );
    }
}

export default BoeQuestions;