import React from "react";
import { useMsal } from "@azure/msal-react";

import Button from '@mui/material/Button';

// function handleLogin(instance) {
//     instance.loginRedirect(loginRequest).catch(e => {
//         console.error(e);
//     });
// }

function signOutClickHandler(instance) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(),
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  }
  
//   // SignOutButton Component returns a button that invokes a popup logout when clicked
//   function SignOutButton() {
//     // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
//     const { instance } = useMsal();
  
//     return (
//       // <li className="nav-item header-search"><Link to="/" onClick={signOutClickHandler(instance)} className="dropdown-item">Sign Out</Link></li>
//           <button type="button" class="btn btn-primary btn-block btn-sm" onClick={() => signOutClickHandler(instance)}>Sign Out</button>
//     );
//   }


/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
      <div >
        <button     onClick={() => signOutClickHandler(instance)}>Sign Out</button>
        </div>
    );
}