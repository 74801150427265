import React, { Component } from 'react'
import StatusStoplight from "../../helpers/statusStoplight.js";
import { DataGrid} from '@mui/x-data-grid';
import { loginRequest, protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import CustomPagination from '../../components/customPagination.js';
class volunteerCases extends React.Component {
	state = {
		error: null,
		isLoaded: false,
		searched: false,
		complaints: [],
		page: 0,
		pageSize:25,

	}
	componentDidMount() {
		//console.log("Volunteer Cases is mounted");
		this.fetchcases();
	}

	getAPIToken = async () => {
		const account = msalInstance.getActiveAccount();

		if (!account) {
			console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
		}

		const response = await msalInstance.acquireTokenSilent({
			account: account,
			...loginRequest
			//scopes: [ "api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user" ]
		});
		//console.log(response);
		return response.accessToken;
	}

	fetchcases = async () => {
		//console.log("Should be getting cases");
		const token = await this.getAPIToken();
		const url = process.env.REACT_APP_API_URL + "Search/GetComplaintsForVolunteer?email=" + this.props.useremail;

		try {
			axios({
				method: "get",
				url: url,
				headers: { Authorization: `Bearer ${token}`, },
			}).then(res => {
				const complaints = res.data;
				this.setState({ complaints });
				this.setState({ searched: true });
			});
		} catch (err) {
			//console.log(err);
		}

	};
	handlePageSizeChange = (newPageSize) => {
		this.setState({ pageSize: newPageSize });
	};
	handlePageChange = (newPage) => {
		this.setState({ page: newPage }); // Update the 'page' state with the new page number
	  };
	render() {

		const { complaints, page, pageSize } = this.state;

		return (
			<div>
				<div className="tw-px-5">
					<p className="tw-text-lg lg:tw-text-xl tw-font-bold">Here are your current cases:</p>
				</div>
				<div className="tw-bg-dot-pattern">
					<div class="tw-px-5">
						<div className=" tw-shadow-xl tw-overflow-x-auto">

							<Box sx={{ height: 400, width: '100%', backgroundColor:"white" }}>
								<DataGrid
								  page={page}
								  onPageChange={(newPage) => this.handlePageChange(newPage)}
								  rows={complaints}
								  pageSize={pageSize}
								  columns={columns}
								  onPageSizeChange={(newPageSize) => this.handlePageSizeChange(newPageSize)} // Handle page size change

								  getRowId={(row) => row.cmpltId}
								  rowsPerPageOptions={[25, 50, 100]} // You can customize the available page sizes

								  disableSelectionOnClick
								/>
							</Box>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default volunteerCases;

const columns = [
	{
		field: 'cmpltId',
		headerName: 'ID',
		width: 90,
		sortable: false,
		disableClickEventBubbling: true,

		renderCell: (params) => {
			// const onClick = (e) => {
			//   const currentRow = params.row;
			//   return alert(JSON.stringify(currentRow, null, 4));
			// };

			return (
				<Link to={`/detailview/?complaint=${params.row.cmpltId}`} style={{ textDecorationLine: 'underline', color: 'blue' }} >{params.row.cmpltId}</Link>
				// <Link to={`/detailview/?complaint=${params.row.cmpltId}`} target="_blank" rel="noopener noreferrer" style={{ textDecorationLine: 'underline', color: 'blue' }} >{params.row.cmpltId}</Link>
			);
		},
	},
	// { field: 'cmpltId', headerName: 'ID', width: 90 },
	{
		field: 'status',
		headerName: 'Status',
		width: 450,
		editable: false,
		renderCell: (params) => {
			// const onClick = (e) => {
			//   const currentRow = params.row;
			//   return alert(JSON.stringify(currentRow, null, 4));
			// };   params.row.CaseStatus

			return (
				<StatusStoplight status={params.row.status} />
			);
		},
	},
	{
		field: 'assignedTo',
		headerName: 'Assigned To',
		width: 350,
		editable: false,
	},
	{
		field: 'lastName',
		headerName: 'Last Name',
		width: 200,
		editable: false,
	},
	{
		field: 'firstName',
		headerName: 'First Name',
		width: 200,
		editable: false,
	},
	{
		field: 'phoneNumber',
		headerName: 'Phone Number',
		width: 200,
		editable: false,
	},
	{
		field: 'emailAddr',
		headerName: 'Email Address',
		width: 275,
		editable: false,
	},
	{
		field: 'incDt',
		headerName: 'Incident Date',
		width: 200,
		editable: false,
		renderCell: (params) => {
			const incdate = new Date(params.row.incDt).toLocaleDateString();
			return incdate;
		}
	},
	// {
	// 	field: 'incDt',
	// 	headerName: 'Incident Date',
	// 	width: 200,
	// 	editable: false,
	// },
	{
		field: 'pollingPlace',
		headerName: 'Polling Place',
		width: 250,
		editable: false,
	},
	{
		field: 'county',
		headerName: 'County',
		width: 200,
		editable: false,
	},
	{
		field: 'election',
		headerName: 'Election Cycle',
		width: 200,
		editable: false,
	},
	{
		field: 'contDt',
		headerName: 'Contact Date',
		width: 200,
		editable: false,
		renderCell: (params) => {
			const condate = new Date(params.row.contDt).toLocaleDateString();
			//console.log(params.row.contDt)
			return condate;
		}
	},
	{
		field: 'crDt',
		headerName: 'Contact Time',
		width: 200,
		editable: false,
		renderCell: (params) => {
			const crTime = new Date(params.row.crDt).toLocaleTimeString();
			//console.log(params.row.crDt)
			return crTime;
		}

	},
	{
		field: 'affidavitBallot',
		headerName: 'Affidavit Ballot Submitted',
		width: 250,
		editable: false,
	},

	{

		field: 'issueTypes',
		headerName: 'Type of Complaint',
		width: 400,
		editable: false,
	},
	{
		field: 'subIssueTypes',
		headerName: 'Sub Issue',
		width: 400,
		editable: false,
	},
	{
		field: 'detail',
		headerName: 'Details',
		width: 200,
		editable: false,
	},

]