import React, { Component } from 'react'

import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

import { FormControl, Autocomplete } from '@mui/material';
import { OAGButtonGroup, OAGStyle, OAGTextField } from './StyledPaper.js';

export default class volunteerDropdown extends Component {
    state = {
        error: null,
        isLoaded: false,
        assignedTo: '',
        assignedtos: [],
    }

    async componentDidMount() {
       // console.log("Volunteer Dropdown Mounted")
        //Go get the Volunteer list
        ///const graphtoken = await this.getGraphToken();
        const graphtoken = await this.props.accessToken;
        //console.log(graphtoken);

        const apimiddlemanurl = process.env.REACT_APP_API_URL + "Search/VolunteerList"

        const token = await this.getAPIToken();

        //console.log(token);
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        headers.append("Authorization", bearer);

        const options = {
            method: "POST",
            headers: headers,
            body: "\"" + graphtoken + "\""
        };

        //console.log(options);

        await fetch(apimiddlemanurl, options)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true
                    });

                   // console.log(result);

                    for (let i = 0; i < result.value.length; i++) {
                        //console.log(result.value[i].mail);
                        this.state.assignedtos.push(result.value[i].mail)
                    }
                    //this.state.assignedtos.sort();
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        //Go get the Admins now.
        const admingurl = process.env.REACT_APP_API_URL + "Search/AdminList";

        await fetch(admingurl, options)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true
                    });

                    //console.log(result);

                    for (let i = 0; i < result.value.length; i++) {
                        //console.log(result.value[i].mail);
                        this.state.assignedtos.push(result.value[i].mail)
                    }
                    this.state.assignedtos.sort();
                    //console.log("Help: " + this.state.assignedtos)
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }

    getAPIToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            ...loginRequest
            //scopes: [ "api://ef6e98ee-0c3a-4fab-9e0f-5ccd4e058c71/access_as_user" ]
        });
        //console.log(response);
        return response.accessToken;
    }

    render() {
        const { error, isLoaded, assignedtos } = this.state;
        const { handleChange, assignedTo, page } = this.props;
        const choices = this.state.assignedtos.map((item, index) => (
            <option key={index} value={item}>
                {item}
            </option>
        ))

        if(page == "search"){

            return (
                <div >
                    <label for="assignedTo" class= {this.props.disabled ? "tw-block  tw-text-sm tw-font-medium" : "tw-block  tw-text-sm tw-font-medium"} >Assigned To</label>
                    <select
                        id="assignedTo"
                        class={this.props.disabled ? "tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  ":"tw-h-[57px]  tw-border    tw-text-sm tw-rounded-lg   tw-block tw-w-full tw-p-2.5  "}
                        placeholder="Assigned to"
                        onChange={handleChange('assignedTo')}
                        
                        value={assignedTo}
                        disabled={this.props.disabled}
                        onSelect={handleChange('assignedTo')}

                    >
                        <option value=""></option>
                        {choices}

                    </select>
                </div>
            
        

    )

        }else{

            return (
                <div >
                    <label for="assignedTo" class= {this.props.disabled ? "tw-block  tw-mb-2 tw-text-md tw-font-lg tw-text-dark-gray" : "tw-block  tw-text-md tw-font-medium tw-mb-2 "} >Assigned To</label>
                    <select
                        id="assignedTo"
                        class={this.props.disabled ? "tw-h-[63px] tw-bg-light-gray tw-border tw-text-dark-gray tw-text-lg tw-rounded-lg  tw-block tw-w-full tw-p-2.5 ":" tw-h-[63px] tw-border  tw-text-lg tw-rounded-lg tw-block tw-w-full tw-p-2.5 "}
                        placeholder="Assigned to"
                        onChange={handleChange('assignedTo')}
                        
                        value={assignedTo}
                        disabled={this.props.disabled}
                        onSelect={handleChange('assignedTo')}

                    >
                        <option value=""></option>
                        {choices}

                    </select>
                </div>
            
        

    ) 
        }
       
    }
}