import React, { Component } from 'react';
import { Backdrop, AppBar, Toolbar, Typography, CircularProgress, IconButton, Box, Alert } from '@mui/material';
import * as MuIcons from '@mui/icons-material';
import Voterdtl from './detailcomponents/voterblock';
import Incidentdtl from './detailcomponents/complaintblock';
import BOEdtl from './detailcomponents/boeblock';
import Notes from './detailcomponents/noteblock';
import EditModal from '../../modals/caseassignmentmodal';
import Documents from './detailcomponents/documentblock';
import VolunteerDropdown from '../../helpers/volunteerDropdown';
import copyOutline from "../../images/icons/eva/outline/copy-outline.svg";
import Edit from "../../images/icons/eva/outline/edit-outline.svg";
import Save from "../../images/icons/eva/outline/save-outline.svg";

import Close from "../../images/icons/eva/outline/close-circle-outline.svg";



import CaseStatus from '../../helpers/caseStatus';
import CopyModal from '../../modals/copymodal';

import { OAGButtonGroup, OAGInputLabel, OAGStyle, OAGTextField, OAGSelect, OAGTypography } from "../../helpers/StyledPaper.js";



import { loginRequest } from "../../authConfig";
import { msalInstance } from "../../index";


export class DetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            issuesCount: 1,
            lastname: '',
            firstname: '',
            phonenumber: '',
            email: '',
            address: '',
            address2: '',
            city: '',
            state: 'New York',
            zip: '',
            county: '',
            dateOfBirth: '',
            voterid: '',

            assignedTo: '',
            status: '',
            volunteer: this.props.userfullname,
            contactmethod: '',
            contactdate: '',
            pollingplace: '',
            pollingaddr: '',
            dateofincident: '',
            incidentdetails: '',
            created: '',
            lastmodified: '',
            electiondistrict: '',
            assemblydistrict: '',
            affidavitballot: '',
            interrelationcode: '',
            boecontacted: '',
            issuerslved: '',
            votercontactlocalboe: '',
            boecontact: '',
            indepescalation: '',
            pollworker: '',
            oagboecontacted: '',
            oagboecontact: '',
            oagfollowup: '',
            oagfollowupdtl: '',
            Oagrslvdissue: '',
            oagrslvdissuedtl: '',
            Voternotifiedstatus: '',

            cmpltID: '',
            //complaintdtls: [],
            //voterdtls: [],
            issuedtls: [],
            apitoken: '',
            graphtoken: '',
            appbaredit: false,

        };
    }
    async componentDidMount() {
        const token = await this.getToken();
        this.setState({ apitoken: token });

        //console.log("Getting Graph token")
        const gtoken = await this.getGraphToken();
        this.setState({ graphtoken: gtoken });

        const queryParams = new URLSearchParams(window.location.search);
        const cmpltId = queryParams.get('complaint');
        this.setState({ cmpltID: cmpltId });

        await this.getIncidentDtls(cmpltId, token);
        //this.getIncidentNotes(cmpltId);
        await this.getVoterDtls(cmpltId, token);

    }

    getToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            account: account,
            //...protectedResources.apiElectionHotline.scopes
            ...loginRequest
        });

        return response.accessToken;
    }

    getGraphToken = async () => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        // "User.Read" stands as shorthand for "graph.microsoft.com/User.Read"
        const graphToken = await msalInstance.acquireTokenSilent({
            account: account,
            scopes: ["https://graph.microsoft.com/User.Read.All"]
        });
        //console.log(graphToken);


        return graphToken.accessToken;
    }

    getIncidentDtls = async (id, token) => {
        //console.log("In getIncidentDetails")
        const url = process.env.REACT_APP_API_URL + "Complaint/" + id;
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(
                (cresult) => {
                    //console.log(cresult.interrelationCode);
                    this.setState({
                        //isLoaded: true,
                        // complaintdtls: result,
                        contactmethod: cresult.contMethod,
                        contactdate: cresult.contDt,
                        pollingplace: cresult.pollingPlace,
                        pollingaddr: cresult.pollingPlaceAddr,
                        dateofincident: cresult.incDt,
                        incidentdetails: cresult.detail,
                        electiondistrict: cresult.voterElecDist,
                        created: cresult.cr_DT,
                        lastmodified: cresult.lm_DT,
                        assemblydistrict: cresult.voterAssemDist,
                        affidavitballot: cresult.affidavitBallot,
                        assignedTo: cresult.assignedTo,
                        status: cresult.status,
                        interrelationcode: cresult.interrelationcode,
                        boecontacted: cresult.boeContacted,
                        issuerslved: cresult.issueRslvd,
                        votercontactlocalboe: cresult.votercontactlocalboe,
                        boecontact: cresult.boeContact,
                        oagboecontacted: cresult.oagboecontacted,
                        oagboecontact: cresult.oagboecontact,
                        indepescalation: cresult.indepescalation,
                        pollworker: cresult.pollworker,
                        oagfollowup: cresult.oagfollowup,
                        oagfollowupdtl: cresult.oagfollowupdtl,
                        Oagrslvdissue: cresult.oagrslvdissue,
                        oagrslvdissuedtl: cresult.oagrslvdissuedtl,
                        Voternotifiedstatus: cresult.voternotifiedstatus,
                        voternotifiedstatusdtl: cresult.voternotifiedstatusdtl,

                    });
                    //console.log("Successful retrieval and state setting")
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    //console.log("Error in Complaint GET; isLoaded was set to true");
                    //console.log(error);
                }
            )


        const issueurl = process.env.REACT_APP_API_URL + "Issue/GetIssuesForComplaint?cmpltid=" + id;
        await fetch(issueurl, options)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        //isLoaded: true,
                        issuedtls: result,
                        issuesCount: result.length
                    });

                    //console.log("We have the Issue Array");
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    //console.log("Error in Issue GET; isLoaded was set to true");
                }
            )
    }

    getVoterDtls = async (id, token) => {
        const url = process.env.REACT_APP_API_URL + "Voter/" + id;
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };


        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        // voterdtls: result,
                        lastname: result.lastName,
                        firstname: result.firstName,
                        phonenumber: result.phoneNumber,
                        email: result.emailAddr,
                        address: result.address,
                        address2: result.address2,
                        city: result.city,
                        state: result.state,
                        zip: result.zipCode,
                        county: result.county,
                        dateOfBirth: result.dateOfBirth,
                        voterid: result.voterId,
                    });
                    //console.log("We retrieved the Voter Details");
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    //console.log("Error in Voter GET; isLoaded was set to true");
                }
            )

    }


    // handle field change
    handleChange = input => (event) => {
        //console.log(event);
        this.setState({ [input]: event.target.value });

    }

    toggleAppBar = () => {
        //console.log("I want to toggle the app bar")
        const currentstate = this.state.appbaredit;
        this.setState({ appbaredit: !currentstate });
    }



    setStatAndAssign = async () => {
        //const token = this.getToken();
        const headers = new Headers();
        const bearer = `Bearer ${this.state.apitoken}`;

        headers.append("content-type", "application/json");
        headers.append("accept", "*/*");
        headers.append("Authorization", bearer);

        const PostData = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                AssignedTo: this.state.assignedTo,
                Status: this.state.status,
            }),
        };

        //console.log(this.state.status)
        const url = process.env.REACT_APP_API_URL + "Complaint/StatAssign/" + this.state.cmpltID;

        try {
            let response = await fetch(url, PostData);
            //console.log(response);
        }
        catch (err) {
            //console.log(err);
        }

        window.location.reload(true);
    };


    formatDate(date) {

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            //day = '' + d.getDate(),
            day = '' + d.getUTCDate(),
            year = d.getFullYear();

        //console.log(d.getDate() + " < result of getDate");
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2) {
            //console.log("Day length is less than 2");
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    setCount = (action) => {
        const { issuesCount } = this.state;
        if (action === "add") this.setState({ issuesCount: issuesCount + 1 });
        else this.setState({ issuesCount: issuesCount - 1 });
    };

    render() {
        const { lastname, firstname, phonenumber, email, address, address2, city, state, zip, county, dateOfBirth, voterid,
            contactmethod, contactdate, pollingplace, pollingaddr, dateofincident, incidentdetails, created, lastmodified, electiondistrict, assemblydistrict, affidavitballot,
            issuedtls, cmpltID, volunteer,
            pollworker, boecontacted, issuerslved, votercontactlocalboe, boecontact, indepescalation, interrelationcode,
            oagboecontacted, oagboecontact, oagfollowup, oagfollowupdtl, Oagrslvdissue, oagrslvdissuedtl, Voternotifiedstatus, voternotifiedstatusdtl } = this.state;

        const incidentinfo = {
            contactmethod, contactdate, pollingplace, pollingaddr, dateofincident, incidentdetails, created, lastmodified, electiondistrict, assemblydistrict, affidavitballot,
            issuedtls, cmpltID, interrelationcode
        };



        const boeinfo = {
            pollworker, boecontacted, issuerslved, votercontactlocalboe, boecontact, indepescalation,
            oagboecontacted, oagboecontact, oagfollowup, oagfollowupdtl, Oagrslvdissue, oagrslvdissuedtl, Voternotifiedstatus, voternotifiedstatusdtl, cmpltID
        };


        const voterinfo = { lastname, firstname, phonenumber, email, address, address2, city, state, zip, county, dateOfBirth, voterid }

        const { error, isLoaded, graphtoken, assignedTo, status, appbaredit } = this.state;

        const complaintArray = [
            { label: 'Complaint ID: ', value: this.state.cmpltID },
            { label: 'Assigned To: ', value: this.state.assignedTo },
            { label: 'Status: ', value: this.state.status },
            { label: 'Last Name: ', value: this.state.lastname },
            { label: 'First Name: ', value: this.state.firstname },
            { label: 'Phone Number: ', value: this.state.phonenumber },
            { label: 'Email Address: ', value: this.state.email },
            { label: 'Mailing Address: ', value: this.state.address },
            { label: 'Address Line 2: ', value: this.state.address2 },
            { label: 'City: ', value: this.state.city },
            { label: 'State: ', value: this.state.state },
            { label: 'Zip: ', value: this.state.zip },
            { label: 'County: ', value: this.state.county },
            { label: 'Date of Birth: ', value: new Date(this.state.dateOfBirth).toLocaleDateString() },
            { label: 'Contact Method: ', value: this.state.contactmethod },
            { label: 'Contact Date: ', value: new Date(this.state.contactdate).toLocaleDateString() },
            { label: 'Created On: ', value: new Date(this.state.created).toLocaleDateString() + " " + new Date(this.state.created).toLocaleTimeString() },
            { label: 'Last Modified: ', value: new Date(this.state.lastmodified).toLocaleDateString() + " " + new Date(this.state.lastmodified).toLocaleTimeString() },
            { label: 'Details: ', value: this.state.incidentdetails },
            { label: 'Polling Place: ', value: this.state.pollingplace },
            { label: 'Incident Date: ', value: new Date(this.state.dateofincident).toLocaleDateString() },
            { label: 'Affidavit Ballot: ', value: this.state.affidavitballot },
        ]


        const divStyle = {
            paddingLeft: 8 + 'px'
        };

        const divFormStyle = {
            paddingTop: 8 + 'px'
        };



        let appbarfields;
        if (appbaredit) {
            appbarfields =
                <div className="tw-grid tw-gap-6  md:tw-grid-cols-3">
                    <div className="">
                        <CaseStatus disabled={false} handleChange={this.handleChange} status={status} />
                    </div>
                    <div className="">
                        <VolunteerDropdown disabled={false} handleChange={this.handleChange} assignedTo={assignedTo} accessToken={graphtoken} />
                    </div>
                    <div className="tw-flex">
                        <div>
                            <label for="SaveChanges" class="tw-block tw-mb-4 tw-text-md tw-font-medium  ">Save</label>

                            <button id="SaveChanges" class="tw-px-2" onClick={this.setStatAndAssign}>
                                <img className="tw-block tw-rounded-full hover:tw-bg-lighter-blue tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Save} alt="Save Icon" />
                            </button>
                        </div>
                        <div class="">
                            <label for="Lock" class=" tw-block tw-mb-4 tw-text-md tw-font-medium  ">Cancel</label>

                            <button id="Lock" className="tw-px-3" onClick={this.toggleAppBar}>
                                <img className="tw-block tw-rounded-full hover:tw-bg-lighter-blue tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Close} alt="Lock Icon" />
                            </button>

                        </div>
                        <CopyModal complaintarray={complaintArray} />
                    </div>

                </div>
        }
        else {
            appbarfields =
                <div className="tw-grid tw-gap-6 md:tw-grid-cols-3">
                    <div className="">
                        <CaseStatus disabled={true} handleChange={this.handleChange} status={status} />
                    </div>
                    <div className="">
                        <VolunteerDropdown disabled={true} handleChange={this.handleChange} assignedTo={assignedTo} accessToken={graphtoken} />
                    </div>
                    <div className="tw-flex">
                        <div class="tw-px-5">
                            <label for="Unlock" class="tw-block tw-mb-4 tw-text-md tw-font-medium  ">Edit</label>
                            <button id="Unlock" onClick={this.toggleAppBar}>
                                <img className="tw-block tw-rounded-full hover:tw-bg-lighter-blue tw-w-auto tw-m-0 tw-h-[40px] lg:tw-h-[40px]" src={Edit} />
                            </button>
                        </div>
                        <CopyModal complaintarray={complaintArray} />
                    </div>

                </div>
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            );
        } else {
            return (
                <div>
                    <div className=" container-fluid">
                        <h4>Complaint Number {this.state.cmpltID}</h4>
                        <div class="tw-rounded-xl tw-py-5 tw-px-5 tw-bg-lightest-blue" position="static" >
                            {/* <Toolbar> */}
                            {appbarfields}
                            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Status:  {this.state.status}
                        </Typography>

                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Assigned To: {this.state.assignedto}
                        </Typography> */}

                            {/* <IconButton
                            size="small"
                            edge="true"
                            color="inherit"
                            aria-label="menu"
                            //sx={{ mr: 2 }}
                            onClick={this.toggleAppBar}
                        >
                            <MuIcons.Edit />
                        </IconButton> */}

                            {/* <EditModal cmpltID={this.state.cmpltID} status={this.state.status} assigned={this.state.assignedto} user={this.props.userfullname} handleChange={this.handleChange} accessToken={graphtoken} /> */}


                            {/* </Toolbar> */}
                        </div>
                        <hr />
                        <div className="tw-bg-dot-pattern row ">

                            <div className="col-sm-6 ">
                                <Voterdtl voterdtls={voterinfo} handleChange={this.handleChange} volunteer={volunteer}
                                    formatDate={this.formatDate} />
                                <br />

                            </div>
                            <div className="col-sm-6 " >
                                <Incidentdtl incdtls={incidentinfo} handleChange={this.handleChange} volunteer={volunteer}
                                    setIssue={this.setIssue}
                                    setCount={this.setCount}
                                    removeIssues={this.removeIssues}
                                    setSubIssue={this.setSubIssue}
                                    formatDate={this.formatDate}
                                />
                                <br />
                                <Documents complaint={this.state.cmpltID} />
                            </div>


                        </div>

                        <div className="tw-bg-dot-pattern row ">
                            <div className="col-sm-12">
                                {/* <Incidentdtl incdtls={incidentinfo} handleChange={this.handleChange} volunteer={volunteer} 
                                            setIssue={this.setIssue}
                                            setCount={this.setCount}
                                            removeIssues={this.removeIssues}
                                            setSubIssue={this.setSubIssue}
                                            formatDate={this.formatDate}
                                    /> */}
                                <BOEdtl boedtls={boeinfo} handleChange={this.handleChange} volunteer={volunteer} />

                            </div>
                            <div className="col-sm-6">

                                {/* <Notes notetype="CNote" id={this.state.cmpltID} volunteer={volunteer} /> */}
                            </div>
                        </div>
                        <hr />
                        <div >
                            <div className="tw-px-5 tw-rounded-xl tw-bg-lightest-blue tw-py-5" >
                                <div className="col-sm-12">
                                    <Notes id={this.state.cmpltID} volunteer={volunteer} />
                                    {/* <BOEdtl boedtls={boeinfo} handleChange={this.handleChange} volunteer={volunteer} /> */}
                                </div>
                                {/* <div className="col-sm-5">
                             <Notes notetype="BNote" id={this.state.cmpltID}/> 
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default DetailView;