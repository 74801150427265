import React from "react"
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Adminnav from "../helpers/adminnav";
import { Outlet, Link, Redirect } from "react-router-dom";

import agencyLogo from "../images/logo.svg";
import { SignInButton } from "../helpers/SignInButton";
import { SignOutButton } from "../helpers/SignOutButton";
//import "../components/oag.css";
import "../index.css";
import GoogleTranslate from "../helpers/GoogleTranslate";
import YourComponent from "./test";


const Header = () => {


    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    let userfullname, userroles, name;
    let nav, motd, navbackground, title;


    function toggleSubMenu() {
        const submenu = document.getElementById("about-submenu-1");
        const button = document.querySelector(".menu-link--button");
        // console.log(button)
        const expanded = button.getAttribute("aria-expanded") === "true";
        // console.log("expanded:" + expanded);
        if (expanded) {
            submenu.style.display = "none";
            button.setAttribute("aria-expanded", "false");
        } else {
            submenu.style.display = "block";
            button.setAttribute("aria-expanded", "true");
        }
    }
    function toggleMenu() {
        const menu = document.getElementById("mobile-toggle");
        const button = document.querySelector(".test--button");
        //console.log("button:" + button.ariaExpanded);
        const expanded = button.getAttribute("aria-expanded");
        const mobileHeader = document.getElementById("mobile-header");

        // console.log("expanded:" + expanded);
        if (expanded == "true") {

            mobileHeader.setAttribute("class", "mobile-header tw-px-4 lg:tw-absolute lg:tw-bottom-0 lg:tw-left-0 lg:tw-right-0")

            button.setAttribute("aria-expanded", "false");
        } else {
            menu.style.display = "block";
            mobileHeader.setAttribute("class", "mobile-header--open tw-px-4 lg:tw-absolute lg:tw-bottom-0 lg:tw-left-0 lg:tw-right-0")
            button.setAttribute("aria-expanded", "true");


        }
    }

    if (isAuthenticated) {
        userfullname = accounts[0].idTokenClaims.preferred_username;
        name = accounts[0].idTokenClaims.name;
        userroles = accounts[0].idTokenClaims.roles;
        var navMenu;
        if (userroles == "Admin" || userroles == "IT") {
            title = "Election Complaint Form for Admins";
            navMenu = <div id="primary-menu" class="tw-my-4 lg:tw-my-0 lg:tw-pl-[305px] xl:tw-pl-[305px] tw-text-sm rtl:lg:tw-pr-[180px] rtl:xl:tw-pr-[305px]">
                <nav class="primary-menu tw-relative">
                    <ul class="menu" tabindex="-1">
                        <li class="menu-item menu-item--button  menu-item--has-children">
                            <a href="/admin" >Admin Complaint Form</a>
                        </li>
                        <li class="menu-item menu-item--button  menu-item--has-children">
                            <a href="/search">Search</a>
                        </li>

                        <li class="menu-item menu-item--button  menu-item--has-children">
                            <button aria-expanded="false" onClick={toggleSubMenu} class="menu-link menu-link--button" data-ariacontrols="about-submenu-1">Admin Pages</button>
                            <ul id="about-submenu-1" class="menu submenu submenu-1" tabindex="-1">
                                <li class="menu-item menu-item--link">
                                    <a href="/issuemgmt" class="menu-link menu-link--link" data-ariacontrols="about-the-office-submenu-1">Issue Types</a>
                                </li>
                                <li class="menu-item menu-item--link">
                                    <a href="/electionmgmt" class="menu-link menu-link--link" data-ariacontrols="employment-opportunities-submenu-2">Elections</a>
                                </li>
                                <li class="menu-item menu-item--link">
                                    <a href="/motdpage" class="menu-link menu-link--link" data-ariacontrols="meet-letitia-james-submenu-3">Message of the Day</a>
                                </li>
                                <li class="menu-item menu-item--link">
                                    <a href={process.env.REACT_APP_REPORTS_URL} class="menu-link menu-link--link" data-ariacontrols="year-in-review-submenu-4">Reporting</a>
                                </li>
                            </ul>
                        </li>

                        <li class="menu-item menu-item--button  menu-item--has-children">
                            <SignOutButton />
                        </li>
                    </ul>
                </nav>
            </div>
        } else {
            title = "Election Complaint Form for Volunteers";
            navMenu = <div id="primary-menu" class="tw-my-4 lg:tw-my-0 lg:tw-pl-[305px] xl:tw-pl-[305px] tw-text-sm rtl:lg:tw-pr-[180px] rtl:xl:tw-pr-[305px]">
                <nav class="primary-menu tw-relative">
                    <ul class="menu" tabindex="-1">
                    <li class="menu-item menu-item--button  menu-item--has-children">
                            <a href="/admin" >Admin Complaint Form</a>
                        </li>
                        <li class="menu-item menu-item--button  menu-item--has-children">
                            <a href="/search">Search</a>
                        </li>
                        <li class="menu-item menu-item--button  menu-item--has-children">
                            <SignOutButton />
                        </li>
                    </ul>
                </nav>
            </div>

        }
        return (
            <>


                <header fontFamily="mr-eaves-xl-modern" role="banner">
                    <div id="top-bar" class="tw-bg-dark-blue tw-text-white ">
                        <div class="tw-container tw-h-full tw-flex tw-flex-row tw-justify-between tw-items-center lg:tw-pl-[200px] rtl:lg:tw-pr-[200px] rtl:tw-pr-[200px] xl:tw-pl-[340px] rtl:xl:tw-pr-[340px] ">
                            <div class="office-tag tw-text-[0.8rem] tw-leading-4 sm:tw-text-xs lg:tw-text-base">Office of the New York State Attorney General</div>

                            {/* <YourComponent /> */}
                            {/* <languageSwitcher /> */}
                            <GoogleTranslate />
                        </div>

                    </div>
                    <div class="tw-relative xl:tw-container">
                        <div class="main-header tw-px-4 tw-py-4 tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between lg:tw-justify-start lg:tw-flex-col lg:tw-h-[180px] xl:tw-h-[215px] xl:tw-px-0">
                            <div id="branding" class="lg:tw-w-3/4 lg:tw-basis-full">
                                <a href="/" title="Home" rel="home" class="tw-branding tw-flex tw-justify-start tw-items-center tw-no-underline hover:tw-no-underline focus:tw-no-underline">
                                    <div class="site-logo tw-border-none tw-absolute -tw-top-[10px] lg:-tw-top-[30px]">
                                        <img class="tw-block tw-h-[75px] tw-w-auto tw-m-0 md:tw-h-[120px] lg:tw-h-[180px] xl:tw-h-[270px]" height="270" src={agencyLogo} alt="Home" />
                                    </div>
                                    <div class="site-name tw-pl-[85px] md:tw-pl-[140px] lg:tw-pl-[200px] xl:tw-pl-[320px] rtl:tw-pr-[85px] rtl:md:tw-pr-[140px] rtl:lg:tw-pr-[200px] rtl:xl:tw-pr-[320px] rtl:tw-pl-0 rtl:md:tw-pl-0 rtl:lg:tw-pl-0 rtl:xl:tw-pl-0">
                                        <h2 class="tw-text-sm tw-m-0 tw-font-black tw-italic tw-text-blue sm:tw-text-md md:tw-text-2xl lg:tw-text-3xl xl:tw-text-3xl rtl:md:tw-text-1xl rtl:lg:tw-text-3xl rtl:xl:tw-text-4xl">Election Hotline</h2>
                                        <div class="tw-block tw-mt-0.5 tw-text-xxs tw-text-dark-blue sm:tw-text-sm md:tw-text-base lg:tw-text-xl xl:tw-text-xl rtl:lg:tw-text-base rtl:xl:tw-text-xl" href="/" title="Home" rel="home">{title}</div>
                                    </div>
                                </a>
                            </div>
                            <div class="tw-hidden lg:tw-block lg:tw-w-1/4 xl:tw-mt-4">
                            </div>
                            <div id="mobile-toggle" class="lg:tw-hidden">
                                <button aria-expanded="false" id="funny" onClick={toggleMenu} class=" test--button" data-ariacontrols="mobile-header" />
                            </div>
                        </div>
                        <div id="mobile-header" class="mobile-header tw-px-4 lg:tw-absolute lg:tw-bottom-0 lg:tw-left-0 lg:tw-right-0 ">
                            <div class="tw-flex tw-flex-col tw-items-stretch tw-py-8 lg:tw-py-0 lg:tw-px-0">

                                {navMenu}
                            </div>
                        </div>
                    </div>
                </header>

            </>
        )


    } else {
        title = "Election Complaint Form";
        navbackground = '#005481';

    }

    return (
        <>
            <header fontFamily="mr-eaves-xl-modern" role="banner">
                <div id="top-bar" class="tw-bg-dark-blue tw-text-white tw-h-[40px]">
                    <div class="tw-container tw-h-full tw-flex tw-flex-row tw-justify-between tw-items-center lg:tw-pl-[200px] rtl:lg:tw-pr-[200px] rtl:tw-pr-[200px] xl:tw-pl-[340px] rtl:xl:tw-pr-[340px] ">
                        <div class="office-tag tw-text-[0.8rem] tw-leading-4 sm:tw-text-sm lg:tw-text-base">Office of the New York State Attorney General</div>
                        {/* <YourComponent /> */}
                        {/* <languageSwitcher/> */}
                        <GoogleTranslate />

                    </div>
                </div>
                <div class="tw-relative xl:tw-container">
                    <div class="main-header tw-px-4 tw-py-4 tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between lg:tw-justify-start lg:tw-flex-col lg:tw-h-[180px] xl:tw-h-[215px] xl:tw-px-0">
                        <div id="branding" class="lg:tw-w-3/4 lg:tw-basis-full">
                            <a href="/" title="Home" rel="home" class="tw-branding tw-flex tw-justify-start tw-items-center tw-no-underline hover:tw-no-underline focus:tw-no-underline">
                                <div class="site-logo tw-border-none tw-absolute -tw-top-[10px] lg:-tw-top-[30px]">
                                    <img class="tw-block tw-h-[75px] tw-w-auto tw-m-0 md:tw-h-[120px] lg:tw-h-[180px] xl:tw-h-[270px]" height="270" src={agencyLogo} alt="Home" />
                                </div>
                                <div class="site-name tw-pl-[85px] md:tw-pl-[140px] lg:tw-pl-[200px] xl:tw-pl-[320px] rtl:tw-pr-[85px] rtl:md:tw-pr-[140px] rtl:lg:tw-pr-[200px] rtl:xl:tw-pr-[320px] rtl:tw-pl-0 rtl:md:tw-pl-0 rtl:lg:tw-pl-0 rtl:xl:tw-pl-0">
                                    <h2 class="tw-text-lg tw-m-0 tw-font-black tw-italic tw-text-blue md:tw-text-2xl lg:tw-text-3xl xl:tw-text-3xl rtl:md:tw-text-1xl rtl:lg:tw-text-3xl rtl:xl:tw-text-4xl">Election Hotline</h2>
                                    <div class="tw-block tw-mt-0.5 tw-text-sm tw-text-dark-blue md:tw-text-base lg:tw-text-xl xl:tw-text-xl rtl:lg:tw-text-base rtl:xl:tw-text-xl" href="/" title="Home" rel="home">{title}</div>
                                </div>
                            </a>
                        </div>
                        <div class="tw-hidden lg:tw-block lg:tw-w-1/4 xl:tw-mt-4">
                        </div>
                        <div id="mobile-toggle" class="lg:tw-hidden">
                            <button aria-expanded="false" id="funny" onClick={toggleMenu} class=" test--button" data-ariacontrols="mobile-header" />
                        </div>
                    </div>
                    <div id="mobile-header" class="mobile-header tw-px-4 lg:tw-absolute lg:tw-bottom-0 lg:tw-left-0 lg:tw-right-0 ">
                        <div class="tw-flex tw-flex-col tw-items-stretch tw-py-8 lg:tw-py-0 lg:tw-px-0">

                            <div id="primary-menu" class="tw-my-4 lg:tw-my-0 lg:tw-pl-[305px] xl:tw-pl-[305px] sm:tw-text-sm tw-text-xs xl:tw-text-xl rtl:lg:tw-pr-[180px] rtl:xl:tw-pr-[305px]">
                                <nav class="primary-menu tw-relative">
                                    <ul class="menu" tabindex="-1">

                                        <li class="menu-item menu-item--button  menu-item--has-children">
                                            <SignInButton />
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header 
