import React from 'react'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'

const notecomponent = (props) => {
  var formatteddate = new Date(props.date).toLocaleString();
  var notenumber = props.noteid;
  return (
    // <div class="card" style="width: 18rem;">
    // <div class="card-body">
    //     <h5 class="card-title">{props.date} by {props.title}</h5>
    //     <p class="card-text">{props.content}</p>
    //     <a href="#" class="btn btn-primary">Go somewhere</a>
    // </div>
    // </div>
    
    <div className="card">
      <div className="card-body">
        <div class="row">
          <div class="col-sm-11">
            <h5 className="card-title">{formatteddate} by {props.title} #{props.noteid}</h5>
          </div>
          <div class="col-sm-1">
            <IconButton id = "NoteDelete" onClick={() => props.deletenote(notenumber)}><Delete fontSize="small" color="primary"/></IconButton>
          </div>
        </div>
            
          <p className="card-text">{props.content}</p>
      </div>      
    </div>
  )
}

export default notecomponent